
import React, { Component } from 'react' 
import primaryLinks from './links'
import Navigation from "../../../Common/navigation/navigation" 
import {Link} from 'react-router-dom'
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import DashboardIcon from '@material-ui/icons/Dashboard';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import ListIcon from '@material-ui/icons/List';
import Alert from './alert'
import Navi from './navigation'


const styles = {
    root: {
     
      color:"#ff6633",
      fontSize: 20,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };



class Primary extends Component {

    constructor(props){
        super()
        this.state = {
            showMenu: false
        }
    }

    toggleMenue(e){

        e.preventDefault()
        this.setState({...this.state,showMenu: !this.state.showMenu})

    }
    
   

    openPage = (page)=>{

        const {actions} = this.props
        actions.push(page)
    }

    logoutUser(e){

        e.preventDefault()
        this.props.actions.logoutUser()
    }


    render(){

        
        //className="pos-abs pd-top-fd-tn right-offset-fl-x-bt"
        const {state,props,toggleMenue} = this 
        const {userProfile,actions} = props 
        const {profile} = userProfile 
        const {personal} = profile
        const {profile_url='',first_name=''} = personal
        let userName = props.width > 900 ? first_name.split(' ')[0] : first_name.slice(0,1).toUpperCase()
       
        const {showMenu} = state 
        let showMenuClasses = showMenu ? 'header__nav--cont-logged header__nav--cont-adjust' : 'header__nav--cont-logged'

        return(

            <section>

                <div className={showMenuClasses}>


                    <span className="header__nav--item-close-logged" onClick={toggleMenue.bind(this)}>
                        <Icon>
                            <CloseIcon style={styles.root} className="fg-primary" />
                        </Icon>
                    </span>


                    {
                        props.width > 900
                        ?   <>

                                <li className="header__nav--item ">

                                                        
                                <span className="fg-dark d-block mg-right-fd-sm">{userName}</span>

                                </li>

                                <li className="header__nav--item">

                                <span className="header__avatar" onClick={(e)=> this.openPage('/settings')}>
                                    
                                    <img src={profile_url} className="header__avatar--img" />

                                </span>


                                </li>
                        
                            </>
                        : <>


                            <Navi logoutUser={this.logoutUser.bind(this)} />

                            {/* <li className="header__nav--item header__nav--item-cv">

                            <Alert />

                            </li>

                            

                            <li className="header__nav--item ">


                                <Link to='/postjob' className="header__nav--item-link ">
                                    <span className="fg-secondary">+ Post Jobs</span>
                                </Link>

                            </li> */}

                            
                        
                        </>
                    }

                    
                    
                    

                    { props.width > 900 ? <li className="header__nav--item">

                        <Link  className="header__nav--item-link" onClick={this.logoutUser.bind(this)}>
                        <span style={{top: .3+"rem"}} className="d-inline-block mg-right-fd-xxx-tn pos-rel">
                            <Icon>
                                <PowerSettingsNewIcon style={{fontSize: 1.35+"rem"}} className="fg-primary" />
                            </Icon>
                            </span>
                            <span className="d-inline-block">Log out</span>
                        </Link>

                        </li>
                    : null
                    }

                    
                
            </div>

            <div className="header__loggedin">

            {

                !showMenu && props.width <= 900
                    ?   <>

                            <li className="header__loggedin-i-txt ">

                                                    
                            <span className="fg-dark d-block mg-right-fd-sm">{userName}</span>

                            </li>

                            <li className="header__loggedin-i-av">

                            <span className="header__avatar" onClick={(e)=> this.openPage('/settings')}>
                                
                                <img src={profile_url} className="header__avatar--img" />

                            </span>


                            </li>

                        </>
                    : null
            }



            {props.width <= 900 ? 
                
                <span className="header__loggedin-i-menu" onClick={toggleMenue.bind(this)}>
                    <Icon>
                        <MenuIcon style={styles.root} className="fg-primary" />
                    </Icon>
                </span>
          
            : null}
            </div>

        </section>
        )

    }

}


export default Primary