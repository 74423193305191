
import React, {Component} from 'react' 
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
// import * as actions from './actions'
import * as navigatorActions from '../ReduxFirstNavigator/actions' 
import * as userProfileActions from '../UserProfile/actions'
import ProgressBarCircular from '../ProgressBarCircular/component'
import ProgressBarLinear from '../ProgressBarLinear/component'
import Upload from './jobalert/alert' 






class CandSettings extends Component{


    constructor(props){

        super()
        
    }


    // componentDidMount(){

       
    //     const {props} = this 
    //     const {actions,userProfile,router} = props 
    //     const {fetchResume} = actions
    //     const {resume} = userProfile 
    //     const {pathname} = router

    //     if(pathname.trim() !== '/cv-profile'){
       
    //         if(Object.keys(resume).length <= 0){

    //             console.lopg('upload component about to fetch data ')
    //             fetchResume(52)
    //         }

    //     }

        
    // }

    componentDidUpdate(){

        const {props} = this 
        const {userProfile,actions} = props 
        const {isActionSuccessful = false,message='',isInitFetching=false} = userProfile 
        const {removeNotification,removeMessage} = actions 

        if(isActionSuccessful && isInitFetching) {

            // let msg = text.trim() || 'Information has successfully been updated.'
            // this.notify({message:msg,type: 'success',className: 'notify-success'}) 
            removeNotification() 
            
        }
    }




    render(){


       
        const {actions,userProfile,text='',children=null,auth,popUp='',button=null,styles=null} = this.props 
       
        console.log('UPLOAD COMPONENT PARENT')
        console.log(this.props)
        // const {profile,isProfile} = userProfile 
    
        

        return(

                 
                  
            <Upload text={text} styles={styles} popUp={popUp} children={this.props.children} actions={actions} button={button} userProfile={userProfile} auth={auth} />
                  
        )
    }




}



const mapStateToProps = (state)=>{

    const {userProfile,auth,router} = state

    return{
        userProfile,auth,router

    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...navigatorActions,...userProfileActions},dispatch)
    }
}

export default  connect(mapStateToProps,mapDispachToProps)(CandSettings)