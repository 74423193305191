import React from 'react';
import clsx from 'clsx';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    border: 'solid #cc3333 2px',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
});

const createControlStyles = makeStyles({

   formLabel: {

    display: 'inline-block',
    width: '40%',
    
   },
   radioGroup:{

    display: 'inline-block',
   }
})

// Inspired by blueprintjs

const theme = createMuiTheme({
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontSize: 20,
  },
});


function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}



export default function CustomizedRadios(props) {

    const handleChange = name => event => {
    
        
        event.preventDefault()
        props.handle(name)
     };

     const classes = createControlStyles()
    

  //    const  IsJsonString = (str)=> {
  //     try {
  //         JSON.parse(str);
  //     } catch (e) {
  //         return false;
  //     }
  //     return true;
  // }

    const {list,legend} = props
  return (

    <ThemeProvider theme={theme}>
    

    <FormControl  component="fieldset" fullWidth={true}>


          {legend && 
                    <FormLabel  component="legend">Gender</FormLabel>
                  }

          <RadioGroup className={classes.radioGroup} defaultValue="female" aria-label="gender" name="customized-radios">
                  {
                      list.map((i,p)=>{

                          

                            return(

                                <FormControlLabel className={classes.formLabel} key={p} value={i.value} onChange={handleChange(i.value)} checked={i.checked} control={<StyledRadio />} size={'medium'} label={i.label} />
                        
                            )
                      
                      })
                  }

          </RadioGroup>

        
        
        
      </FormControl>

    </ThemeProvider>
  );
}
