
import React, { Component } from 'react' 
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import Notifications from '@material-ui/icons/Notifications';
import SearchIcon from '@material-ui/icons/Search';
import InfoIcon from '@material-ui/icons/Info';


const styles = {
    root: {
     
      color:"seagreen",
      fontSize: 35,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };




class Benefits extends Component{
    
    constructor(props){

        super(props)

    }


    render(){

        return(

           <section className="home__jobseeker">

               <div className="home__jobseeker--b1 ">

                        <p className="font-fd-x-tn fg-primary bx-shadow-dark">
                            <span className="d-block text-align-center mg-bottom-fd-tn fg-secondary mg-right-fd-xx-tn">
                            <i class="fa fa-search" aria-hidden="true"></i>

                            </span>
                            <span className="d-block text-align-center">Job search</span>
                        </p>
               </div>

               <div className="home__jobseeker--b2">

                   <section className="home__jobseeker--b2-listing">

                    <h2 className="fg-secondary font-fd-x-tn pd-left-fl-tn">Local Listing</h2>
                    <p className="font-fd-xx-tn fg-dark">
                    We aggregate thousands of jobs from our partner sites and list them in one place. We list all local vacancies to ensure that you don't miss out on opportunities that are close to  your heart—home is where the heart is! 

                        </p>

                        <span></span>

                   </section>
                    

                   

               </div>

               <div className="home__jobseeker--b3">

                  
                        <p className="font-fd-x-tn fg-primary bx-shadow-dark">
                            <span className="d-block text-align-center mg-bottom-fd-tn fg-secondary mg-right-fd-tn">
                            <i class="fa fa-rss" aria-hidden="true"></i>

                            </span>
                            <span className="d-block text-align-center">Career guidance</span>
                        </p>
               

               </div>

               <strong className="clearfix" />
             
           </section>
        )
    }

}


export default withStyles(styles)(Benefits)