
import React, {Component} from 'react' 
import FormControl from '../../formcontrol'
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ProgressBarLinear from '../../progressbarlinear'
import { toast } from 'react-toastify';


const styles = {
    root: {
     
      color:"white",
      fontSize: 20,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };




class Form extends  Component{


    constructor(props){

        super()

        
        this.state = {
            form : {
                    
                    
               
                email:{
                    
                    control: 'input',
                    value:'',
                    validation: {
                        isRequiredFormat:{
                            isRequired: true,
                            isEmail: true
                            
                        },
                
                    },
                    isValid: false,
                    isTouched: false,
                    validationMessage: '',
                    validationStyles: {
                        position: 'left'
                    },
                    config: {
                        
                        name: "email",
                        type: 'text',
                        placeholder: 'alertme@mail.com',
                        // disabled: false
                        
                    }
                    
                },
                keyword:{
                    
                    control: 'input',
                    value:'',
                    validation: {
                        isRequiredFormat:{
                            isRequired: true,
                            minLetters: 2
                            
                        },
                
                    },
                    isValid: false,
                    isTouched: false,
                    validationMessage: '',
                    validationStyles: {
                        position: 'left'
                    },
                    config: {
                        
                        name: "keyword",
                        type: 'text',
                        placeholder: 'Job title or keyword',
                        // disabled: false
                        
                    }
                    
                },
                
                

              
            
            },
            next: false,
            isSuccess: false
            
        };
    }

    
    updator = (upControl)=>{
        
            console.log('THE UPDATE CONTROL')
            console.log(upControl)
            let newForm ={...this.state.form} 
            let updateControl = {...newForm[upControl.id]}
            updateControl.value = upControl.e.target.value 

        
            
            if(!(updateControl.validation.isPlaceholder && updateControl.value.trim() === '')){

                if(upControl.blur){
                
                    let validData = this.controlValidate(updateControl) 
                    
                    updateControl.isValid = validData[0] 
                    updateControl.validationMessage = validData[1]

                    if(!updateControl.isValid){

                        for(let k in newForm){ 


                            if(newForm[k].config.disabled === false && k.trim() !== upControl.id.trim()){
                                newForm[k].config.disabled = true
                            }
                        }
                    }else{

                        for(let k in newForm){ 

                            if(newForm[k].config.disabled === true){
                                newForm[k].config.disabled = false
                            }
                        }

                    }
                    
                }

            }
        
            updateControl.touched = upControl.blur
            newForm[upControl.id] = updateControl
            
            this.setState({
                form: newForm
                
            })


    } 

    controlValidate = (validateControl)=>{
        
        let validData = [true,''] 
    
        if(validateControl.validation.isRequired){ 
        
            let valid = validateControl.value.trim() !== ''
            let message = ''
            if(!valid){
                message = `${validateControl.config.name} is a required field `
            }
    
            validData = !valid ? [valid,message] : validData 
            return validData
            
        }
        if(validateControl.validation.isRequiredFormat){
            
            let value =  validateControl.value.trim() 
            let specialCharactersRegex = '[`!@#$%^&*()_+\-=\[\]]{};:"\\|,.<>\/?~]'
            let charsArray = Array.from(specialCharactersRegex)
                charsArray.push("'")
            let containsSpecialCars = charsArray.some((v)=>value.indexOf(v) >= 0)
            let pieces = value.split(/\W+/) 
            let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            console.log('SPECIAL CHARACTER TEST')
            console.log(charsArray)
            console.log(containsSpecialCars)
            console.log(value)
            console.log(specialCharactersRegex)
            let isRequiredFormat =  validateControl.validation.isRequiredFormat 
            if(isRequiredFormat.specialCharacters && containsSpecialCars === true ) return [false,`special characters are not allowed as username`]
            if(isRequiredFormat.minLetters && (value.length < isRequiredFormat.minLetters) ) return [false,`${validateControl.config.name} should be atleast ${isRequiredFormat.minLetters} letters long`]
            if(isRequiredFormat.minPieces && (pieces.length < isRequiredFormat.minPieces) ) return [false,`${validateControl.config.name} should be atleast ${isRequiredFormat.minPieces} words`] 
            if(isRequiredFormat.maxPieces && (pieces.length > isRequiredFormat.maxPieces) ) return [false,`${validateControl.config.name} should be atmost ${isRequiredFormat.maxPieces} words`] 
            if(isRequiredFormat.isNumber && isNaN(value) ) return [false,`${validateControl.config.name} should be a number with no spaces`] 
            if(isRequiredFormat.isLen && (value.length !== isRequiredFormat.isLen) ) return [false,`${validateControl.config.name} should be a total of ${isRequiredFormat.isLen} numbers`] 
            if(isRequiredFormat.isEmail && emailRegex.test(value) !== true) return [false,`${validateControl.config.name} Should be a valid email`]
        
                
        
        }
        
        return validData
    
    }
   

    submitForm = (e)=>{
                
        e.preventDefault() 

        
        
        const {actions} = this.props
        const {sendAlertSubscriber} = actions 
        let submittData = {} 
        let formIsValid = true 
        let {form} = this.state 
        let {notify} = this
        
        
        
        for(let k in form){
            
            console.log('THE SUBMIT CONTROL')
            console.log(form[k])
            if(form[k].validation && form[k].validation.isRequiredFormat){

                console.log('THE CONTROL REQUIRES VALIDATION')

                if(form[k].isValid === false){

                    console.log('CONTROL:',k, ' is not valid')
                    formIsValid = false 
                    
                    console.log(notify)
                    notify({message:`${k} must provided before you can continue.`,type: 'warning',className: 'notify-warn'})
                    break


                }else{
 
                    
                        submittData[k] = form[k].value

                   
                
                }

            }
        
        } 

        if(!formIsValid) return


    
        console.log(submittData)
        submittData.name = this.extractNameFromEmail(submittData.email)

        this.setState({...this.state,isSuccess: true},()=>{
            sendAlertSubscriber(submittData) 
        })
         
        
        
        

    }

    goToNext = (e)=>{
                
        e.preventDefault() 

        let {form} = this.state 
        let {email} = form
        let {notify} = this
        console.log('THE FORM E')
        console.log(e)

        if(!this.controlValidate(email)[0]) return notify({message:'Email must be valid before you can continue.',type: 'warning',className: 'notify-warn'})

        this.setState({...this.state,next: true},()=>{

            console.log('THE NEXT KEY')
            console.log(this.state.next)
        })
        
    }

    componentDidUpdate(){

        const {footer} = this.props 
        const {next,isSuccess} = this.state
        const {isFetching,formType,isActionSuccessful} = footer 

        if(isActionSuccessful && isSuccess && next){

            this.setState({...this.state,next: false,isSuccess: false})

        }

        
    }

    getButton = ()=>{

        const {next} = this.state 
        let el = null 

        console.log('TEH GET BUTTON NEXT')
        console.log(next)
      

        if(!next){

            
            el = ( <p className="home__search--circle top-offset-fl-sm text-align-center " >

            <button className="cursor-pointer font-fd-xx-tn bg-primary fg-light mg-top-fl-x-sm  d-inline-block pd-fd-bt bd-none" onClick={this.goToNext.bind(this)}>
                <Icon><ArrowForwardIcon className={this.props.classes.root} /></Icon>
            </button>

          </p>)

        }else{

            el = ( <p className="home__search--circle top-offset-fl-sm text-align-center ">

            <input type="submit" onClick={this.submitForm.bind(this)} className="cursor-pointer font-fd-xx-tn bg-primary fg-light mg-top-fl-x-sm  d-inline-block pd-fd-bt bd-none" value="Alert me"/>

          </p>)

        }

        return el

    }

    notify = (config) => {

        
            console.log('THE TOAST CONFIG')
            console.log(config)
            const {message} = config || ''
            const type = config.type || 'info'
            toast[type](message,
                {position: toast.POSITION.BOTTOM_LEFT,...config});
        
    }


    extractNameFromEmail(email){

        return  email.split("@")[0]
    }
        
   
    
   

    render(){

        const {footer} = this.props 
        const {next} = this.state
        const {isFetching,formType} = footer

        return(

                    <section className="mg-top-fd-md">

                        <form className="form">
                            
                            

                            <div className="hr-size-fl-xx-bg  fg-dark bd-rad-x-bt pd-fd-xxx-tn d-block mg-top-fd-bt font-fd-x-tn">

                                {
                                    !next 
                                        ? <FormControl 
                                
                                            styles={{child:'footer__alert bx-raised',error: ''}}
                                            id = 'email'
                                            controlData={this.state.form.email}
                                            change={(control)=>this.updator(control)}
                                        
                                    
                                        />

                                        : <FormControl 
                                
                                        styles={{child:'footer__alert bx-raised',error: ''}}
                                        id = 'keyword'
                                        controlData={this.state.form.keyword}
                                        change={(control)=>this.updator(control)}
                                    
                                
                                    />
                                }
                                

                            </div> 

                            <div>

                                {

                                isFetching === true && formType === 'alert'
                                ? 
                                    <div className="home__search--circle top-offset-fl-sm text-align-center" style={{backgroundColor: 'white'}}>
                                        <section className="huloader huloader-co">
                                        <div className="huloader__loading huloader__loading-co">
                                            <div></div>
                                            <div></div>
                                        </div>  
                                    </section></div>

                                : this.getButton()
                               
                                }
                            </div>

                            {/* <div className="service__modal--form-control">

                                <input type="submit" value="COUNT ME IN" onClick={(e)=>this.submitForm(e)} className="forgotpassword__launchcta-submit" />
                                
                            </div>  */}

                            



                        
                            
                        </form>

                       
                        <div>

                            {
                                
                                // isProgressBar === true && isFetching === true
                                //     ?  <ProgressBarLinear />
                                //     : null
                            }
        
                        
                        </div>

                    </section>

                    
               

             
             
           
        )
    }




}

export default withStyles(styles)(Form)