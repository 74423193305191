
import React,{Component} from 'react'
import {Link} from 'react-router-dom'
import archivefilters from '../archivefilters/archivefilters';




class ArchivePosts extends Component{
    
    
    constructor(props) {
        super(props);
        this.state = {
            months: [
                'Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'
            ]
        }
      
    }

    getPreviewText = (text)=>{

      
        let prevText = text.substr(text.indexOf('>')+1,text.indexOf("</p>"))
        console.log('THE PREVTEXT')
        console.log(prevText)
        console.log(text.indexOf('>'))
        console.log(text.indexOf('</p>'))
        console.log(prevText.length)
        // prevText = prevText.length > 240 ? prevText.substr(0, prevText.length - (prevText.length - 252)) : prevText
     

        // return `${prevText}...`
        
        return `${prevText.replace('</p> <p class="blog_">',' ').substr(0,200)}...` 
        //prevText.substr(0,200)

    }


    getDate = (date)=>{

        const {state} = this 
        const {months} = state
        let setDate = new Date(date) 
        let getDate = setDate.getDate() 
        let formAtedDate = getDate < 10 ? `0${getDate}` : getDate

        return `${months[setDate.getMonth()]} ${formAtedDate}, ${setDate.getFullYear()}`

    }


    getPost = (relatedPost,i,openBlogPage)=>{

        const {slug,image,title,created_at,author,body,post_topic_name} = relatedPost
        let url = `/blog/post/${slug}`


        return (

            <section className="archive__posts--row-i">

                <Link to={url} className="link d-block ">
                    
                    <h2 className="archive__posts--row-i-title">{title}</h2>
                    <img src={image} className="archive__posts--row-i-pik" />
                    
                    <p className="archive__posts--row-i-content">{this.getPreviewText(body)}</p>
                

                </Link>

                <span className="archive__posts--row-i-tag" onClick={(e)=>openBlogPage({topic:post_topic_name,url: '/blog/topic/'},e)}>#{post_topic_name}</span>

            </section>

        )

    }


   
 


      
    
    render(){

        const {props} = this 
        const {blogr,openBlogPage} = props 
        const {archive} = blogr 

        return(

           

                
                <div  className="archive__posts">


                      

                          
                            <div className="archive__posts--row">

                                {archive.map((p,i)=>{

                                    return this.getPost(p,i,openBlogPage)
                                })}

                                {/* <section className="archive__posts--row-i">

                                    <Link to='/' className="link d-block ">

                                        <img src="/img/users/250_3.jpg" className="archive__posts--row-i-pik" />
                                        <h2 className="archive__posts--row-i-title">Jobbri posting done</h2>
                                        <p className="archive__posts--row-i-content">sed diam nonumy eirmod tempor invidunt ut labore 
                                        et dolore magna aliquyam erat, sed diam voluptua.</p>
                                       

                                    </Link>

                                    <span className="archive__posts--row-i-tag">#update</span>

                                </section>


                                <section className="archive__posts--row-i">

                                    <Link to='/' className="link d-block ">

                                        <img src="/img/users/250_1.jpg" className="archive__posts--row-i-pik" />
                                        <h2 className="archive__posts--row-i-title">Jobbri posting done</h2>
                                        <p className="archive__posts--row-i-content">sed diam nonumy eirmod tempor invidunt ut labore 
                                        et dolore magna aliquyam erat, sed diam voluptua.</p>
                                       

                                    </Link>

                                    <span className="archive__posts--row-i-tag">#update</span>

                                </section>
                                

                                <section className="archive__posts--row-i">

                                    <Link to='/' className="link d-block ">

                                        <img src="/img/users/250_2.jpg" className="archive__posts--row-i-pik" />
                                        <h2 className="archive__posts--row-i-title">Jobbri posting done</h2>
                                        <p className="archive__posts--row-i-content">sed diam nonumy eirmod tempor invidunt ut labore 
                                        et dolore magna aliquyam erat, sed diam voluptua.</p>
                                       

                                    </Link>

                                    <span className="archive__posts--row-i-tag">#update</span>

                                </section>

                                <section className="archive__posts--row-i">

                                    <Link to='/' className="link d-block ">

                                        <img src="/img/users/250_11.jpg" className="archive__posts--row-i-pik" />
                                        <h2 className="archive__posts--row-i-title">Jobbri posting done</h2>
                                        <p className="archive__posts--row-i-content">sed diam nonumy eirmod tempor invidunt ut labore 
                                        et dolore magna aliquyam erat, sed diam voluptua.</p>
                                       

                                    </Link>

                                    <span className="archive__posts--row-i-tag">#update</span>

                                </section>

                                <section className="archive__posts--row-i">

                                    <Link to='/' className="link d-block ">

                                        <img src="/img/users/250_5.jpg" className="archive__posts--row-i-pik" />
                                        <h2 className="archive__posts--row-i-title">Jobbri posting done</h2>
                                        <p className="archive__posts--row-i-content">sed diam nonumy eirmod tempor invidunt ut labore 
                                        et dolore magna aliquyam erat, sed diam voluptua.</p>
                                       

                                    </Link>

                                    <span className="archive__posts--row-i-tag">#career</span>

                                </section> */}
                                <strong className="clearfix" />
                                
                               


                            </div>


                            {/* <div className="archive__posts--row">

                                <section className="archive__posts--row-i">

                                    <Link to='/' className="link d-block ">

                                        <img src="/img/users/250_6.jpg" className="archive__posts--row-i-pik" />
                                        <h2 className="archive__posts--row-i-title">Jobbri posting done</h2>
                                        <p className="archive__posts--row-i-content">sed diam nonumy eirmod tempor invidunt ut labore 
                                        et dolore magna aliquyam erat, sed diam voluptua.</p>
                                       

                                    </Link>

                                    <span className="archive__posts--row-i-tag">#update</span>

                                </section>


                                <section className="archive__posts--row-i">

                                    <Link to='/' className="link d-block ">

                                        <img src="/img/users/250_7.jpg" className="archive__posts--row-i-pik" />
                                        <h2 className="archive__posts--row-i-title">Jobbri posting done</h2>
                                        <p className="archive__posts--row-i-content">sed diam nonumy eirmod tempor invidunt ut labore 
                                        et dolore magna aliquyam erat, sed diam voluptua.</p>
                                       

                                    </Link>

                                    <span className="archive__posts--row-i-tag">#career</span>

                                </section>
                                

                                <section className="archive__posts--row-i">

                                    <Link to='/' className="link d-block ">

                                        <img src="/img/users/250_8.jpg" className="archive__posts--row-i-pik" />
                                        <h2 className="archive__posts--row-i-title">Jobbri posting done</h2>
                                        <p className="archive__posts--row-i-content">sed diam nonumy eirmod tempor invidunt ut labore 
                                        et dolore magna aliquyam erat, sed diam voluptua.</p>
                                       

                                    </Link>

                                    <span className="archive__posts--row-i-tag">#career</span>

                                </section>
                                <section className="archive__posts--row-i">

                                    <Link to='/' className="link d-block ">

                                        <img src="/img/users/250_9.jpg" className="archive__posts--row-i-pik" />
                                        <h2 className="archive__posts--row-i-title">Jobbri posting done</h2>
                                        <p className="archive__posts--row-i-content">sed diam nonumy eirmod tempor invidunt ut labore 
                                        et dolore magna aliquyam erat, sed diam voluptua.</p>
                                       

                                    </Link>

                                    <span className="archive__posts--row-i-tag">#update</span>

                                </section>

                                <section className="archive__posts--row-i">

                                    <Link to='/' className="link d-block ">

                                        <img src="/img/users/250_10.jpg" className="archive__posts--row-i-pik" />
                                        <h2 className="archive__posts--row-i-title">Jobbri posting done</h2>
                                        <p className="archive__posts--row-i-content">sed diam nonumy eirmod tempor invidunt ut labore 
                                        et dolore magna aliquyam erat, sed diam voluptua.</p>
                                       

                                    </Link>

                                    <span className="archive__posts--row-i-tag">#career</span>

                                </section>
                                <strong className="clearfix" />
                                
                               


                            </div>
 */}

                           


                       
                        
                 

                </div>
               
                

           
        )
    }

}


export default ArchivePosts