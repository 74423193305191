
import React, {Component} from 'react' 
import FormControl from '../../../formcontrol'
// import ProgressBarLinear from '../progressbarlinear'
import { toast } from 'react-toastify';
import TextareaAutosize from 'react-textarea-autosize';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Tooltip from '../../../tooltip'
// import ReCAPTCHA from "react-google-recaptcha"


class Bio extends  Component{


    constructor(props){

        super()

     
        this.state = {
            form : {
                    
                    
                
                name:{
                    
                    control: 'input',
                    value:'',
                    validation: {
                        isRequiredFormat:{
                            isRequired: true,
                            minLetters: 2,
                            maxLetters: 50
                           
                            
                        },
                
                    },
                    isValid: false,
                    isTouched: false,
                    validationMessage: '',
                    validationStyles: {
                        position: 'right'
                    },
                    config: {
                        
                        name: "name",
                        type: 'text',
                        // placeholder: 'surprise@turtrozz.co.za'
                        // disabled: false
                        
                    }
                    
                },
             
                phone:{
                    
                    control: 'input',
                    value:'',
                    validation: {
                        isRequiredFormat:{
                            isRequired: true,
                           
                            
                        },
                
                    },
                    isValid: false,
                    isTouched: false,
                    validationMessage: '',
                    validationStyles: {
                        position: 'right'
                    },
                    config: {
                        
                        name: "Phone",
                        type: 'text',
                        //placeholder: 'surprise@turtrozz.co.za',
                        // disabled: false
                        
                    }
                    
                },
                email:{
                    
                    control: 'input',
                    value:'',
                    validation: {
                        isRequiredFormat:{
                            isRequired: true,
                            isEmail: true
                           
                            
                        },
                
                    },
                    isValid: false,
                    isTouched: false,
                    validationMessage: '',
                    validationStyles: {
                        position: 'right'
                    },
                    config: {
                        
                        name: "email",
                        type: 'text',
                        //placeholder: 'surprise@turtrozz.co.za',
                        // disabled: false
                        
                    }
                    
                }
               
               
              
              
            
            },
            textAreas: {

            message:{

                    value:  '',
                    validation: {
                        isRequiredFormat:{

                            minLetters: 50,
                            maxLetters: 200
                
                            
                        },
                
                    },
                    isValid: false,
                    isTouched: false,
                    validationMessage: '',
                    validationStyles: {
                        position: 'top'
                    },
                    config: {
                        
                        name: "message",
                        // type: 'text',
                        // placeholder: '...type description',
                        // disabled: false
                        
                    }
                    
            }

            },
            
            
            
        };
    }

    
    updator = (upControl)=>{
        
        // console.log('THE UPDATE CONTROL')
        // console.log(upControl)
        let newForm ={...this.state.form} 
        let updateControl = {...newForm[upControl.id]}
        updateControl.value = upControl.e.target.value 
        //console.log(updateControl)

    
        
        if(!(updateControl.validation.isPlaceholder && updateControl.value.trim() === '')){

            if(upControl.blur){
            
                let validData = this.controlValidate(updateControl) 
                
                updateControl.isValid = validData[0] 
                updateControl.validationMessage = validData[1]

                if(!updateControl.isValid){

                    for(let k in newForm){ 


                        if(newForm[k].config.disabled === false && k.trim() !== upControl.id.trim()){
                            newForm[k].config.disabled = true
                        }
                    }
                }else{

                    for(let k in newForm){ 

                        if(newForm[k].config.disabled === true){
                            newForm[k].config.disabled = false
                        }
                    }

                }
                
            }

        }
    
        updateControl.isTouched = upControl.blur
        newForm[upControl.id] = updateControl 



        if(upControl.blur && updateControl.isValid){

            this.setState({
                form: newForm,
                showTextBoxInput: false,
                textBoxID: ''
                
            })


        }else{

            this.setState({
                form: newForm
                
            })


        }
        
       

} 

controlValidate = (validateControl)=>{
    
    let validData = [true,''] 

    if(validateControl.validation.isRequired){ 
    
        let valid = validateControl.value.trim() !== ''
        let message = ''
        if(!valid){
            message = `${validateControl.config.name} is a required field `
        }

        validData = !valid ? [valid,message] : validData 
        return validData
        
    }
    if(validateControl.validation.isRequiredFormat){
        
        let value =  validateControl.value.trim() 
        let specialCharactersRegex = '[`!@#$%^&*()_+\-=\[\]]{};:"\\|,.<>\/?~]'
        let charsArray = Array.from(specialCharactersRegex)
            charsArray.push("'")
        let containsSpecialCars = charsArray.some((v)=>value.indexOf(v) >= 0)
        let pieces = value.split(/\W+/) 
        let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        console.log('SPECIAL CHARACTER TEST')
        console.log(charsArray)
        console.log(containsSpecialCars)
        console.log(value)
        console.log(specialCharactersRegex)
        let isRequiredFormat =  validateControl.validation.isRequiredFormat 
        if(isRequiredFormat.specialCharacters && containsSpecialCars === true ) return [false,`special characters are not allowed as username`]
        if(isRequiredFormat.minLetters && (value.length < isRequiredFormat.minLetters) ) return [false,`${validateControl.config.name} should be atleast ${isRequiredFormat.minLetters} letters long`]
        if(isRequiredFormat.maxLetters && (value.length > isRequiredFormat.maxLetters) ) return [false,`${validateControl.config.name} should be atmost ${isRequiredFormat.maxLetters} letters long`]
        if(isRequiredFormat.minPieces && (pieces.length < isRequiredFormat.minPieces) ) return [false,`${validateControl.config.name} should be atleast ${isRequiredFormat.minPieces} words`] 
        if(isRequiredFormat.maxPieces && (pieces.length > isRequiredFormat.maxPieces) ) return [false,`${validateControl.config.name} should be atmost ${isRequiredFormat.maxPieces} words`] 
        if(isRequiredFormat.isNumber && isNaN(value) ) return [false,`${validateControl.config.name} should be a number with no spaces`] 
        if(isRequiredFormat.isLen && (value.length !== isRequiredFormat.isLen) ) return [false,`${validateControl.config.name} should be a total of ${isRequiredFormat.isLen} numbers`] 
        if(isRequiredFormat.isEmail && emailRegex.test(value) !== true) return [false,`${validateControl.config.name} Should be a valid email`]
    
            
    
    }
    
    return validData

}


submitForm = (e)=>{
    
    e.preventDefault() 
    
   
    
    
    const {props,state} = this
    const setProps = props.props.props
    const {actions} = setProps
    const {sendQueryMessage} = actions 

  
    
    let {form,textAreas} = this.state 
    let allRequiredAddKeys = Object.keys(form).length + Object.keys(textAreas).length
    let {notify} = this
    let validatedAndFetched = this.validateAndFetchInput([form,textAreas])
    const {isValid,submittData} = validatedAndFetched
  
    if(!isValid) return notify({message:'Request information must be valid before sending',type: 'warning',className: 'notify-warn'})
    if(Object.keys(submittData).length === 0) return notify({message:'Request information must be completed before sending',type: 'warning',className: 'notify-warn'})

    //  console.log('THE VALID DATA')
    //  console.log(submittData)
    sendQueryMessage(submittData)
    // if(!formIsValid) return
    // if(!isCaptchaVerified) return  notify({message:'Recaptcha must be selected before signin',type: 'warning',className: 'notify-warn'})
    
    
    

}


validateAndFetchInput = (forms)=>{


    let submittData = {} 
    let formIsValid = true 
    let shouldOuterBreak = false

    for(let it=0; it < forms.length; it++){
        
        let form = forms[it]

        for(let k in form){
        
            // console.log('THE SUBMIT CONTROL')
            // console.log(form[k])
            if(form[k].validation && form[k].validation.isRequiredFormat){

                console.log('THE CONTROL REQUIRES VALIDATION')

                if(form[k].isTouched && form[k].isValid === false){

                    // console.log('CONTROL:',k, ' is not valid')
                    formIsValid = false 
                    shouldOuterBreak = true
                    
          
    
                    //break


                }else if(form[k].isTouched){
 
                    submittData[k] = form[k].value

                }

            }else{

                if(form[k].value.trim() !== ''){

                    submittData[k] = form[k].value

                }
               

            }
          
        
        } 

    }

    return {isValid: formIsValid,submittData: submittData}

   

        
}



// submitForm = (e)=>{
    
//     e.preventDefault() 
    
    
//     const {props} = this 
//     console.log('THE PROPS')
//     console.log(props)
//     const setProps = props.props.props
//     const {actions} = setProps
//     const {sendQueryMessage} = actions 
//     let submittData = {} 
//     let formIsValid = true 
//     let {form} = this.state 
//     let {notify} = this
    
    
    
//     for(let k in form){
        
//         // console.log('THE SUBMIT CONTROL')
//         // console.log(form[k])
//         if(form[k].validation && form[k].validation.isRequiredFormat){

//             console.log('THE CONTROL REQUIRES VALIDATION')

//             if(form[k].isValid === false){

//                 // console.log('CONTROL:',k, ' is not valid')
//                 formIsValid = false 
                
//                 console.log(notify)
//                 notify({message:'Required information must be valid',type: 'warning',className: 'notify-warn'})
//                 break


//             }else{

//                 submittData[k] = form[k].value

//             }

//         }else{

//             if(form[k].value.trim() !== ''){

//                 submittData[k] = form[k].value

//             }
            

//         }
        
    
//     } 

//     if(!formIsValid) return 
//     sendQueryMessage(submittData)
//     // if(!isCaptchaVerified) return  notify({message:'Recaptcha must be selected before signin',type: 'warning',className: 'notify-warn'})


    
    

// }
    

    notify = (config) => {

        
        console.log('THE TOAST CONFIG')
        console.log(config)
        // const {props} = this 
        // const {actions,auth} = props 
        // const {removeShownMessage} = actions
        const {message} = config || ''
        const type = config.type || 'info'
        // const errMessage = auth.message
        toast[type](message,
            {position: toast.POSITION.BOTTOM_LEFT,...config});

        // if(errMessage !== ''){

        //     removeShownMessage()

        // }
        
        
    }


    count = (event,id)=>{

        console.log('I AM CHANGING')
        console.log(event)
        console.log(event.target)
        console.log(event.target.value)
        console.log(event.target.value.trim().length)

        let newTextareas ={...this.state.textAreas} 
        let updateControl = {...newTextareas[id]}
        updateControl.value = event.target.value 

        let validData = this.controlValidate(updateControl) 
        console.log('THE VALIDATE TEXTBOX RESPONSE')
        console.log(validData)
                    
        updateControl.isValid = validData[0] 
        updateControl.validationMessage = validData[1]
        updateControl.isTouched = true

        newTextareas[id] = updateControl 

        this.setState({

            textAreas: newTextareas
                    
        })

            
        


    }
    
   

    render(){

        
          const {props,state} = this 
          const {textAreas} = state  
          const setProps = props.props.props
          const {contact} = setProps 
          const {isFetching=false} = contact 
          console.log('THE CONTACT') 
          console.log(contact)

          
     
          console.log('THE VALUE OF PROPS IN CONTACT')
          console.log(props)
        // const {actions,isEdit,allowEdit,experience={},index=0,isAdding=false,experienceAdd= null} = props 
        //  const editContStyles = isEdit ? {boxShadow: '-15px 10px 25px 5px rgba(0, 0, 0, 0.04);'} : {boxShadow: 'none'}
        // const {push} = actions
        // const {message = ''} = auth 
        // console.log('tHE MESSAGE')
        // console.log(message)
        // console.log(message.trim() !== '')
        // console.log('THE INDEX')
        // console.log(index)
        // console.log(parseInt(index))
        // let card = index === 0 ? '' : `resume__experience--card-${parseInt(index)+1}` 
        // console.log(card)



        return(

                   

                       
                        

           

                <form className="form contact__mail--jo-form">
            
             


                 <div className="contact__mail--jo-form-control">

                    <section className="contact__mail--jo-form-control-combine">

                            <label className="contact__mail--jo-form-control-label">FirstName</label>
                            <FormControl 

                                styles={{child:'contact__mail--jo-form-control-input',error: ''}}
                                id = 'name'
                                controlData={this.state.form.name}
                                change={(control)=>this.updator(control)}
                                // placeHolder={experience.company || ''}

                                

                            />
                    </section>

                    <section className="contact__mail--jo-form-control-combine">

                            <label className="contact__mail--jo-form-control-label">Phone</label>
                            <FormControl 

                                styles={{child:'contact__mail--jo-form-control-input',error: ''}}
                                id = 'phone'
                                controlData={this.state.form.phone}
                                change={(control)=>this.updator(control)}
                                // placeHolder={experience.position || ''}

                                

                            />
                    </section>

                    <section className="contact__mail--jo-form-control-combine">

                            <label className="contact__mail--jo-form-control-label">Email</label>
                            <FormControl 

                                styles={{child:'contact__mail--jo-form-control-input',error: ''}}
                                id = 'email'
                                controlData={this.state.form.email}
                                change={(control)=>this.updator(control)}
                                // placeHolder={experience.position || ''}

                                

                            />
                    </section>
                    <strong className="clearfix" />
                </div>

               
                       

                    <div className="contact__mail--jo-form-control contact__mail--jo-form-control-texta">

                            <label className="contact__mail--jo-form-control-label">Message</label>
                            <TextareaAutosize   className="contact__mail--jo-text contact__mail--jo-text-edit" style={{width: '100%',marginBottom: '10px'}} defaultValue={ ''} onChange={(e)=> this.count(e,'message')} />
                            {!textAreas.message.isValid && textAreas.message.validationMessage.trim()
                                ? <Tooltip message={textAreas.message.validationMessage} position={'top'} active={true} /> 
                                : null
                            }
                            <span className="contact__mail--jo-form-control-textarea-counter">
                                <small className="contact__mail--jo-form-control-textarea-counter-count">{textAreas.message.value.length}</small>
                                <small className="contact__mail--jo-form-control-textarea-counter-sep">Of</small>
                                <small className="contact__mail--jo-form-control-textarea-counter-total">{textAreas.message.validation.isRequiredFormat.maxLetters}</small>
                            </span>
                        

                    </div> 


                        <div className="contact__mail--jo-form-control-btn">


                                {
                                    isFetching === true 
                                    ? 
                                        <div className="contact__mail--jo-form-control-btn-btn ">
                                            <section className="huloader huloader-co">
                                                <div className="huloader__loading huloader__loading-co">
                                                    <div></div>
                                                    <div></div>
                                                </div>  
                                            </section>
                                        </div>
                                     
                                 : <button className="contact__mail--jo-form-control-btn-btn " onClick={(e)=>this.submitForm(e)}>
                                        <span className="contact__mail--jo-form-control-btn-btn-rect"></span>
                                        <span className="contact__mail--jo-form-control-btn-btn-rect-2">
                                        <small>Send</small></span>
                                    </button>
                                            

                                    
                                 } 


                                

                                
                             
                        </div>

            
            </form>
   
             
           
        )
    }




}

export default Bio