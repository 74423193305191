
import React,{Component} from 'react'
import What from './what/what' 
import Who from './who/who'
// import Post from './post/post'


class Employer extends Component{
    
    
    render(){

        return(

            <div className="grid-row">

                
                <div  className="home--what-who-cont">
                    <h2 className="font-fd-tn mg-bottom-fd-x-bg pd-left-fl-x-bt">Jobbri</h2>
                    <span className="home__id"></span>
                    <What />
                    <Who actions={this.props.actions} width={this.props.width} />
                    <strong className="clearfix" />
                    <div className="home__blog" style={{width: 300+'px'}}>
                        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" style={{width: `100%`}}>
                            <path fill="#FA4D56" 
                            d="M50.9,-59.5C60.3,-52.8,58.3,-31.4,59.7,-12.2C61.2,7,66,24,62.3,41.2C58.6,58.5,46.4,76.1,30.7,79.8C15.1,83.5,-4,73.3,-21.7,64.6C-39.3,56,-55.7,48.8,-66.1,35.8C-76.5,22.7,-81,3.8,-79.1,-15.2C-77.3,-34.2,-69.1,-53.4,-54.8,-59.2C-40.6,-65,-20.3,-57.5,0.2,-57.8C20.8,-58.1,41.5,-66.2,50.9,-59.5Z" 
                            transform="translate(100 100)" />
                                
                        </svg>
                    </div>

                </div>
               
                

            </div>
        )
    }

}


export default Employer