import React from 'react';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import Trash from '@material-ui/icons/Delete';


const styles = {
  root: {
     
    color: '#cc3333',
    opacity: '.7',
    fontSize: 22,
    
  },
  iconhover:{
      '&:hover':{

          content: ""
      }
  }
};



class Button extends React.Component{

  constructor(props){
    super();

  }

 


  render(){

    const {classes} = this.props 
    const {openPopup} = this.props
    return(

      <button onClick={openPopup} style={{border:'none',cursor:'pointer',backgroundColor: 'transparent'}}>
          <span>Deactivate Account</span>
      </button>
      
    )
  }
}

export default withStyles(styles)(Button)