
import React, {Component} from 'react' 
import FormControl from '../formcontrol'
// import ProgressBarLinear from '../progressbarlinear'
import { toast } from 'react-toastify';
// import ReCAPTCHA from "react-google-recaptcha"


class Bio extends  Component{


    constructor(props){

        super()

      
     
        this.state = {
            form : {
                    
                    
                
                profession:{
                    
                    control: 'input',
                    value:'',
                    validation: {
                        isRequiredFormat:{
                            isRequired: true,
                            minLetters: 5
                           
                            
                        },
                
                    },
                    isValid: false,
                    isTouched: false,
                    validationMessage: '',
                    validationStyles: {
                        position: 'right'
                    },
                    config: {
                        
                        name: "profession",
                        type: 'text',
                        // placeholder: 'surprise@turtrozz.co.za'
                        // disabled: false
                        
                    }
                    
                },
               
              
                
               

              
            
            },
            
        };
    }

    
    updator = (upControl)=>{
        
            // console.log('THE UPDATE CONTROL')
            // console.log(upControl)
            let newForm ={...this.state.form} 
            let updateControl = {...newForm[upControl.id]}
            updateControl.value = upControl.e.target.value 
            const {props} = this 
            const {saveProfessionSubmit} = props 
            const self = this 
            //console.log(updateControl)

            if(!(updateControl.validation.isPlaceholder && updateControl.value.trim() === '')){

                if(upControl.blur){
                
                    let validData = this.controlValidate(updateControl) 
                    
                    updateControl.isValid = validData[0] 
                    updateControl.validationMessage = validData[1]

                    if(!updateControl.isValid){

                        for(let k in newForm){ 


                            if(newForm[k].config.disabled === false && k.trim() !== upControl.id.trim()){
                                newForm[k].config.disabled = true
                            }
                        }
                    }else{

                        for(let k in newForm){ 

                            if(newForm[k].config.disabled === true){
                                newForm[k].config.disabled = false
                            }
                        }

                    }
                    
                }

            }
        
            updateControl.isTouched = upControl.blur
            newForm[upControl.id] = updateControl 

            saveProfessionSubmit({submitForm: self.submitForm.bind(self)})
            this.setState({
                    form: newForm
                    
            })
           

    } 

    controlValidate = (validateControl)=>{
        
        let validData = [true,''] 
    
        if(validateControl.validation.isRequired){ 
        
            let valid = validateControl.value.trim() !== ''
            let message = ''
            if(!valid){
                message = `${validateControl.config.name} is a required field `
            }
    
            validData = !valid ? [valid,message] : validData 
            return validData
            
        }
        if(validateControl.validation.isRequiredFormat){
            
            let value =  validateControl.value.trim() 
            let specialCharactersRegex = '[`!@#$%^&*()_+\-=\[\]]{};:"\\|,.<>\/?~]'
            let charsArray = Array.from(specialCharactersRegex)
                charsArray.push("'")
            let containsSpecialCars = charsArray.some((v)=>value.indexOf(v) >= 0)
            let pieces = value.split(/\W+/) 
            let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            console.log('SPECIAL CHARACTER TEST')
            console.log(charsArray)
            console.log(containsSpecialCars)
            console.log(value)
            console.log(specialCharactersRegex)
            let isRequiredFormat =  validateControl.validation.isRequiredFormat 
            if(isRequiredFormat.specialCharacters && containsSpecialCars === true ) return [false,`special characters are not allowed as username`]
            if(isRequiredFormat.minLetters && (value.length < isRequiredFormat.minLetters) ) return [false,`${validateControl.config.name} should be atleast ${isRequiredFormat.minLetters} letters long`]
            if(isRequiredFormat.maxLetters && (value.length > isRequiredFormat.maxLetters) ) return [false,`${validateControl.config.name} should be atmost ${isRequiredFormat.maxLetters} letters long`]
            if(isRequiredFormat.minPieces && (pieces.length < isRequiredFormat.minPieces) ) return [false,`${validateControl.config.name} should be atleast ${isRequiredFormat.minPieces} words`] 
            if(isRequiredFormat.maxPieces && (pieces.length > isRequiredFormat.maxPieces) ) return [false,`${validateControl.config.name} should be atmost ${isRequiredFormat.maxPieces} words`] 
            if(isRequiredFormat.isNumber && isNaN(value) ) return [false,`${validateControl.config.name} should be a number with no spaces`] 
            if(isRequiredFormat.isLen && (value.length !== isRequiredFormat.isLen) ) return [false,`${validateControl.config.name} should be a total of ${isRequiredFormat.isLen} numbers`] 
            if(isRequiredFormat.isEmail && emailRegex.test(value) !== true) return [false,`${validateControl.config.name} Should be a valid email`]
        
                
        
        }
        
        return validData
    
    }
   

    submitForm = (e=null)=>{
        
        if(e) e.preventDefault() 
        
    
        const {props} = this
        const {saveFormInput} = props
  
        let {form} = this.state 
        let {notify} = this
        let validatedAndFetched = this.validateAndFetchInput([form])
        const {isValid,submittData} = validatedAndFetched
      
        if(!isValid) return {message:'Identity information must be valid before saving'}
        if(Object.keys(submittData).length === 0) return {message: 'Identity information must be updated before saving'} 
        return {data: submittData,message: null}
        
        
    }

    validateAndFetchInput = (forms)=>{


        let submittData = {} 
        let formIsValid = true 
        let shouldOuterBreak = false

        for(let it=0; it < forms.length; it++){
            
            let form = forms[it]

            for(let k in form){
            
                // console.log('THE SUBMIT CONTROL')
                // console.log(form[k])
                if(form[k].validation && form[k].validation.isRequiredFormat){
    
                    console.log('THE CONTROL REQUIRES VALIDATION')
    
                    if(form[k].isTouched && form[k].isValid === false){
    
                        // console.log('CONTROL:',k, ' is not valid')
                        formIsValid = false 
                        shouldOuterBreak = true
                        
              
        
                        //break
    
    
                    }else if(form[k].isTouched){
     
                        submittData[k] = form[k].value
    
                    }
    
                }else{
    
                    if(form[k].value.trim() !== ''){
    
                        submittData[k] = form[k].value
    
                    }
                   
    
                }
              
            
            } 

        }

        return {isValid: formIsValid,submittData: submittData}

       

            
    }

    notify = (config) => {

        
        console.log('THE TOAST CONFIG')
        console.log(config)
        // const {props} = this 
        // const {actions,auth} = props 
        // const {removeShownMessage} = actions
        const {message} = config || ''
        const type = config.type || 'info'
        // const errMessage = auth.message
        toast[type](message,
            {position: toast.POSITION.BOTTOM_LEFT,...config});

        // if(errMessage !== ''){

        //     removeShownMessage()

        // }
        
        
    }
        

   

    render(){

        
        //  const {props,state} = this 
        // //  const {bioText} = state
        //  const {actions,isEdit,allowEdit} = props 
        //  const editContStyles = isEdit ? {boxShadow: '-15px 10px 25px 5px rgba(0, 0, 0, 0.04);'} : {boxShadow: 'none'}
        // const {push} = actions
        // const {message = ''} = auth 
        // console.log('tHE MESSAGE')
        // console.log(message)
        // console.log(message.trim() !== '')


        return(

                   

                        <form className="form resume__about--bio-contact-form">


                        <div className=" resume__about--bio-contact-form--control  ">

                        
                           <section className="resume__about--bio-contact-form--control-combine" style={{width: '80%'}}>

                                
                                <FormControl 

                                    styles={{child:'resume__about--bio-contact-form--control-input',error: ''}}
                                    id = 'profession'
                                    controlData={this.state.form.profession}
                                    change={(control)=>this.updator(control)}
                                    placeHolder={'type your profession...'}
                                
                                    

                                />
                            </section>
                        </div> 

                        

                
                        </form>
                             
           
        )
    }




}

export default Bio