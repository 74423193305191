
import * as types from './types'
import * as props from './props'
import { saveAs } from 'file-saver'
//import { actions } from 'reapop'
const manipulateResumeUpdateItem =(state,action)=>{

    let newResume = {...state.profile}
    const {response} = action 
    const responseResume = response.resume
    // const {documentID,document} = responseResume  

    responseResume.forEach((resI,ri)=>{

        const {documentID,document} = resI  

        if(newResume[documentID] instanceof Array){

            // console.log('THE KEY IS AN ARRAY')
    
            let index = -1        
            let updatingActor = [...newResume[documentID]]
            let updatingDocument = updatingActor.filter((a,i)=>{ 
                // console.log('THE INDEX')
                // console.log(i)
                // console.log(index)
                // console.log(a)
                // index = i 
                if(a.id === document.id){
    
                    index = i
                    return a.id === document.id
    
                }
               
            })
    
            // console.log('THE UPDATING ACTOR')
            // console.log(updatingActor)
            // console.log('the updating document')
            // console.log(updatingDocument)
            // console.log('THE DOCUMENT')
            // console.log(document)
    
            if(updatingDocument.length > 0){
    
                // console.log('THE UPDATING INDEX')
                // console.log(index)
                // console.log(updatingActor[index])
                updatingActor[index] =  {...updatingDocument[0],...document}
    
            }
           
            newResume = {...newResume,[documentID]: [...updatingActor]}
                    
                    
                    
                
    
        }else{
    
    
    
                newResume = {...newResume,[documentID]: {...newResume[documentID],...document}}
                      
                    
                
        }


    })

  
    return  {
    
        ...state,
        isFetching: false,
        isActionSuccessful: true,
        isResumeUpdate: true, 
        profile: {...state.profile,...newResume}
          
        
    }

}

const manipulateResumeDeleteItem =(state,action)=>{


    let newResume = {...state.profile}
    const {response} = action 
    const responseResume = response.resume
    // const {documentID,document} = responseResume  

    responseResume.forEach((resI,ri)=>{

        const {documentID,document,isDeleted = false} = resI  
        console.log('RESI')
        console.log(resI)

        if(newResume[documentID] instanceof Array){


            if(isDeleted){

                let index = -1        
                let updatingActor = [...newResume[documentID]]
                let updatingDocument = updatingActor.filter((a,i)=>{ 

                    console.log('DELETE A')
                    console.log(a)
                    console.log(i)
                    if(a.id === document.id){

                        index = i
                        return true

                    }
                   
                })

                console.log('THE DOCUMENT')
                console.log(updatingActor)
                console.log(updatingDocument)
                console.log(index)
        
                if(updatingDocument.length > 0){

                    console.log('THE UPDATING DOCUMENT')
                    console.log(updatingActor)

                    updatingActor.splice(index,1)
                }

                console.log('updating after')
                console.log(updatingActor)

                newResume = {...newResume,[documentID]: [...updatingActor]}


            }
                    
              
        }

      
    


    })

  
    return  {
    
        ...state,
        isFetching: false,
        isActionSuccessful: true, 
        isResumeUpdate: true, 
        profile: {...state.profile,...newResume}
          
        
    }

    

}

const manipulateResumeAddItem =(state,action)=>{


    let newResume = {...state.profile}
    const {response} = action 
    const responseResume = response.resume
    // const {documentID,document} = responseResume  

    responseResume.forEach((resI,ri)=>{

        const {documentID,document} = resI  

        if(newResume[documentID] instanceof Array){

 
            let updatingActor = [...newResume[documentID],document]
             newResume = {...newResume,[documentID]: [...updatingActor]}
                    
              
        }
    


    })

  
    return  {
    
        ...state,
        isFetching: false,
        isActionSuccessful: true, 
        isResumeUpdate: true, 
        profile: {...state.profile,...newResume}
          
        
    }

  
}

const saveDownloadResume =(state,action)=>{

  


    const {response} = action 
    const {profile} = state 
    const {personal} = profile 
    const {first_name} = personal 
    const name = first_name.split(' ')[0]


    const resume_name=`${name}_jobbri_cv`

    
    const pdfBlob = new Blob([response], { type: 'application/docx' });
    saveAs(pdfBlob, resume_name);

  
    return  {
    
        ...state,
        isFetching: false,
        isActionSuccessful: true, 
        isUploadResume: true
       
          
        
    }

  
}

const saveDownloadSharedResume =(state,action)=>{

  

    

    
    
    // const {}
    // let newResume = {...state.profile}
    console.log('THE ACTION')
    console.log(action)
    const {response,postLaunch} = action 
    const {format='pdf'} = postLaunch 
    const applicationFormat = `application/${format}`
    const {profile} = state 
    const {personal} = profile 
    const {first_name} = personal 
    const name = first_name.split(' ')[0]


    const resume_name=`${name}-jobbri-cv`

    // const {documentID,document} = responseResume 
    
    
    const pdfBlob = new Blob([response], { type: applicationFormat });
    saveAs(pdfBlob, resume_name);

  
    return  {
    
        ...state,
        isFetching: false,
        isActionSuccessful: true, 
        isUploadResume: true
       
          
        
    }

  
}

const adjustReplacedResume =(state,action)=>{

  

    let newResume = {...state.profile}
    let personal = {...newResume.personal}
    const {response} = action 
    const {actor} = response
    // const responseResume = response.resume

  
    return  {

        ...state,
        isFetching: false,
        isActionSuccessful: true, 
        isResumeReplaced: true,
        isUploadResume: true,
        profile: {personal: {...personal,...actor},skill: [],portfolio:[],education:[],experience:[],language:[]}
        
    } 
    
          
        
    

  
}

const updateLocalUserAlert = (state,action)=>{




    
    const newAlerts = [...state.profile.alerts]
    const {response} = action 
    const {id,frequency}= response
    let index = -1        
    let updateAlert = newAlerts.filter((a,i)=>{ 

        
        if(a.id === id){

            index = i
            return true

        }
       
    })

    if(updateAlert.length > 0){ newAlerts[index] =  {...updateAlert[0],frequency: frequency} }
   






    return  {
    
        ...state,
        isFetching: false,
        isActionSuccessful: true, 
        profile: {...state.profile,alerts: [...newAlerts]}
          
        
    }
}

const deleteLocalUserAlert = (state,action)=>{




    
    const newAlerts = [...state.profile.alerts]
    const {response} = action 
    const {id,alertsCount}= response
    let index = -1        
    let updateAlert = newAlerts.filter((a,i)=>{ 

        
        if(a.id === id){

            index = i
            return true

        }
       
    })

    // if(updateAlert.length > 0){ newAlerts[index] =  {...updateAlert[0],frequency: frequency} }
    if(updateAlert.length > 0) newAlerts.splice(index,1)
   






    return  {
    
        ...state,
        isFetching: false,
        isActionSuccessful: true, 
        isAlertDelete: true,
        profile: {...state.profile,alerts: [...newAlerts],alertsCount: alertsCount}
          
        
    }
}

const manipulateDeleteBookmark = (state,action)=>{




    
    const newItem = [...state.profile.savedJobs]
    const {response} = action 
    const {id,savedCount}= response
    let index = -1        
    let updateItem = newItem.filter((a,i)=>{ 

        
        if(a.id === id){

            index = i
            return true

        }
       
    })

    // if(updateAlert.length > 0){ newAlerts[index] =  {...updateAlert[0],frequency: frequency} }
    if(updateItem.length > 0) newItem.splice(index,1)
   






    return  {
    
        ...state,
        isFetching: false,
        isActionSuccessful: true, 
        profile: {...state.profile,savedJobs: [...newItem],savedCount: savedCount}
          
        
    }
}
 
const manipulateDeleteHistory = (state,action)=>{




    
    const newItem = [...state.profile.searchHistory]
    const {response} = action 
    const {id,searchCount}= response
    let index = -1        
    let updateItem = newItem.filter((a,i)=>{ 

        
        if(a.id === id){

            index = i
            return true

        }
       
    })

    // if(updateAlert.length > 0){ newAlerts[index] =  {...updateAlert[0],frequency: frequency} }
    if(updateItem.length > 0) newItem.splice(index,1)
   






    return  {
    
        ...state,
        isFetching: false,
        isActionSuccessful: true, 
        profile: {...state.profile,searchHistory: [...newItem],searchCount: searchCount}
          
        
    }
}


const manipulateBookmarkItem =(state,action)=>{



    const newItem = [...state.profile.savedJobs]
    const {response} = action 
    const {bookmark,savedCount}= response
    // let index = -1        
    // let updateItem = newItem.filter((a,i)=>{ 

        
    //     if(a.id === id){

    //         index = i
    //         return true

    //     }
       
    // })

    // if(updateAlert.length > 0){ newAlerts[index] =  {...updateAlert[0],frequency: frequency} }
    // if(updateItem.length > 0) newItem.splice(index,1)
   


    return  {
    
        ...state,
        isFetching: false,
        isActionSuccessful: true, 
        profile: {...state.profile,savedJobs: [...newItem,bookmark],savedCount: savedCount}
          
        
    }
}

const manipulateSearchHistoryItem =(state,action)=>{



    const newItem = [...state.profile.searchHistory]
    const {response} = action 
    const {search,searchCount,searchExist=false}= response 

    // console.log('TGE RESULTING SEARCH')
    // console.log(search)
    if(searchExist) return  {...state,isFetching: false}
        
          
    // let index = -1        
    // let updateItem = newItem.filter((a,i)=>{ 

        
    //     if(a.id === id){

    //         index = i
    //         return true

    //     }
       
    // })

    // if(updateAlert.length > 0){ newAlerts[index] =  {...updateAlert[0],frequency: frequency} }
    // if(updateItem.length > 0) newItem.splice(index,1)
   


    return  {
    
        ...state,
        isFetching: false,
        //isActionSuccessful: true, 
        profile: {...state.profile,searchHistory: [...newItem,search],searchCount: searchCount}
          
        
    }
}





export default (state = props, action)=>{

    switch (action.type) {


        case types.FETCH_USER_PROFILE_REQUEST:

            return  {

                ...state,
                isFetching: true,
                isProgressBar: action.payload,
                isProfile: true
                
            }

        case types.FETCH_USER_PROFILE_SUCCESS:

            
            return  {

                ...state,
                isFetching: false,
                isProgressBar: false,
                isProfile: true,
                profile: {...action.response}
               
              
               
                
            }
        case types.RECEIVE_USER_PROFILE:

        
            return  {

                ...state,
                isFetching: false,
                isProgressBar: false,
                isProfile: true,
                profile: {...action.user}
                
                
                
                
            }

        case types.FETCH_USER_PROFILE_FAILURE:

            return  {

                ...state,
                isFetching: false,
                isProgressBar: false,
                isProfile: true,
                message: action.error
                
            }
           
        case types.SEND_USER_ALERT_UPDATE_REQUEST:

            return  {

                ...state,
                isFetching: true,
                //isProgressBar: action.payload
                
            }

        case types.SEND_USER_ALERT_UPDATE_SUCCESS:


            
            return updateLocalUserAlert(state,action) 
            
            

        case types.SEND_USER_ALERT_UPDATE_FAILURE:

            return  {

                ...state,
                isFetching: false,
                //isProgressBar: false,
                message: action.error
                
            }




        case types.DELETE_USER_ALERT_REQUEST:

            return  {

                ...state,
                isFetching: true,
                //isProgressBar: action.payload
                
            }

        case types.DELETE_USER_ALERT_SUCCESS:

            
           return deleteLocalUserAlert(state,action)

        case types.DELETE_USER_ALERT_FAILURE:

            return  {

                ...state,
                isFetching: false,
                isAlertDelete: true,
                message: action.error
                
            }
    

        case types.SET_ALERT_UPDATE_DATA:

            return  {

                ...state,
                isFetching: false,
                isProgressBar: false,
                update: {...action.payload}
                
            }
      
        
        case types.SEND_USER_AVATAR_UPDATE_REQUEST:

            return  {

                ...state,
                isFetching: true,
                isProgressBar: action.payload,
                isProfile: true
                
            }

        case types.SEND_USER_AVATAR_UPDATE_SUCCESS:

            
            return  {

                ...state,
                isFetching: false,
                isProgressBar: false,
                isProfile: true,
                isActionSuccessful: true,
                profile: {...state.profile,profileUrl: action.response.profileUrl,version: action.response.version},
                launcher: action.launcher
                
            }

        case types.SEND_USER_AVATAR_UPDATE_FAILURE:

            return  {

                ...state,
                isFetching: false,
                isProgressBar: false,
                isProfile: true,
                message: action.error
                
            }



        case types.FETCH_RESUME_REQUEST:

            return  {

                ...state,
                isFetching: true,
                isInitFetching: true,
                isResumeFetchError: false
                
            }

        case types.FETCH_RESUME_SUCCESS:


            return  {

                ...state,
                isFetching: false,
                isInitFetching: false,
                isInitSuccess: true,
                isActionSuccessful: true,
                isResumeFetchError: false,
                profile: {...state.profile,...action.response.resume}
                
                
                
            }

        case types.FETCH_RESUME_FAILURE:

            return  {

                ...state,
                isFetching: false,
                isInitFetching: false,
                isInitMessage: true,
                isResumeFetchError: true,
                message: action.error
                
            } 
    
        case types.UPDATE_RESUME_ITEM_REQUEST:

            return  {

                ...state,
                isFetching: true
                
            }

        case types.UPDATE_RESUME_ITEM_SUCCESS:

            return manipulateResumeUpdateItem(state,action) 
            

        case types.UPDATE_RESUME_ITEM_FAILURE:

            return  {

                ...state,
                isFetching: false,
                isActionSuccessful: false,
                isResumeUpdate: false, 
                message: action.error
                
            }


        case types.DELETE_RESUME_ITEM_REQUEST:

            return  {

                ...state,
                isFetching: true
                
            }

        case types.DELETE_RESUME_ITEM_SUCCESS:

            return manipulateResumeDeleteItem(state,action) 
            

        case types.DELETE_RESUME_ITEM_FAILURE:

            return  {

                ...state,
                isFetching: false,
                isResumeUpdate: false, 
                isActionSuccessful: false, 
                message: action.error
                
            }

        case types.UPDATE_RESUME_ITEM_SUCCESS:

            return manipulateResumeUpdateItem(state,action) 
            

        case types.UPDATE_RESUME_ITEM_FAILURE:

            return  {

                ...state,
                isFetching: false,
                isResumeUpdate: false, 
                isActionSuccessful: false, 
                message: action.error
                
            }


        case types.ADD_RESUME_ITEM_REQUEST:

            return  {

                ...state,
                isFetching: true
                
            }

        case types.ADD_RESUME_ITEM_SUCCESS:

            return manipulateResumeAddItem(state,action) 
            

        case types.ADD_RESUME_ITEM_FAILURE:

            return  {

                ...state,
                isFetching: false,
                isResumeUpdate: false, 
                isActionSuccessful: false, 
                message: action.error
                
            }


        case types.DELETE_RESUME_REQUEST:

            return  {

                ...state,
                isFetching: true
                
            }

        case types.DELETE_RESUME_SUCCESS:

            return  {

                ...state,
                isFetching: false,
                isActionSuccessful: true, 
                profile: {},
                
                
                
            }

            

        case types.DELETE_RESUME_FAILURE:

            return  {

                ...state,
                isFetching: false,
                isActionSuccessful: false, 
                message: action.error
                
            }

    
        case types.SEND_RESUME_AVATAR_UPDATE_REQUEST:

            return  {

                ...state,
                isFetching: true
                
            }

        case types.SEND_RESUME_AVATAR_UPDATE_SUCCESS:

            return  {

                ...state,
                isFetching: false,
                isActionSuccessful: true, 
                isResumeUpdate: true, 
                profile: {...state.profile,personal: {...state.profile.personal,profile_url: action.response.profileUrl,version: action.response.version}},
                
                
                
            }

            

        case types.SEND_RESUME_AVATAR_UPDATE_FAILURE:

            return  {

                ...state,
                isFetching: false,
                isResumeUpdate: false, 
                isActionSuccessful: false, 
                message: action.error
                
            }


            case types.SAVE_UPLOAD_RESUME_REQUEST:

                return  {
    
                    ...state,
                    isFetching: true
                    
                }
    
            case types.SAVE_UPLOAD_RESUME_SUCCESS:
    
                return  {
    
                    ...state,
                    isFetching: false,
                    isActionSuccessful: true,
                    isUploadResume: true, 
                    profile: {...state.profile,
                        personal: {
                            ...state.profile.personal,
                            resume_url: action.response.actor.resume_url,
                            is_upload_resume: action.response.actor.is_upload_resume}},
                    
                    
                    
                }
    
                
    
            case types.SAVE_UPLOAD_RESUME_FAILURE:
    
                return  {
    
                    ...state,
                    isFetching: false,
                    isActionSuccessful: false, 
                    isUploadResume: false, 
                    message: action.error
                    
                }
            case types.DOWNLOAD_UPLOAD_RESUME_REQUEST:

                return  {
    
                    ...state,
                    isFetching: true
                    
                }
    
            case types.DOWNLOAD_UPLOAD_RESUME_SUCCESS:
    
                return  saveDownloadResume(state,action)
    
                
    
            case types.DOWNLOAD_UPLOAD_RESUME_FAILURE:
    
                return  {
    
                    ...state,
                    isFetching: false,
                    isActionSuccessful: false, 
                    message: action.error
                    
                } 

            case types.DOWNLOAD_SHARE_RESUME_REQUEST:

                return  {
    
                    ...state,
                    isFetching: true
                    
                }
    
            case types.DOWNLOAD_SHARE_RESUME_SUCCESS:
    
                return  saveDownloadSharedResume(state,action)
    
                
    
            case types.DOWNLOAD_SHARE_RESUME_FAILURE:
    
                return  {
    
                    ...state,
                    isFetching: false,
                    isActionSuccessful: false, 
                    message: action.error
                    
                }

        
            case types.DELETE_UPLOAD_RESUME_REQUEST:

                return  {
    
                    ...state,
                    isFetching: true
                    
                }
    
            case types.DELETE_UPLOAD_RESUME_SUCCESS:
    
                return{
                    
                ...state,
                isFetching: false,
                isActionSuccessful: true,
                isResumeDeleted: true, 
                isUploadResume: true, 
                profile: {...state.profile,
                            personal: {
                                ...state.profile.personal,
                                resume_url: action.response.actor.resume_url,
                                is_upload_resume: action.response.actor.is_upload_resume
                            }
                        }

                
                
                
                }

    
                
    
            case types.DELETE_UPLOAD_RESUME_FAILURE:
    
                return  {
    
                    ...state,
                    isFetching: false,
                    isActionSuccessful: false, 
                    isResumeDeleted: false,
                    isUploadResume: false, 
                    message: action.error
                    
                }


            case types.REPLACE_RESUME_REQUEST:

                return  {
    
                    ...state,
                    isFetching: true
                    
                }
    
            case types.REPLACE_RESUME_SUCCESS:
    
                return  adjustReplacedResume(state,action)
                
    
            case types.REPLACE_RESUME_FAILURE:
    
                return  {
    
                    ...state,
                    isFetching: false,
                    isActionSuccessful: false, 
                    isUploadResume: false, 
                    message: action.error
                    
                } 


        case types.SEND_USER_PROFILE_UPDATE_REQUEST:

            return  {

                ...state,
                isFetching: true,
                isProfile: true,
                isProgressBar: action.payload
                
            }
    


        case types.SEND_USER_PROFILE_UPDATE_SUCCESS:

        
            return  {

                ...state,
                isFetching: false,
                isProgressBar: false,
                isActionSuccessful: true,
                isProfile: true,
                profile: {...state.profile,...action.response.update},
                launcher: action.launcher
               
                
            }

        case types.SEND_USER_PROFILE_UPDATE_FAILURE:

            return  {

                ...state,
                isFetching: false,
                isProgressBar: false,
                isProfile: true,
                message: action.error
                
            } 


      

        case types.SEND_USER_ACCOUNT_DELETE_REQUEST:

            return  {

                ...state,
                isFetching: true,
                isProgressBar: action.payload
                
            }
    


        case types.SEND_USER_ACCOUNT_DELETE_SUCCESS:

        
            return  {

                ...state,
                isFetching: false,
                isProgressBar: false,
                isActionSuccessful: true,
                accountDeleted: {...action.response.deleteStatus},
                launcher: action.launcher
            }

        case types.SEND_USER_ACCOUNT_DELETE_FAILURE:

            return  {

                ...state,
                isFetching: false,
                isProgressBar: false,
                message: action.error
                
            } 


        case types.SUBSCRIBE_AND_ADD_USER_ALERT_REQUEST:

            return  {

                ...state,
                isFetching: true,
               
                
            }
    


        case types.SUBSCRIBE_AND_ADD_USER_ALERT_SUCCESS:

        
            return  {

                ...state,
                isFetching: false,
                isActionSuccessful: true,
                profile: {...state.profile,
                    alertsCount: action.response.alertsCount,
                    alerts: [...state.profile.alerts,action.response.alert],
                    alertSubscriberID: action.response.alertSubscriberID

                }
               
            }

        case types.SUBSCRIBE_AND_ADD_USER_ALERT_FAILURE:

            return  {

                ...state,
                isFetching: false,
                message: action.error
                
            } 
            

        case types.SAVE_JO_USER_JOB_ALERT_REQUEST:

            return  {

                ...state,
                isFetching: true,
                
                
            }
    


        case types.SAVE_JO_USER_JOB_ALERT_SUCCESS:

        
            return  {

                ...state,
                isFetching: false,
                isActionSuccessful: true,
                profile: {...state.profile,
                    alertsCount: action.response.alertsCount,
                    alerts: [...state.profile.alerts,action.response.alert]
                   
                }
                
            }

        case types.SAVE_JO_USER_JOB_ALERT_FAILURE:

            return  {

                ...state,
                isFetching: false,
                message: action.error
                
            } 


        case types.DELETE_SAVED_JOB_REQUEST:

            return  {

                ...state,
                isFetching: true
                
            }

        case types.DELETE_SAVED_JOB_SUCCESS:

            return manipulateDeleteBookmark(state,action)

            
          

        case types.DELETE_SAVED_JOB_FAILURE:

            return  {

                ...state,
                isFetching: false,
                message: action.error
                
            }


        case types.DELETE_USER_SEARCH_HISTORY_REQUEST:

            return  {

                ...state,
                isFetching: true
                
            }

        case types.DELETE_USER_SEARCH_HISTORY_SUCCESS:

            return manipulateDeleteHistory(state,action)

            
            

        case types.DELETE_USER_SEARCH_HISTORY_FAILURE:

            return  {

                ...state,
                isFetching: false,
                message: action.error
                
            }

        case types.SEND_USER_JOB_BOOKMARK_REQUEST:

            return  {

                ...state,
                //isSkipRender: true,
                isFetching: true
                
            }

        case types.SEND_USER_JOB_BOOKMARK_SUCCESS:


           return manipulateBookmarkItem(state,action)

        case types.SEND_USER_JOB_BOOKMARK_FAILURE:

            return  {

                ...state,
                isFetching: false,
                //isJobSaved: false,
                //isSkipRender: true,
                message: action.error
            }



        case types.SEND_USER_SEARCH_HISTORY_REQUEST:


           //return manipulateSearchHistoryItem(state,action)

            return  {

                ...state,
                isFetching: true,
                // isSkipRender: true,
                // search: {...state.search,canSetHistory:false}
                
            }

        case types.SEND_USER_SEARCH_HISTORY_SUCCESS:

            return manipulateSearchHistoryItem(state,action)


            // return  {

            //     ...state,
            //     isFetching: false,
            //     isSkipRender: true,
            //     isHistorySaved: action.response.actionStatus,
            //     search: {...state.search,canSetHistory:false}
            // }
                

        case types.SEND_USER_SEARCH_HISTORY_FAILURE:

            return  {

                ...state,
                isFetching: false,
                // isHistorySaved: false,
                //isSkipRender: true,
                // search: {...state.search,canSetHistory:false},
                //message: action.error
            }


    
        case types.SEND_USER_ALERT_SUBSCRIPTION_REQUEST:

            return  {

                ...state,
                isFetching: true,
            
                
                
                
            }

        case types.SEND_USER_ALERT_SUBSCRIPTION_SUCCESS:

            
            return  {

                ...state,
                isFetching: false,
                isActionSuccessful: true,
                text: action.response.text || ''
                
                
                
                
            }

        case types.SEND_USER_ALERT_SUBSCRIPTION_FAILURE:

            return  {

                ...state,
                isFetching: false,
                isActionSuccessful: false,
                message: action.error
                
            }    
    
                
        
        case types.UNSET_NOTIFICATION_DISPLAY: 

            return {

                ...state,
                isActionSuccessful: false,
                isInitSuccess: false,
                isResumeUpdate: false, 
                isResumeDeleted : false, 
                isUploadResume: false,
                isUploadResumeReplaced : false,
                isFetching: false,
                isInitFetching: false,
                isResumeReplaced : false,
                isAlertDelete: true
            
            }
        case types.UNSET_MESSAGE_NOTIFICATION_DISPLAY: 

            return {

                ...state,
                message: '',
                isInitMessage: false,
                isResumeFetchError: false,
                isAlertDelete: false
            }

        case types.UNSET_IS_PROFILE:

            return  {

                ...state,
                isFetching: false,
                isProgressBar: false,
                isProfile: false,
               
                
            } 
        case types.UNSET_USER_PROFILE:

            return  {

                ...state,
                isFetching: false,
                isProgressBar: false,
                isProfile: false,
                isActionSuccessful: false,
                profile: {}
                
                
            } 
    
    
        default:
        return state
    }


}



