
import React, { Component } from 'react' 
import FormControl from './formcontrol.js'
import Suggestions from './suggestions'
import { toast } from 'react-toastify';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
 

import SearchIcon from '@material-ui/icons/Search';
// import { fetchJobs } from '../../../actions.js';
// import { isFetching } from '../../../props.js';



const styles = {
    root: {
     
      color:"white",
      fontSize: 30,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };



 class Form extends Component{
    
    
    constructor(props){
    	
    	 super() 

         const {search} = props
         const {jq='', jl=''} = search

          console.log('THE VALUE OF SEARCH IN SEARCH FORM')
          console.log(search)
    	  this.state = {
    	  	
    	  	  form : {
                    
                    
                    jobKeyword:{
                        
                        control: 'input',
                        value: jq,
                        validation: {
                            isRequiredFormat:{
                              
                                specialCharacters: true,
                                
                            }
                        
                             
                        },
                        isValid: false,
                        isTouched: false,
                        isSuggest: false,
                        validationMessage: '',
                        validationStyles: {
                            position: 'bottom'
                        },
                        config: {
                            
                            name: "keyword",
                            type: 'text',
                            // placeholder: 'job title, keyword'
                            
                        }
                       
                        
                    },
                    jobLocation:{
                        
                        control: 'input',
                        value:jl,
                        validation: {
                            isRequiredFormat:{
                               
                                specialCharacters: true,
                            },
                            
                             
                        },
                        isValid: false,
                        isTouched: false,
                        isSuggest: false,
                        validationMessage: '',
                        validationStyles: {
                            position: 'bottom'
                        },
                        config: {
                            
                            name: "location",
                            type: 'text',
                            // placeholder: 'city,region,country'
                            
                        }
                        
                    }
                
            },
            showSuggest: false,
            showSuggestId: '',
            searchedLocations: [],
            updateLocal: true
            // isNativeSet: false
        
    	  	
    	  }
    	
    } 
    
    
    
    updator = (upControl)=>{
        
        console.log('THE UPDATE CONTROL')
        console.log(upControl) 
        console.log(upControl.e.target.value)
        const {props,state} = this 
        const {actions} = props
        const {getTextSuggestions} = actions
        let newForm ={...state.form} 
        let updateControl = {...newForm[upControl.id]}
        updateControl.value = upControl.e.target.value 
        let getSuggestions = false


        console.log('THE UPDATECONTROLFROM FORM')
        console.log(JSON.stringify(updateControl))
        // if(updateControl.isActive === false) updateControl.isActive = true

    
        if(!(updateControl.isSuggest)){

            console.log('UPDATECONTROL IS NOT SUGGEST')
            if(!(updateControl.validation.isPlaceholder && updateControl.value.trim() === '')){

            
                if(upControl.blur){
                
                    let validData = this.controlValidate(updateControl) 
                    
                    updateControl.isValid = validData[0] 
                    updateControl.validationMessage = validData[1] 
                    if(updateControl.value.length >= 2) getSuggestions = true
                    

                }

            }
        }
    
        if(updateControl.isSuggest && updateControl.value.length >= 2) getSuggestions = true
        //updateControl.touched = upControl.blur 
        updateControl.isTouched = true
        newForm[upControl.id] = updateControl 
        console.log('STRINGIFY UPDATED CONTROL')
        console.log(JSON.stringify(updateControl))
        console.log(JSON.stringify(newForm[upControl.id]))
        
        if(!getSuggestions){
            
            console.log('THE FORM IS UPDATING WITHOUTH SUGGESTIONS')
            console.log(JSON.stringify(newForm))
        	this.setState({

                form: newForm,

            },()=>{

                console.log('THE UPDATED FORM')
                console.log(JSON.stringify(this.state.form))
            })
        	
        	
        }else{
        	
        	
                console.log('TEH FORM IS UPDATING FOR SUGGESTIONS')
                this.setState({
                    form: newForm,
                   
                
                },()=>{
                    
                    
                    
                    if(upControl.id === 'jobLocation'){

                        this.searchLocations(updateControl.value)
  
                    }else{

                        //getTextSuggestions(updateControl.value)

                    }
                    
                })

            
        	
                
        }
        


    } 

    controlValidate = (validateControl)=>{
        
        let validData = [true,''] 
        
        if(validateControl.validation.isRequiredFormat){ 
        
            let valid = validateControl.value.trim() !== ''
            let message = ''
            if(!valid){
                message = `${validateControl.config.name} is a required field `
            }
    
            validData = !valid ? [valid,message] : validData 
            return validData
            
        }
        if(validateControl.validation.isRequiredFormat){
            
            let value =  validateControl.value.trim() 
            let specialCharactersRegex = '[`!@#$%^&*()_+\=\[\]]{};:"\\|<>\/?~]'
            let charsArray = Array.from(specialCharactersRegex)
                charsArray.push("'")
            let containsSpecialCars = charsArray.some((v)=>value.indexOf(v) >= 0)
            let pieces = value.split(/\W+/) 
            let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            console.log('SPECIAL CHARACTER TEST')
            console.log(charsArray)
            console.log(containsSpecialCars)
            console.log(value)
            console.log(specialCharactersRegex)
            let isRequiredFormat =  validateControl.validation.isRequiredFormat 
            if(isRequiredFormat.specialCharacters && containsSpecialCars === true ) return [false,`special characters are not allowed as ${validateControl.config.name}`]
            if(value.length < isRequiredFormat.minLetters ) return [false,`${validateControl.config.name} should be atleast ${isRequiredFormat.minLetters} letters long`]
            if(pieces.length < isRequiredFormat.minPieces ) return [false,`${validateControl.config.name} should be atleast ${isRequiredFormat.minPieces} words`] 
            if(pieces.length > isRequiredFormat.maxPieces ) return [false,`${validateControl.config.name} should be atmost ${isRequiredFormat.maxPieces} words`]
            if(isRequiredFormat.isEmail && emailRegex.test(value) !== true) return [false,`${validateControl.config.name} should be a valid email`]
        
                
        
        }

        
        return validData
        
    }

    notify = (config) => {

        
        console.log('THE TOAST CONFIG')
        console.log(config)
        const {message} = config || ''
        const type = config.type || 'info'
        toast[type](message,
            {position: toast.POSITION.BOTTOM_LEFT,...config});
    
    }


    submitForm = (e)=>{
                    
       e.preventDefault() 

        
        
      
        let submittData = {} 
        let formIsValid = true 
        let {form} = this.state 
        const {props,notify} = this 
        const {actions} = props 
        const {saveJobSearchParams} = actions

    
        
        
        
        for(let k in form){
            
           
                console.log('THE CURRENT FORM VALUE')
                console.log(k)
                console.log(form[k])

                if(form[k].validation && form[k].validation.isRequiredFormat){

                    if(form[k].isValid === false){

                
                        formIsValid = false 

                        notify({message:'Search values must be valid',type: 'warn',className: 'notify-warn'})
                        break


                    }else{

                        submittData[k] = form[k].value
                    }

                }else if(form[k].value.trim() !== ''){

                    if(form[k].isValid === false){
                        formIsValid = false 
                        notify({message:'Search values must valid',type: 'warning',className: 'notify-warn'})
                        break
                    }else{

                        submittData[k] = form[k].value

                    }
                
                }
            
        
        
        } 

        if(!formIsValid) return 
        console.log('THE SUBMIT CONTROL')
        console.log(submittData) 
        console.log(form)
        this.closeSearch()

       


        saveJobSearchParams({
            jq: submittData.jobKeyword,
            jl: submittData.jobLocation,
            canSetHistory: true,
            fetchJobs: true
        })


        
        

    }

    closeSearch = (e=null) =>{

        if(e) e.preventDefault()
        
        const {props} = this 
        const {openForm} = props 
        openForm(false)
    }

    showSuggestScreen(e,key){

        e.preventDefault() 

        
    
        // console.log('THE FORM SUGGESTIONS')
        // console.log(key)
        // console.log(e)
        const {state} = this 
        let newForm ={...state.form} 

        if(newForm[key].value.length >= 2) return
        newForm[key].isSuggest = true
        this.setState({...this.state,form: newForm,showSuggest: true,showSuggestId: key})

    }

    hideSuggestScreen(key,value=''){



       
        const {state} = this 
        let newForm ={...state.form} 
        newForm[key].isSuggest = false 

        if(value.trim() !== ''){
            newForm[key].value = value 
            newForm[key].isValid = true
        }
      

        

        this.setState({
            ...state,
            form: newForm,
            showSuggest: false,
            showSuggestId: '',
          
            
            }) 
        


    }
    searchLocations(searchTerm){

        console.log('THE SEARCH LOCATIONS FUNCTION GETS A CALL WITH VALUE')
        console.log(searchTerm)
        const {props,state} = this 
        const {searchedLocations} = state 
        const {locations} = props 
        searchTerm = searchTerm.toLowerCase()

        let filteredList = locations.filter((l,i)=> l.state_name.toLowerCase().includes(searchTerm)
        )
        console.log('THE VALUE OF FILTERS')
        console.log(filteredList)
        this.setState({...state,searchedLocations: filteredList})

    }

    static getDerivedStateFromProps(nextProps, prevState) {

         const {search} = nextProps 
         const {jq = '',jl = ''} = search 
         let modJq = jq.toLowerCase().trim() 
         let modJl = jl.toLowerCase().trim()
         let newForm = {...prevState.form}
         const {jobKeyword,jobLocation} = newForm 
         let isTouchedKey = jobKeyword.isTouched 
         let isTouchedLoc = jobLocation.isTouched

         let jobKeyValue = jobKeyword.value.trim() 
         let jobLocValue = jobLocation.value.trim() 
         let keywordKey = 'jobKeyword' 
         let locationKey = 'jobLocation' 

          console.log('THE NEXPROPS')
          console.log(nextProps) 
          console.log('THE PREVSTATE')
          console.log(prevState)
         

        // if( isTouchedKey && isTouchedLoc) return null 
        // if(isTouchedKey) return null 
        // if(isTouchedLoc) return null 
        if((modJq !== jobKeyValue &&  jobKeyValue === '') && modJl !== jobLocValue && jobLocValue === '' ){

            console.log('THE DERIVED STATE FUNCTION RUNS BOTH')
            console.log(isTouchedKey)
            console.log(isTouchedLoc)
            
            if(isTouchedKey || isTouchedLoc) return null 

                newForm[keywordKey].value = jq 
                newForm[keywordKey].isValid = true 
                newForm[keywordKey].isTouched = true 
                newForm[locationKey].value = jl 
                newForm[locationKey].isValid = true 
                newForm[locationKey].isTouched = true 

            
            return {

                form: newForm
            }

        }else if(modJq !== jobKeyValue &&  jobKeyValue === ''){

            console.log('THE DERIVED STATE FUNCTION RUNSJQ') 
            console.log(isTouchedKey)
            if(isTouchedKey) return null
            newForm[keywordKey].value = jq 
            newForm[keywordKey].isValid = true 
            newForm[keywordKey].isTouched = true 

            //newForm[locationKey].value = jl
            
            return {

                form: newForm
            }

        }else if(modJl !== jobLocValue && jobLocValue === ''){
            
            console.log('THE DERIVED STATE FUNCTION RUNSJL') 
            console.log(isTouchedLoc)
            if(isTouchedLoc) return null
            newForm[locationKey].value = jl 
            newForm[locationKey].isValid = true 
            newForm[locationKey].isTouched = true 
            
            return {

                form: newForm
            }
        }

        

        return null;
    }

    
    // componentWillReceiveProps(nextProps){
    //     if (nextProps.inputValue !== this.props.inputValue) {
    //       this.setState({ inputVal: nextProps.inputValue })
    //     }
    // }

    componentDidUpdate(){

        const {props,state} = this 
        const {search,listing} = props 
        const {fetchJobs=false,jq='',jl=''} = search
        const {updateLocal=false} = state 
        const {isFetching=false} = listing
       
        if(fetchJobs && updateLocal){

            let newForm ={...state.form} 
            // let updateControl = {...newForm[upControl.id]}

            if(jq.trim() & jl.trim()){

                newForm['jobKeyword'].value = jq 
                newForm['jobKeyword'].isValid = true
                newForm['jobLocation'].value = true
                newForm['jobLocation'].isValid = true

                this.setState({...this.state,form: newForm,updateLocal: false})

            }else if(jq.trim()){

                newForm['jobKeyword'].value = jq 
                newForm['jobKeyword'].isValid = true
              
                
                this.setState({...this.state,form: newForm,updateLocal:false})


            }else if(jl.trim()){

               
                newForm['jobLocation'].value = jl
                newForm['jobLocation'].isValid = true
                
                this.setState({...this.state,form: newForm,updateLocal: false})


            }
        }else if(!updateLocal){

            if(!isFetching){
                this.setState({...this.state,updateLocal: true})
            }
        }
    }
   
   
  
    render(){ 
    
      const {props,state,hideSuggestScreen,showSuggestScreen,searchLocations,closeSearch } = this 
      const {suggestions,locations,search,isScrolled,width} = props 
      const {showSuggest,showSuggestId,searchedLocations} = state 
      let  newLocations = [...locations] 
      let refinedLocations = searchedLocations.length >= 1 ? searchedLocations : newLocations.splice(0,4)
      let searchForm =  !isScrolled && width > 1200? 'job__search--form': 'job__search--form job__search-sticky--form' 
      let searchFormI =  !isScrolled && width > 1200 ? 'job__search--form-i': 'job__search--form-i job__search-sticky--form-i' 
      let searchFormPd = !isScrolled && width > 1200?  {paddingBottom: '20px'} : {paddingBottom: 0}

    //   console.log('THE RENDER OF FORM SEARCH VALUES')
    //   console.log(search)

    //   let searchKeywords = '' 
    //   let searchLoca = ''
    //    search.jq ? searchKeywords = search.jq : searchKeywords = 'job title, keyword' 
    //    search.jl ? searchLoca = search.jl : searchLoca = "city, region, country" 
    //    let derivedKeywords = searchKeywords === 'job title, keyword' ? null : searchKeywords
    //    let derivedLoca = searchLoca === "city, region, country" ? null : searchLoca
    //    console.log('THE SEARCH KEYWORDS')
    //    console.log(searchKeywords)
    //    console.log(searchLoca)
    //    console.log(search)
      
    
    return(

      
            <section className={searchForm}>

                <form className="form" style={searchFormPd} autocomplete="off">

                <section className={searchFormI}>

                       <FormControl 

                                    styles={{child:'bx-raised job__search--form-text',error: ''}}
                                    id = 'jobKeyword'
                                    controlData={this.state.form.jobKeyword}
                                    change={(control)=>this.updator(control)} 
                                    click={showSuggestScreen.bind(this)} 
                                    // placeHolder= {searchKeywords} 
                                    // derivedValue = {derivedKeywords}
                                   
                                    
                                    

                          />
                        {showSuggest && suggestions && showSuggestId  === 'jobKeyword' ? <Suggestions list={suggestions} id="jobKeyword" hideScreen={hideSuggestScreen.bind(this)} /> : null}
                    
                      


                    
                </section>

                <section className={searchFormI}> 
                
                
                      <FormControl 

                                    styles={{child:'bx-raised job__search--form-text',error: ''}}
                                    id = 'jobLocation'
                                    controlData={this.state.form.jobLocation}
                                    change={(control)=>this.updator(control)} 
                                    click={showSuggestScreen.bind(this)} 
                                    // placeHolder= {searchLoca} 
                                    // derivedValue = {derivedLoca}
                                    
                                    

                          />
                     {showSuggest && refinedLocations && showSuggestId === 'jobLocation' ? <Suggestions list={refinedLocations} id="jobLocation" hideScreen={hideSuggestScreen.bind(this)} /> : null}

                   

                
                </section>

                {/* <p className="job__search--form-i">

                    <input type="text" className="bx-raised job__search--form-text" placeholder="category"/>

                
                </p> */}
                <strong className="clearfix" />
               


                {!isScrolled && width > 1200  ?
                    <p className="job__search--form-submit">

                     {/* <input onClick={(e)=>this.submitForm(e)} type="submit" className="cursor-pointer font-fd-xx-tn fg-light mg-top-fl-x-sm  d-inline-block pd-fd-bt bd-none" value="Search"/>  */}

                     <button className="job__search--form-submit-btn" title={"search"} onClick={(e)=>this.submitForm(e)}>
                     <span className="job__search--form-submit-btn-bar job__search--form-submit-btn-bar-1" ></span>
                        <span className="job__search--form-submit-btn-bar job__search--form-submit-btn-bar-2" >
                            {/* <small>x</small> */}
                            <span className="job__search--mobile-input-icon">
                                <Icon><SearchIcon style={styles.root} /></Icon>
                            </span>
                        </span>
                     </button>

            

                </p>
                : <section className="job__search-sticky-submit"> 
                    <input onClick={(e)=>this.submitForm(e)} 
                    type="submit" 
                    className="cursor-pointer font-fd-xx-tn fg-light   d-inline-block bd-none" 
                    value="Search"/> </section>
                }

                <section className="job__search-sticky-cancel"> 
                    <input onClick={(e)=>this.closeSearch(e)} 
                    type="cancel" 
                    className="cursor-pointer font-fd-xx-tn fg-light   d-inline-block bd-none" 
                    value="cancel"/> 
                </section>


            </form>
        
    </section>
        
    )
    
    }

}


export default withStyles(styles)(Form)