
import React, { Component } from 'react' 
import ExperienceInfo from './experienceinfo'







class Experience extends Component{
    
    constructor(){

        super()

        this.state ={

            rectHeight: 0,
            rectOffsetTop: 0,}
        

        
    }

    getRectHeight = ()=>{

        let el = document.getElementById('experience-rect')

        let heightRect = el.clientHeight 
        let rectOffsetTop = el.offsetTop
        if(heightRect !== this.state.rectHeight){

            this.setState({...this.state,rectHeight: heightRect,rectOffsetTop: rectOffsetTop})
        }
        
    }

    openLogin = ()=>{

        const {props} = this 
        const {actions} = props 
        const {push} = actions 
        push('/access')
    }

    
   componentDidMount(){

      this.getRectHeight()
   }








scrollToBottom = (id)=>{

   

        let scrollEl = document.getElementById(id)

        if(scrollEl) scrollEl.scrollIntoView({behavior: 'smooth'})

    
   
    
}


componentDidUpdate(prevState){

    if(this.state.isExperienceAdd){

        
        this.scrollToBottom('experience-add')
        
    }

   this.getRectHeight()
    

    console.log('THE PREVSTATE')
    console.log(prevState)

    

    
    
    // else{

    //     console.log('THE PREVIOUS STATE')
    //     console.log(prevState)
    //     if(prevState.isExperienceAdd === true){
    //         this.scrollToBottom('experience-top')
    //     }
       
    // }
}



    
    render(){


       const {state,props} = this 
       const {rectHeight,rectOffsetTop} = state 
       const {experience=[]} = props
    //    const depRectStyles = !isEdit || !isExperienceAdd ? {height: rectHeight, marginTop: rectHeight * .18 - rectHeight} : {height: rectHeight, marginTop: (2 * 100) - rectHeight}
       const parentHeight = {height: rectHeight + 450}
       const depRectStyles = {height: rectHeight, top: 420, position: 'absolute'} 
       console.log('THE RECT HEIGHT')
       console.log(rectHeight)
       console.log(rectOffsetTop)

        return(

           
    
            <div className="resume__experience" style={parentHeight} id="experience-top">

              
                 
                 <h2 className="resume__experience--title">
                     <span className="resume__experience--title-circle"></span>
                     <span className="resume__experience--title-text">Experience</span>
                 </h2>

                 <div className="resume__experience-rect" id="experience-rect">

                   

                  

                   { experience.length > 0 ? <ExperienceInfo experience={experience} /> :  null  }

        

                    


                </div>

                <div className="resume__experience-rect-2" style={depRectStyles}>

                    
                 </div>
                



            </div>

          
        )
    }


}


export default Experience


