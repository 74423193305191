
import React, {Component} from 'react' 
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import * as actions from './actions'
import * as navigatorActions from '../Navigator/actions'
import * as authActions from '../Auth/actions'
import { withRouter } from 'react-router-dom';



import Tools from './components/tools/tools'
import SettingOffset from './components/settingoffset/settingoffset'
import Navigation from './components/navigation/navigation'






class CandidateBar extends Component{


    constructor(props){

        super()
        this.state = {

           
            width: 0,
            height: 0,
            
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }


       
  

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }

    componentDidMount(){

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
       
    }

    componentWillUnmount(){

        window.removeEventListener('resize', this.updateWindowDimensions);
      
     }

    logoutUser(){

        this.props.actions.logoutUser()
    }


    render(){

        const {state} = this 
        const {width} = state

        return(

                 
                    width > 900 
                    ? <div className="dashboard__sidebar" >

                    

                        <div>
                            
                            <section className="dashboard__sidebar--tools">
                            <Tools />
                            </section>

                            <section className="dashboard__sidebar--nav">

                                <Navigation actions={this.props.actions}  />
                            </section>

                            <section className="dashboard__sidebar--settingoffset">
                                <SettingOffset logoutUser={this.logoutUser.bind(this)} />
                            </section>
                            
                            

                        </div>
                        
                

                    </div>

                :null
            
        )
    }




}

const mapStateToProps = (state)=>{

    return{
        state:{
            ...state.router
        }
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...actions,...navigatorActions,...authActions},dispatch)
    }
}

export default withRouter(connect(mapStateToProps,mapDispachToProps)(CandidateBar))