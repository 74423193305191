import { getIO } from "./view"
import {updateAndSaveChat,manipulateProfilePersonalUpdate,manipulateProfileFeature,
        profileFeatureEditItems,profileFeatureEditItemKeys,loadContextItems,saveEditItem,
        completeFeatureEdit,profileFeatureAddItems,profileFeatureDeleteItems,profileFeatureDeleteRecord
} from './util'



export const start = (self)=>{

   

    return (

        <>
        
          <button style={{color: 'red'}} onClick={(e)=>updateAndSaveChat(self,e,getIO,'register')}>Register</button>
          <button  onClick={(e)=>updateAndSaveChat(self,e,getIO,'cv','Create CV')}>Create CV</button>
          <button  onClick={(e)=>updateAndSaveChat(self,e,getIO,'profile','Update Profile')}>Update Profile</button>
          <button  onClick={(e)=>updateAndSaveChat(self,e,getIO,'Support','Get Support')}>Get Support</button>
        
        </>
    )


}


export const personalask = (self)=>{

   

  return (

      <>
      
        <button style={{color: 'red'}} onClick={(e)=>manipulateProfilePersonalUpdate(self,e,'name')}>Name</button>
        <button  onClick={(e)=>manipulateProfilePersonalUpdate(self,e,'surname')}>Surname</button>
        <button  onClick={(e)=>manipulateProfilePersonalUpdate(self,e,'bio')}>Bio</button>
        <button  onClick={(e)=>manipulateProfilePersonalUpdate(self,e,'profession')}>Profession</button>
        <button  onClick={(e)=>manipulateProfilePersonalUpdate(self,e,'avatar')}>Profile Picture</button>
        <button  onClick={(e)=>manipulateProfilePersonalUpdate(self,e,'mobile')}>Contact Number</button>
        <button  onClick={(e)=>manipulateProfilePersonalUpdate(self,e,'address')}>Address</button>

        <p><button  onClick={(e)=>updateAndSaveChat(self,e,getIO,'address')}>I'm Done</button></p>
      
      </>
  )


}


export const profileask = (self)=>{

   

  return (

      <>
      
        <button style={{color: 'red'}}  onClick={(e)=>profileFeatureEditItems(self,e,updateAndSaveChat,getIO,'askedit','Edit Information')}>Edit Information</button>
        <button  onClick={(e)=>profileFeatureAddItems(self,e,updateAndSaveChat,getIO,'profileitems','Add Information')}>Add Information</button>
        <button  onClick={(e)=>profileFeatureDeleteItems(self,e,updateAndSaveChat,getIO,'askdelete','Delete Information')}>Delete Information</button>
      
       

      
      </>
  )


}

export const profile = (self)=>{

   

  return (

      <>
      
        <button style={{color: 'red'}} onClick={(e)=>updateAndSaveChat(self,e,getIO,'personalask','Personal Information')}>Personal Information</button>
        <button  onClick={(e)=>manipulateProfileFeature(self,e,updateAndSaveChat,getIO,'profileask','Academic Information','education')}>Academic Information</button>
        <button  onClick={(e)=>manipulateProfileFeature(self,e,updateAndSaveChat,getIO,'profileask','Work Experience','experience')}>Work Experience</button>
        <button  onClick={(e)=>manipulateProfileFeature(self,e,updateAndSaveChat,getIO,'profileask','Spoken Languages','language')}>Spoken Languages</button>
      
      </>
  )


} 

export const editItems = (self)=>{



  const {props} = self 
  const {actions,userProfile,humanoid} = props 
  const {currentProfileFeature,profileQuestions} = humanoid
  const {profile} = userProfile 
  const featureVariants = profileQuestions[currentProfileFeature] 
  const {identifierText} = featureVariants

  const profileFeature = profile[currentProfileFeature] 

  return( 
      <>
        {profileFeature.map((record,i)=>{

          return <button style={{color: 'red'}} key={i} onClick={(e)=>profileFeatureEditItemKeys(self,e,updateAndSaveChat,getIO,'askeditkeys',record[identifierText],record['id'])}>{record[identifierText]}</button>
      })
      }

      </>
  )
   

  // return (

  //     <>
      
  //       <button style={{color: 'red'}} onClick={(e)=>updateAndSaveChat(self,e,getIO,'personalask','Personal Information')}>Personal Information</button>
  //       <button  onClick={(e)=>manipulateProfileFeature(self,e,updateAndSaveChat,getIO,'profileask','Academic Information','education')}>Academic Information</button>
  //       <button  onClick={(e)=>manipulateProfileFeature(self,e,updateAndSaveChat,getIO,'profileask','Work Experience','experience')}>Work Experience</button>
  //       <button  onClick={(e)=>manipulateProfileFeature(self,e,updateAndSaveChat,getIO,'profileask','Spoken Languages','language')}>Spoken Languages</button>
      
  //     </>
  // )


} 

export const deleteItems = (self)=>{



  const {props} = self 
  const {actions,userProfile,humanoid} = props 
  const {currentProfileFeature,profileQuestions} = humanoid
  const {profile} = userProfile 
  const featureVariants = profileQuestions[currentProfileFeature] 
  const {identifierText} = featureVariants

  const profileFeature = profile[currentProfileFeature] 

  return( 
      <>
        {profileFeature.map((record,i)=>{

          return <button style={{color: 'red'}} key={i} onClick={(e)=>profileFeatureDeleteRecord(self,e,updateAndSaveChat,getIO,record[identifierText],'',record['id'])}>{record[identifierText]}</button>
      })
      }

      </>
  )
   

  // return (

  //     <>
      
  //       <button style={{color: 'red'}} onClick={(e)=>updateAndSaveChat(self,e,getIO,'personalask','Personal Information')}>Personal Information</button>
  //       <button  onClick={(e)=>manipulateProfileFeature(self,e,updateAndSaveChat,getIO,'profileask','Academic Information','education')}>Academic Information</button>
  //       <button  onClick={(e)=>manipulateProfileFeature(self,e,updateAndSaveChat,getIO,'profileask','Work Experience','experience')}>Work Experience</button>
  //       <button  onClick={(e)=>manipulateProfileFeature(self,e,updateAndSaveChat,getIO,'profileask','Spoken Languages','language')}>Spoken Languages</button>
      
  //     </>
  // )


}

export const editItemKeys = (self)=>{



  const {props} = self 
  const {actions,userProfile,humanoid} = props 
  const {currentProfileFeatureRecord,profileQuestions} = humanoid
  // const {profile} = userProfile 
  // const featureVariants = profileQuestions[currentProfileFeature] 
  // const {identifierText} = featureVariants

  // const profileFeature = profile[currentProfileFeature] 

  const keys = Object.keys(currentProfileFeatureRecord)

  return( 
      <>
        {keys.map((ikey,i)=>{

          if(ikey === 'id' || ikey === 'u_id') return null
          return <button style={{color: 'red'}} key={i} onClick={(e)=>saveEditItem(self,e,ikey)}>{ikey}</button>
      })
      }

    <button style={{color: 'red'}}  onClick={(e)=>loadContextItems(self,e,updateAndSaveChat,getIO,'profileitems','all')}>All</button>

   <p style={{marginTop: "50px"}}>
     <button style={{color: 'red'}}  onClick={(e)=>loadContextItems(self,e,updateAndSaveChat,getIO,'profileitems')}>I'm Done Choosing</button>
     </p>

      </>
  )
   

  // return (

  //     <>
      
  //       <button style={{color: 'red'}} onClick={(e)=>updateAndSaveChat(self,e,getIO,'personalask','Personal Information')}>Personal Information</button>
  //       <button  onClick={(e)=>manipulateProfileFeature(self,e,updateAndSaveChat,getIO,'profileask','Academic Information','education')}>Academic Information</button>
  //       <button  onClick={(e)=>manipulateProfileFeature(self,e,updateAndSaveChat,getIO,'profileask','Work Experience','experience')}>Work Experience</button>
  //       <button  onClick={(e)=>manipulateProfileFeature(self,e,updateAndSaveChat,getIO,'profileask','Spoken Languages','language')}>Spoken Languages</button>
      
  //     </>
  // )


}


export const editcomplete = (self)=>{

   

  return (

      <>
      
        <button style={{color: 'seagreen'}} onClick={(e)=>completeFeatureEdit(self,e,updateAndSaveChat,getIO,'Yes','yes')}>Yes, let's chat further</button>
        <button  onClick={(e)=>completeFeatureEdit(self,e,updateAndSaveChat,getIO,'No','no')}>No, I'm done.</button>
        
      
      </>
  )


}


export const editfailure = (self)=>{

   

  return (

      <>
      
        <button style={{color: 'seagreen'}} onClick={(e)=>completeFeatureEdit(self,e,updateAndSaveChat,getIO,'Yes','yes')}>Yes, let's try again</button>
        <button  onClick={(e)=>completeFeatureEdit(self,e,updateAndSaveChat,getIO,'No','no')}>No, let's start over.</button>
        
      
      </>
  )


}

export const limitreached = (self)=>{

   

  return (

      <>
      
        <button style={{color: 'seagreen'}} onClick={(e)=>completeFeatureEdit(self,e,updateAndSaveChat,getIO,'Yes','yes')}>Okay, let me delete</button>
        <button  onClick={(e)=>completeFeatureEdit(self,e,updateAndSaveChat,getIO,'No','no')}>Okay, never mind.</button>
        
      
      </>
  )


}



export const incomplete = (self)=>{

   

  return (

      <>
      
        <button style={{color: 'seagreen'}} onClick={(e)=>updateAndSaveChat(self,e,getIO,'yes')}>Yes, let's finish that up</button>
        <button  onClick={(e)=>updateAndSaveChat(self,e,getIO,'no')}>No, lets start afresh.</button>
        
      
      </>
  )


}


export const complete = (self)=>{

   

  return (

      <>
      
        <button style={{color: 'seagreen'}} onClick={(e)=>updateAndSaveChat(self,e,getIO,'yes')}>Yes, let's chat further</button>
        <button  onClick={(e)=>updateAndSaveChat(self,e,getIO,'no')}>No, I'm done.</button>
        
      
      </>
  )


}


//updateAndSaveChat(self,e,getIO,'profileask','Academic Information','education')