
import React, {Component} from 'react' 
import FormControl from '../formcontrol'
// import ProgressBarLinear from '../progressbarlinear'
import { toast } from 'react-toastify';
import CuratedLanguageInfo from './culanguageinfo'
import CuratedLanguageForm from './culanguageform'
// import ReCAPTCHA from "react-google-recaptcha"


class Bio extends  Component{


    constructor(props){

        super()

    }




    getPortfolio = (e,i,isEditing,setIsEditing,allowEdit,isPortAdd)=>{

        const {props,removeItem} = this 
        const {actions,userProfile,width} = props 

        if(i+1 === isEditing){

            return <CuratedLanguageForm width={width} language={e} key={i} actions={actions} userProfile={userProfile}  isEditing={isEditing} allowEdit={allowEdit} setIsEditing={setIsEditing} />
        }else{

            return <CuratedLanguageInfo width={width} language={e} key={i} isPortAdd={isPortAdd} setIsEditing={setIsEditing} removeItem={removeItem.bind(this)} isEditing={isEditing} allowEdit={allowEdit} index={i} />
        }
        
        

            
        

    
    }

    removeItem = (e,id)=>{
        
        e.preventDefault() 
        
       
    
        console.log('THE ID OF THE DELETING ITEM')
        console.log(id)
        
        const {props} = this
        const {actions,ID=52} = props 
        const {deleteResumeItem} = actions
      
     
         deleteResumeItem({language: {id:id}},ID)
        
        // if(!formIsValid) return
        // if(!isCaptchaVerified) return  notify({message:'Recaptcha must be selected before signin',type: 'warning',className: 'notify-warn'})
        
        
        
    
    }
 

               

   

    render(){

        
         const {props,state} = this 
        //  const {bioText} = state
         const { isLangAdd= false,width,isFirstAdd,language,isEditing=0,allowEdit,setIsEditing,isLanguageAdding,isAdding,actions,userProfile} = props 
        
        //  const editContStyles = isEdit ? {boxShadow: '-15px 10px 25px 5px rgba(0, 0, 0, 0.04);'} : {boxShadow: 'none'}
        // const {push} = actions
        // const {message = ''} = auth 
        // console.log('tHE MESSAGE')
        // console.log(message)
        // console.log(message.trim() !== '')

        //if(isPortfolioAdd) return <CuratedLanguageForm isAdding={true} /> 

        if(!isLangAdd && language.length <= 0) return <>
            <section className="resume__curated--card" style={{marginBottom: `100px`}} id={'language'}>

                <div className="resume--add-info">

                <div class="resume--add-info-rect resume--add-info-rect-1 bx-raised">
                </div>
                <div class="resume--add-info-rect resume--add-info-rect-2 bx-raised">
                </div>
                <div class="resume--add-info-rect resume--add-info-rect-3 bx-raised"></div>
                <div class="resume--add-info-rect resume--add-info-rect-4 bx-raised" onClick={(e)=>{isLanguageAdding(e,true)}}>

                        <p>
                            
                            <small style={{fontSize: '20px',display: 'block',textAlign: 'center'}}>
                               <i className="fa fa-lx fa-plus" style={{color: 'seagreen'}} aria-hidden="true">
                               </i>
                              
                            </small>
                            <small style={{fontSize: '15px',display: 'block',textAlign: 'center'}}>Add Languages you speak</small>
                        </p>
                </div>

                </div>


            </section>
            
        </>

        return(

                    

                        
                       
                        // <>{ language.map((e,i)=> this.getlanguages(e,i))}</>

                        <section className="resume__curated--card">

                        {!isLangAdd && language.length < 5 && isEditing === 0 
                           ?   <small className="resume--edit-pen resume__about--bio-edit-pencil" onClick={isLanguageAdding}>
                               <i className="fa fa-lx fa-plus" style={{color: 'seagreen'}} aria-hidden="true">
                               </i>
                              
                               </small>
                           : null 
                           }
                        <div className="resume__curated--card-pointer"></div>
   
                        <div className="resume__curated--card-pointee">
   
                           <h5 className="resume__curated--card-pointee-period">Languages</h5>
                           {width > 1200 
                            ?<p className="resume__curated--card-pointee-legend">

                                    <span>Language</span>
                                    <span>Speak</span>
                                    <span>Write</span>
                                    <span>Read</span>
                                    <span>Actions</span>
                                
                                </p>
                                : null
                            }
                            <ul className="resume__curated--card-pointee-list">

                            {language.map((p,i)=> this.getPortfolio(p,i,isEditing,setIsEditing,allowEdit,isLangAdd))} 
                            {isLangAdd? <CuratedLanguageForm width={width} actions={actions} userProfile={userProfile} isLangAdd={isLangAdd} isAdding={isAdding} isFirstAdd={isFirstAdd} isEditing={isEditing} setIsEditing={setIsEditing} isLanguageAdding={isLanguageAdding} allowEdit={allowEdit} /> : null}

                            </ul>
                           
                           {/* <p className="resume__curated--card-pointee-legend">
                                   <span>Name</span>
                                   <span>Description</span>
                                   <span>Link</span>
                                   <span>Actions</span>
                               
                               </p>
   
                           <ul className="resume__curated--card-pointee-list">
   
                                   <li className="resume__curated--card-pointee-list-i">
                                       <span>Indym</span>
                                       <span>An independent Modeling agency</span>
                                       <span><a href="https://indym.herokuapp.com" target="_blank">View</a></span>
                                       <span>
                                           <small><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                               
                                           <small><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                                       </span>
                                       
                                   </li>
                                   <li className="resume__curated--card-pointee-list-i">
                                       <span>PHP</span>
                                       <span>An independent Modeling agency</span>
                                       <span><a href="https://indym.herokuapp.com">View</a></span>
                                       <span>
                                           <small><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                               
                                           <small><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                                       </span>
                                       
                                   </li>
                                   <li className="resume__curated--card-pointee-list-i">
                                       <span>Javascript</span>
                                       <span>An independent Modeling agency</span>
                                       <span><a href="https://indym.herokuapp.com">View</a></span>
                                       <span>
                                           <small><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                               
                                           <small><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                                       </span>
                                       
                                   </li>
   
   
                               </ul> */}
   
                           </div>
                        </section>

           
             
             
           
        )
    }




}

export default Bio