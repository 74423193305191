
import React, { Component } from 'react' 
import ContactForm from './contactform'





 function Form (props){
    
   
  

    return(

      
           <>




                     <div className="contact__mail--jo-form-send">
                         <h2 className="contact__mail--jo-form-send-title">
                             <span className="contact__mail--jo-form-send-title-circle" />
                         
                         </h2>
                         <div className="contact__mail--jo-form-send-rect">
                
                            <ContactForm props={{...props}} />
                       
                        </div>
                        <div className="contact__mail--jo-form-send-rect-2"  /></div>
    

                {/* <form className="form pd-bottom-fd-lg ">

                <p className="contact__mail--jo-i">

                    <input type="text" className="bx-raised contact__mail--jo-text" placeholder="first name"/>

                </p>

                <p className="contact__mail--jo-i">

                    <input type="text" className="bx-raised contact__mail--jo-text" placeholder="last name"/>

                   
                </p>

                <p className="contact__mail--jo-i">

                    <input type="text" className="bx-raised contact__mail--jo-text" placeholder="alertme@mail.com"/>

                   
                </p>
                <strong className="clearfix" />
                <p className="contact__mail--jo-textbox">

                <textarea name="user_message" id="message" className="bx-raised contact__mail--jo-textbox-box" placeholder="Your Message">
                    
                </textarea>
                </p>



                <p className="contact__mail--jo-submit">

                    <input type="submit" className="cursor-pointer font-fd-xx-tn bg-primary fg-light mg-top-fl-x-sm  d-inline-block pd-fd-bt bd-none" value="Send"/>

                </p>


                </form>
               */}
           </>
      
    )

}


export default Form