
import React,{Component} from 'react'
import {BrowserRouter as Router, Link} from 'react-router-dom' 
import TwitterLogin from 'react-twitter-auth';
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from 'react-google-login'; 
import GitHubLogin from 'react-github-login';


import Facebook from './facebook'
import Google from './google'
import config from './config';
import { captchaVerifyError } from '../../Auth/actions';


class AccessHeader extends Component{


    constructor() {
        super();
        this.state = {

            authenticationConfig: {

                usertype: 'applicant',
                strategy: 'social',
                
       
            }
        }
        // this.state = { isAuthenticated: false, user: null, token: ''};
    }

    logout = () => {
        this.setState({isAuthenticated: false, token: '', user: null})
    };

    onFailure = (error) => {
        console.log('ERROR')
        console.log('THE REQUEST FAILED')
        console.log(error)
        // alert(error);
    };

    twitterResponse = (response) => {


        const {props} = this
        const {actions} = props
        const {loginError,receiveUserProfile,receiveLogin} = actions
        console.log('THE CONSOLE')
        console.log(response)
        
        return response.json().then(user => {

                console.log('THE USER')
                console.log(user)

                const {actor = null,message=null} = user 

                 if(message) return loginError(message.message)
                if(!actor) return loginError('System error has occured trying to register')
                const {auth,profile } = actor

                if(auth.accessToken){

                
                    localStorage.setItem('user', auth)
                    receiveUserProfile(profile)
                    receiveLogin(actor)
                

                }else{


                    message = 'System error has occured trying to process user' 

                    if(message instanceof String) return loginError(message)
                    return  loginError('System error has occured registering user')
                    


                }
        })
        //return console.log(response.json())
        
        // response.json().then(user => {


        //     const {actor = null } = user 
                  

        //     if(!actor) return dispatch(registerError('System error has occured trying to register'))
        //     const {auth,profile } = actor

        //     if(auth.accessToken){

             
        //       localStorage.setItem('user', auth)
        //       dispatch(userProfileActions.receiveUserProfile(profile))
        //       return dispatch(receiveRegister(actor))
            

        //     }else if(auth.taken){

        //       let {message = 'Specified email belongs to a registered user'} = user
        //       dispatch(registerError(message))
              

        //     }else{


        //       let {message = 'System error has occured trying to register user'} = user

        //       if(message instanceof String) return dispatch(registerError(message))
        //       return  dispatch(registerError('System error has occured registering user'))
            


        //     }

          


          //}
        


        // console.log('THE INITIAL RESPONSE::TWITTER')
        // console.log(response)
        // return
        // const {actions} = this.props
        // const {registerUser} = actions 
      
        // let {authenticationConfig} = this.state 
        // let {email,name,accessToken,picture} = response 
        // let profileUrl = picture.data.url
        // let submitData = {authVendor: 'facebook',email,access_token: accessToken,username: name,password: accessToken,profileUrl,...authenticationConfig}
        // console.log('THE DATA TO SUBMIT TO THE SERVER')
        // console.log(submitData) 
        // let {notify} = this
        // const tokenBlob = new Blob([JSON.stringify({access_token: response.accessToken}, null, 2)], {type : 'application/json'});
        // const options = {
        //     method: 'POST',
        //     body: tokenBlob,
        //     mode: 'cors',
        //     cache: 'default'
        // };

        //registerUser(submitData)
        // fetch('http://localhost:3000/api/v1/auth/facebook', options).then(r => {
        //     const token = r.headers.get('x-auth-token');
        //     r.json().then(user => {
        //         if (token) {
        //             this.setState({isAuthenticated: true, user, token})
        //         }
        //     });
        // })
    };

    facebookResponse = (response) => {

        console.log('THE INITIAL RESPONSE')
        console.log(response)
        const {actions} = this.props
        const {registerUser} = actions 
      
        let {authenticationConfig} = this.state 
        let {email,name,accessToken,picture} = response 
        let profileUrl = picture.data.url
        let submitData = {authVendor: 'facebook',email,access_token: accessToken,username: name,password: accessToken,profileUrl,...authenticationConfig}
        console.log('THE DATA TO SUBMIT TO THE SERVER')
        console.log(submitData) 
        // let {notify} = this
        // const tokenBlob = new Blob([JSON.stringify({access_token: response.accessToken}, null, 2)], {type : 'application/json'});
        // const options = {
        //     method: 'POST',
        //     body: tokenBlob,
        //     mode: 'cors',
        //     cache: 'default'
        // };

        registerUser(submitData)
        // fetch('http://localhost:3000/api/v1/auth/facebook', options).then(r => {
        //     const token = r.headers.get('x-auth-token');
        //     r.json().then(user => {
        //         if (token) {
        //             this.setState({isAuthenticated: true, user, token})
        //         }
        //     });
        // })
    };

    googleResponse = (response) => {


        console.log('THE INITIAL RESPONSE')
        console.log(response)
        const {actions} = this.props
        const {registerUser} = actions 
    
      
        let {authenticationConfig} = this.state 
        let {tokenObj} = response 
        let {access_token} = tokenObj 
        let submitData = {authVendor: 'google',access_token: access_token,...authenticationConfig}
        console.log('THE DATA TO SUBMIT TO THE SERVER')
        console.log(submitData) 
        // let {notify} = this
        // const tokenBlob = new Blob([JSON.stringify({access_token: response.accessToken}, null, 2)], {type : 'application/json'});
        // const options = {
        //     method: 'POST',
        //     body: tokenBlob,
        //     mode: 'cors',
        //     cache: 'default'
        // };

        registerUser(submitData)
        // const tokenBlob = new Blob([JSON.stringify({access_token: response.accessToken}, null, 2)], {type : 'application/json'});
        // const options = {
        //     method: 'POST',
        //     body: tokenBlob,
        //     mode: 'cors',
        //     cache: 'default'
        // };
        // fetch('http://localhost:4000/api/v1/auth/google', options).then(r => {
        //     const token = r.headers.get('x-auth-token');
        //     r.json().then(user => {
        //         if (token) {
        //             this.setState({isAuthenticated: true, user, token})
        //         }
        //     });
        // })
    };

    githubResponse = (response) => {


        console.log('THE INITIAL RESPONSE')
        console.log(response)
        const {actions} = this.props
        const {registerUser} = actions 

        let {authenticationConfig} = this.state 
        let {code} = response 
        let submitData = {authVendor: 'github',access_token: code,...authenticationConfig}
        console.log('THE DATA TO SUBMIT TO THE SERVER')
        console.log(submitData) 
       
        registerUser(submitData)
        
    };
    
   

    
    
    render(){

        

        return(

           

                
                <div  className="access__header--container">
                      
                    <section className="access__header bx-shadow-dark">

                        <span className="access__header--signup">Sign up</span>
                        <span className="access__header--vbar"></span>
                        <span className="access__header--signin">Sign in</span>
                       

                    </section>

                    <section className="access__header--with bx-raised-1">
                        <span>With</span>
                    </section>

                    <span className="access__header--withbar"></span>

                    <section className="access__header--social bx-raised-1">

                        {/* <Link to="https://www.facebook.com/"  className="access__header--social-1 link">
                            <img className="access__header--social-img" src="/img/social/facebook.png" />  */}
                            {/* <TwitterLogin loginUrl="http://localhost:3000/api/v1/auth/twitter"
                                onFailure={this.onFailure} 
                                onSuccess={this.twitterResponse}
                                requestTokenUrl="http://localhost:3000/api/v1/auth/twitter/reverse"
                            /> */}
                        {/* </Link > */}
                        
                            {/* <FacebookLogin 
                                appId={config.FACEBOOK_APP_ID}
                                autoLoad={false}
                                fields="name,email,picture"
                                callback={this.facebookResponse} 
                                 textButton=""
                                icon={<img className="access__header--social-img" src="/img/social/facebook.png" />}
                                 cssClass="login-fb"
                                // render={renderProps => (
                                //     <button onClick={renderProps.onClick}>
                                //         <img className="access__header--social-img" src="/img/social/facebook.png" />
                                //     </button>
                                //   )}
                                  
                            /> */}
                       
                        {/* <Link to="https://www.facebook.com/" className="access__header--social-3 link">
                          <img className="access__header--social-img" src="/img/social/instagram.png" />
                        </Link > */}
                        {/* <Link to="https://www.facebook.com/" className="access__header--social-4 link">
                          <img className="access__header--social-img" src="/img/social/googlePlus.png" /> */}

                          {/* <GoogleLogin 

                                clientId={config.GOOGLE_APP_ID}
                                autoLoad={false}
                                cssClass="login-fb"
                                icon={false}
                                buttonText=""
                                onSuccess={this.googleResponse}
                                onFailure={this.onFailure}
                                className="login-fb"
                            
                         >
                             <img className="access__header--social-img" src="/img/social/googlePlus.png" />

                         </GoogleLogin> */}

                        <TwitterLogin 

                            // clientId={config.TWITTER_APP_ID}
                            className="login-fb"
                            loginUrl="http://localhost:3000/register/"
                            requestTokenUrl="http://localhost:3000/register/?twAction=twtoken"
                            buttonText=""
                            icon={false}
                            onSuccess={this.twitterResponse}
                            onFailure={this.onFailure}
                            ><img className="access__header--social-img" src="/img/social/twitter.png" />

                        </TwitterLogin>

                        {/* <GitHubLogin 

                                clientId={config.GITHUB_APP_ID}
                                className="login-fb"
                                redirectUri=""
                                buttonText=""
                                icon={false}
                                onSuccess={this.githubResponse}
                                onFailure={this.onFailure}
                         ><img className="access__header--social-img" src="/img/social/github.png" />
                         </GitHubLogin> */}

                        {/* </Link > */}

                        <GoogleLogin
                                clientId={config.GOOGLE_APP_ID}
                                
                                render={renderProps => (
                                    <button onClick={renderProps.onClick} disabled={false} style={{cursor: 'pointer',border: 'none'}}>
                                        <Google />
                                    </button>
                                  )}
                                onSuccess={this.googleResponse}
                                onFailure={this.onFailure} 
                                cssClass="login-fb"
                                
                                
                                icon= {false}
                                 >

                            
                            {/* <Google /> */}

                       </GoogleLogin >

                    </section>

                    <span className="access__header--orhbar"></span>
                    <span className="access__header--withvbar"></span>
                    
                    <section className="access__header--or">
                        <span className="access__header--or-o"><small>o</small></span>
                        <span className="access__header--or-r">r</span>
                    </section>

                   
                </div>
               
                

           
        )
    }

}


export default AccessHeader