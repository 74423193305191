import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FavoriteIcon from '@material-ui/icons/Favorite';








const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 1.5+'rem',
   
  
  },

  headingCont:{

    backgroundColor: "rgba(205,153,153,.1)",
    border: "none",
    borderRadius: 1+'rem',
    boxShadow: 0+'px ' + 0+'px ' + 0+'px ' + 0+'px ' + "rgba(205,153,153,.01)",
    width: 100+'%'
  },



  expandedContent:{

    backgroundColor: "#FFF",
    padding: 0,
    paddingTop: 1.5+'rem',
    width: 100+"%",
    paddingLeft: 2+"%",
    paddingRight: 5+'%',
    fontSize: 1.2+'rem'
  }
}));






 function Accordion(props) {

 
  
  const classes = useStyles();
  console.log('THE REST PROP')
  const {isSub=false} = props
  // console.log(rest)

  return (
    <div className={classes.root}>

      <ExpansionPanel className={classes.headingCont} style={{marginBottom: 2+'rem'}} >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon style={{color: 'seagreen',fontSize: '3rem',fontWeight: 'bolder'}} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
      <Typography  className={classes.heading} style={isSub ? {color: 'white'} : null}>{props.name ? props.name : null}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.expandedContent}>
         
          {props.children ? props.children : null}
         

          
        </ExpansionPanelDetails>
      </ExpansionPanel>
     
        
    </div>
  );
}

export default Accordion