
import React, {Component} from 'react' 
import {Helmet} from 'react-helmet'
import {Link} from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import * as actions from './actions'
import Header from './header/header'
import Faqi from './faqi/fagi'





class Privacy extends Component{


    constructor(props){

        super(props)
        this.state ={

            width: 0
            
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    }


    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }

    componentDidMount(){

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
       
    }

    componentWillUnmount(){

        window.removeEventListener('resize', this.updateWindowDimensions);
      
     }

   


    render(){

        const {state} = this
        const {width} = state

        return(

            <article className="faqs">

                <Helmet>
                    <title>Jobbri - Privacy</title>
                </Helmet>
                  
                  <section>
                      <Header />
                  </section>
                  <div className="faqs__highlight">
                  <h1>Privacy Policy</h1>
                    <p>Last updated: January 15, 2021</p>
                    <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
                    <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the Privacy Policy Generator.</p>
                  </div>
                  <Faqi width={width} />
                  {/* <section className="faqs__contact">

                      <p className="faqs__contact--text">Your question(s) not answered?</p>
                      <Link to="/contact" className="link">
                        <button className="faqs__contact--btn">Email us</button>
                      </Link>

                  </section>
                     */}
                

                

            </article>
        )
    }




}

const mapStateToProps = (state)=>{


    return {

        login: {
            ...state.auth
        }
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...actions},dispatch)
    }
}

export default connect(mapStateToProps,mapDispachToProps)(Privacy)