
import React from 'react';
import {BrowserRouter,Link} from 'react-router-dom';


const Brand = ()=>{


	   const getCurrntYear = ()=>{

            return new Date().getFullYear()

       }

		return(

            <div className="footer__brand">

            <section className="footer__brand--brand">

                <div className="mg-bottom-fd-md" >

                    <Link to='/' className="app__brand--name-footer">

                        <span className="fg-dark d-inline-block ">
                            <img src="/img/jobbrilogo.png" alt="jobbri logo" width="100" />
                        </span>
                        {/* <span className="fg-dk-green mg-left-fd-bt d-inline-block font-fd-tn">
                            o
                        </span> */}



                    </Link>


                </div>

                <div>
                    <span className="mg-left-fl-bt font-fd-xx-tn fg-dark-lt">Jobbri. @{getCurrntYear()}.All rights reserved</span>
                </div>


            </section>
            <section className="footer__brand--social">

                <div className="mg-bottom-fd-xx-sm">
                    <p className="font-fd-xx-tn bg-lt-grey-lta hr-size-fl-xx-lg pd-fd-tn">Anything you would like to say? connect on social media</p>
                </div>
                <div className="footer__brand--social-cont">

                    <span className="footer__brand--social-social">
                        <small className="footer__social--text-1">
                            <a href="https://www.facebook.com/Jobbri-105946767631202/" target="_blank">
                               <i class="fa fa-facebook" aria-hidden="true"></i>
                            </a>
                            
                        </small>
                        {/* <small className="footer__social--text-2">cebook</small>
                        <small className="footer__social--bg"></small> */}
                    </span>
                    <span className="footer__brand--social-social">
                        <small className="footer__social--text-1">

                        <a href="https://twitter.com/jobbrijobs" target="_blank">
                               <i class="fa fa-twitter" aria-hidden="true"></i>
                            </a>
                        

                        </small>
                        {/* <small className="footer__social--text-2">itter</small>
                        <small className="footer__social--bg"></small> */}
                    </span>

                    <span className="footer__brand--social-social">
                        <small className="footer__social--text-1">

                           <a href="https://www.instagram.com/jobbri/" target="_blank">
                               <i class="fa fa-instagram" aria-hidden="true"></i>
                            </a>
                       

                        </small>
                        {/* <small className="footer__social--text-2">stagram</small>
                        <small className="footer__social--bg"></small> */}
                    </span>
                    {/* <span className="footer__brand--social-social">
                        <small className="footer__social--text-1">
                            Go
                        </small>
                        <small className="footer__social--text-2">plus</small>
                        <small className="footer__social--bg"></small>
                    </span> */}

                </div>

            </section>
            <section className="footer__brand--policy">

                    
                    <span className="fg-dark-lt"><Link to="/privacy" className="link">Privacy</Link></span>
                    <span className="fg-dark-lt"><Link to="/terms" className="link">Terms</Link></span>
                    <span className="fg-dark-lt"><Link to="/faqs" className="link">Faqs</Link></span>
                
            </section>
            <strong className="clearfix" />

            </div>

			
		)


	
	

}




export default Brand;





