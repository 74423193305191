
import React, {Component} from 'react' 

// import ProgressBarLinear from '../progressbarlinear'

// import ReCAPTCHA from "react-google-recaptcha"


class Bio extends  Component{


    constructor(props){

        super()
       
    }

 

  
    render(){

        
         const {props} = this 
         const {bio=''} = props 
         const editContStyles = {boxShadow: 'none'}

        
        // const {push} = actions
        // const {message = ''} = auth 
        // console.log('tHE MESSAGE')
        // console.log(message)
        // console.log(message.trim() !== '')


        return(

                    <section className="resume__about--bio-edit" style={editContStyles}>


               
                        
                            
                        {bio.trim() === '' 
                         ? ''
                         : <p className="resume__about--bio-text">
                            {bio}
                           </p>}

                            

                       
                            

                    </section>

                    
               

             
             
           
        )
    }




}

export default Bio