
import * as templates from './templates'
import Icon from '@material-ui/core/Icon';
import CheckIcon from '@material-ui/icons/Check';

import {delayMessage,delayResponseMessage,randomize,formatDate,getFiller,scrollToBottom} from './util'
import {nextQuestion,readResponse} from './questionr'
import { isProcessing, messages } from '../props';

export const showIO = (self=null,qs,index=0) =>{

    const {multiText=false,question=null,responseText='',enforce=false,processor=false} = qs
    const {props} = self 
    const {actions} = props 
    const {saveChatText,saveMessage} = actions 
    //const {isTyping} = chatHeader 
    //const {messages=[]} = humanoid 
    

    // if(enforce) return saveMessage([{
            
    //     text: responseText,
    //     date: new Date()
    // }])

    if(processor) {
        delayResponseMessage(self,[{
            
        text: responseText,
        date: new Date()
            }],null,3000)
            return scrollToBottom()
        }

    if(enforce) {
            delayResponseMessage(self,[{
                
            text: responseText,
            date: new Date()
        }],null,6000)
        return scrollToBottom()
    }

    if(!question) {
        saveMessage([{
            
        text: responseText,
        isSender: true,
        date: new Date()
        }])
        return scrollToBottom()
    }
    

    if(multiText){

        question.forEach((q,i)=>{

            const {text='',isTemplate=false, template_name='',isHigherOrder=false} = q

            if(i === 0){

                // delayResponseMessage(self,[{
            
                //     text: text instanceof Array ? randomize(text) : text,
                //     date: new Date()
                // }],{q,i})

                if(isHigherOrder){

                    delayResponseMessage(self,[{
            
                        text: text instanceof Array ? getFiller(self,randomize(text)) : getFiller(self,text),
                        date: new Date()
                    }],{q:q,index:i},0,0)

                }else{

                    delayResponseMessage(self,[{
            
                        text: text instanceof Array ? getFiller(self,randomize(text)) : getFiller(self,text),
                        date: new Date()
                    }],{q:q,index:i},0,0)

                }
                

                // saveChatText(
                //     [{
            
                //         text: text instanceof Array ? randomize(text) : text,
                //         date: new Date()
                //     }],
                //     q,
                //     i
                
                // )
            }else{

                if(isTemplate) {
                    delayMessage(self,saveTemplateMessage,{templateName: template_name,q:q,currentIndex: i},null,1000,1000)
                    delayResponseMessage(self,[{
            
                        text: text instanceof Array ? getFiller(self,randomize(text)) : getFiller(self,text),
                        date: new Date()
                    }],{q:q,index:i},0,0)
        
                
                // saveChatText(
                //     [{
        
                //         text: text instanceof Array ? randomize(text) : text,
                //         date: new Date()
                //     }],q,i)
                    
                }else{

                    if(isHigherOrder){

                        delayMessage(self,saveChatText,{text: text instanceof Array ? getFiller(self,randomize(text)) : getFiller(self,text),date: new Date()},q,i,500,500)
            
        
                    }else{
        
                        delayMessage(self,saveChatText,{text: text instanceof Array ? getFiller(self,randomize(text)) : getFiller(self,text),date: new Date()},q,i)
        
                    }
        
        
                   
        
                }
        

                
            }


        })

       

    
        
    }else{

        const {template_name='',isTemplate=false,text='', isHigherOrder=false} = question 

        if(isTemplate) {
            delayMessage(self,saveTemplateMessage,{templateName: template_name,q:question,currentIndex: index},null,1000,1000)
            delayResponseMessage(self,[{
            
                text: text instanceof Array ? getFiller(self,randomize(text)) : getFiller(self,text),
                date: new Date()
            }],{q:question,index:index},0,0)
        
            // saveChatText(
            //     [{

            //         text: text instanceof Array ? randomize(text) : text,
            //         date: new Date()
            //     }],
            //     question,
            //     index)
            
        }else{

            if(isHigherOrder){

                delayResponseMessage(self,[{
            
                    text: text instanceof Array ? getFiller(self,randomize(text)) : getFiller(self,text),
                    date: new Date()
                }],{q:question,index:index},1000)
    

            }else{

                delayResponseMessage(self,[{
            
                    text: text instanceof Array ? getFiller(self,randomize(text)) : getFiller(self,text),
                    date: new Date()
                }],{q:question,index:index})
    

            }

           

            // saveChatText(
            //     [{
        
            //         text: text instanceof Array ? randomize(text) : text,
            //         date: new Date()
            //     }],question,index
            
            // )

        }


        

    }
    scrollToBottom()
    
   
    
}

export const getIO = (self = null,e = null)=>{

    //if(e) e.preventDefault() 
    
    const {props,state} = self 
    const {chatHeader,actions,humanoid} = props
    const {isTyping=false} = chatHeader 
    const {isProcessing=false} = humanoid
    const {updateTypingStatus} = actions
    

    
    
    const text = state.text
    const aliasText= state.aliasText 
    let textOnShow = aliasText.trim() ? aliasText : text

    //if(!skipText && text.trim() === '') return

    // const {actions,humanoid,chatHeader} = props 
    // const {setIsSetupComplete,saveChatText} = actions 
    // const {isTyping} = chatHeader 
    // const {botCurrentStage} = humanoid 
    if(isTyping) return showIO(self,{responseText: textOnShow})
    if(isProcessing) {

        showIO(self,{responseText: textOnShow})
        return showIO(self,{responseText: '',processor:true})
    }
    showIO(self,{responseText: textOnShow})
    readResponse(self,text)
    // nextQuestion(self,text)
    self.setState({...self.state,text: '',aliasText})
    

}

export const saveTemplateMessage = (self,misProps=null)=>{

    const {props} = self 
    const {actions} = props 
    const {saveChatText} = actions 

    // console.log('THE SAVETEMPLATE')
    // console.log(misProps)

    saveChatText(
        [{
            templateName: misProps.templateName,
            template: true,
            date: new Date()
        }],
         misProps.q,
         misProps.currentIndex
        // misProps.hostCurrentState,
        // misProps.hostCurrentStateSID,
        // misProps.hostCurrentStateCQ
    )


}


export const showConversation = (chat,i,self=null,messages=[]) =>{

    let message = null 
    const {props} = self 
    const {humanoid}= props
    const {isProcessing=false,isProcessingText=''} = humanoid
   


    if(!chat.isSender){

       if(chat.isSending){

           
        

       message = (
       
       <>
       <div className="humanoid__chat--chats-receive" key={i}>

           <p className="humanoid__chat--chats-receive-text">
               {chat.text}
              
              
           </p>
           <span className="humanoid__chat--chats-receive-date">
               {formatDate(chat.date)}
           </span>

       </div>

       <div className="humanoid__chat--chats-receive" key={i}>

           <p className="humanoid__chat--chats-receive-text">
               <small>Processing</small>
           </p>
           <span className="humanoid__chat--chats-receive-date">
               {formatDate(chat.date)}
           </span>

       </div>


       </>

       
       
       )

       }else{

        const {text='',template=false,templateName=''} = chat 
        // console.log('THE TEMPLATES')
        // console.log(templates)
        // console.log(template)
        // console.log(templateName)
        // console.log(templates[templateName])

           
       message = (
       
        <>
         {
         template 
         ? i === messages.length - 1
            ? <div className="humanoid__chat--chats-receive" key={i}>

                    <p className="humanoid__chat--chats-receive-text">
                    {template ? templates[templateName](self) : text}
                    
                                
                    
                                
                    {/* <div class="messages">
        
                        <section className="message">
                            <p className="messages message loading"><span></span></p>
                        </section>
                            
                            </div> */}
                    </p>
                    <span className="humanoid__chat--chats-receive-date">
                        {formatDate(chat.date)}
                    </span>

        
                </div>

            : null
         : i === messages.length - 1 && isProcessing === true
         
            ?   
            
                    

                    <div className="humanoid__chat--chats-receive" key={i}>

                        <p className="humanoid__chat--chats-receive-text">
                            {isProcessingText}
                           

                            <p className="messages-loading">
                                <span></span>
                            </p>
                                
                            
                        </p>
                        <span className="humanoid__chat--chats-receive-date">
                            {formatDate(chat.date)}
                        </span>

                    </div>
                
            :  <div className="humanoid__chat--chats-receive" key={i}>

                    <p className="humanoid__chat--chats-receive-text">
                    {template ? templates[templateName](self) : text}
                        {/* <div class="messages">

                            <section className="message">
                                <p className="messages message loading"><span></span></p>
                            </section>
                            
                        </div> */}
                    </p>
                    <span className="humanoid__chat--chats-receive-date">
                        {formatDate(chat.date)}
                    </span>

                </div>
         }
        

           </>


           )
       }

    }else{


       message = (

        
           <div className="humanoid__chat--chats-send" key={i}>
               <p className="humanoid__chat--chats-send-text">
               {chat.text}
               
              
               </p>
               <span className="humanoid__chat--chats-send-date">
                   <small className="humanoid__chat--chats-send-date-time">

                    {formatDate(chat.date)}
                   </small>

                   <small className="humanoid__chat--chats-send-date-checks">

                   <span>
                       <Icon>
                           <CheckIcon style={{fontSize:'12px'}} className="fg-primary" />
                       </Icon>
                   </span>

                   <span>
                       <Icon>
                           <CheckIcon style={{fontSize: '12px'}} className="fg-primary" />
                       </Icon>
                   </span>
                   </small>
               </span>
           </div>
           )

    }
   
    return message

}

