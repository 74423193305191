
import React, {Component} from 'react' 
import FormControl from '../formcontrol'
// import ProgressBarLinear from '../progressbarlinear'
import { toast } from 'react-toastify';
import CuratedSkillInfo from './cuskillsinfo'
import CuratedSkillForm from './cuskillsform'
import { actions } from 'reapop';
// import ReCAPTCHA from "react-google-recaptcha"


class Bio extends  Component{


    constructor(props){

        super()

    }




    getPortfolio = (e,i,isEditing,setIsEditing,allowEdit,isSkillAdd)=>{

        const {props,removeItem} = this 
        const {actions,userProfile,width} = props 

        if(i+1 === isEditing){

            return <CuratedSkillForm width={width} skill={e} key={i} isEditing={isEditing} actions={actions} userProfile={userProfile} allowEdit={allowEdit} setIsEditing={setIsEditing} />
        }else{

            return <CuratedSkillInfo width={width} skill={e} key={i} isSkillAdd={isSkillAdd} removeItem={removeItem} setIsEditing={setIsEditing} isEditing={isEditing} allowEdit={allowEdit} index={i} />
        }
        
    
    }

    removeItem = (e,itemId)=>{
        
        e.preventDefault() 
        
       
    
        console.log('THE ID OF THE DELETING ITEM')
        console.log(itemId)
        
        const {props} = this 
        const {actions,userProfile} = props 
        const {profile} = userProfile
        const {id} = profile
        const {deleteResumeItem} = actions
      
     
         deleteResumeItem({skill: {id:itemId}},id)
        
        // if(!formIsValid) return
        // if(!isCaptchaVerified) return  notify({message:'Recaptcha must be selected before signin',type: 'warning',className: 'notify-warn'})
        
        
        
    
    }
 

               

   

    render(){

        
         const {props,state} = this 
        //  const {bioText} = state
         const {width, isSkillAdd = false,skill,isEditing=0,allowEdit,setIsEditing,isSkillAdding,isAdding,userProfile,actions} = props 
        
        //  const editContStyles = isEdit ? {boxShadow: '-15px 10px 25px 5px rgba(0, 0, 0, 0.04);'} : {boxShadow: 'none'}
        // const {push} = actions
        // const {message = ''} = auth 
        // console.log('tHE MESSAGE')
        // console.log(message)
        // console.log(message.trim() !== '')

        //if(isPortfolioAdd) return <CuratedPortfolioForm isAdding={true} /> 

        if(!isSkillAdd && skill.length <= 0) return <>
            <section className="resume__curated--card" style={{marginBottom: `100px`}} id={'skill'}>

                <div className="resume--add-info">

                <div class="resume--add-info-rect resume--add-info-rect-1 bx-raised">
                </div>
                <div class="resume--add-info-rect resume--add-info-rect-2 bx-raised">
                </div>
                <div class="resume--add-info-rect resume--add-info-rect-3 bx-raised"></div>
                <div class="resume--add-info-rect resume--add-info-rect-4 bx-raised" onClick={isSkillAdding}>

                        <p>
                            
                            <small style={{fontSize: '20px',display: 'block',textAlign: 'center'}}>
                               <i className="fa fa-lx fa-plus" style={{color: 'seagreen'}} aria-hidden="true">
                               </i>
                              
                            </small>
                            <small style={{fontSize: '15px',display: 'block',textAlign: 'center'}}>Add skills you have, employers love them</small>
                        </p>
                </div>

                </div>


            </section>
            
        </>

        return(

                    

                        
                       
                        // <>{ skills.map((e,i)=> this.getskills(e,i))}</>

                        <section className="resume__curated--card">

                        {!isSkillAdd && skill.length < 5 && isEditing === 0 
                           ?   <small className="resume--edit-pen resume__about--bio-edit-pencil" onClick={isSkillAdding}>
                               <i className="fa fa-lx fa-plus" style={{color: 'seagreen'}} aria-hidden="true">
                               </i>
                              
                               </small>
                           : null 
                           }
                        <div className="resume__curated--card-pointer"></div>
   
                        <div className="resume__curated--card-pointee">
   
                           <h5 className="resume__curated--card-pointee-period">Skill</h5>
                           {width > 1200 
                            ?<p className="resume__curated--card-pointee-legend">

                                    <span>Skill</span>
                                    <span>Score</span>
                                    <span>Years</span>
                                    <span>Actions</span>
                                
                                </p>
                                : null
                            }
                           
                            <ul className="resume__curated--card-pointee-list">

                            {skill.map((p,i)=> this.getPortfolio(p,i,isEditing,setIsEditing,allowEdit,isSkillAdd))} 
                            {isSkillAdd ? <CuratedSkillForm width={width} userProfile={userProfile} actions={actions} isSkillAdd={isSkillAdd} isEditing={isEditing} setIsEditing={setIsEditing} isSkillAdding={isSkillAdding} isAdding={isAdding} allowEdit={allowEdit} /> : null}

                            </ul>
                           
                           {/* <p className="resume__curated--card-pointee-legend">
                                   <span>Name</span>
                                   <span>Description</span>
                                   <span>Link</span>
                                   <span>Actions</span>
                               
                               </p>
   
                           <ul className="resume__curated--card-pointee-list">
   
                                   <li className="resume__curated--card-pointee-list-i">
                                       <span>Indym</span>
                                       <span>An independent Modeling agency</span>
                                       <span><a href="https://indym.herokuapp.com" target="_blank">View</a></span>
                                       <span>
                                           <small><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                               
                                           <small><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                                       </span>
                                       
                                   </li>
                                   <li className="resume__curated--card-pointee-list-i">
                                       <span>PHP</span>
                                       <span>An independent Modeling agency</span>
                                       <span><a href="https://indym.herokuapp.com">View</a></span>
                                       <span>
                                           <small><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                               
                                           <small><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                                       </span>
                                       
                                   </li>
                                   <li className="resume__curated--card-pointee-list-i">
                                       <span>Javascript</span>
                                       <span>An independent Modeling agency</span>
                                       <span><a href="https://indym.herokuapp.com">View</a></span>
                                       <span>
                                           <small><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                               
                                           <small><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                                       </span>
                                       
                                   </li>
   
   
                               </ul> */}
   
                           </div>
                        </section>

           
             
             
           
        )
    }




}

export default Bio