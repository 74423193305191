
import {activityProps} from './activityProps' 
import {profileQuestionsProps} from './profileQuestionsProps'

export const messages = [

    // {
    //     text: `Hi there Surprise, I'm excited you want to create your cv with us, over the next few minutes, I'll be chating with you to help you create your cv with ease, are you ready?`,
    //     date: '17 February 2021 21:28:20'
        

    // },
    // {
    //     text: `Yes, I'm ready`,
    //     date: '17 February 2021 21:29:45',
    //     isSender: true

    // },
    // {
    //     text: `...I'd like to know, ...am I allowed to ask questions while chatting?`,
    //     date: '17 February 2021 21:30:24',
    //     isSender: true

    // },
    // {
    //     text: `...Not yet Surprise, I'm only just a robot at this point, but that will be implemented soon.`,
    //     date: '17 February 2021 21:30:20'
        

    // },
    // {
    //     text: `What's your name?`,
    //     date: '17 February 2021 21:31:00'
        

    // },
    // {
    //     text: `Surprise`,
    //     date: '17 February 2021 21:31:24',
    //     isSender: true

    // },

    // {
    //     text: `Do you have a nickname?`,
    //     date: '17 February 2021 21:32:00'
        

    // },
    // {
    //     text: `Yes`,
    //     date: '17 February 2021 21:32:20',
    //     isSender: true

    // },

    // {
    //     text: `What's your nickname?`,
    //     date: '17 February 2021 21:33:10'
        

    // },

    // {
    //     text: `Msapu`,
    //     date: '17 February 2021 21:33:50',
    //     isSender: true

    // },

    // {
    //     text: `Lol! Nice one, is it fine if I call you "Msapu"?`,
    //     date: '17 February 2021 21:34:20'
        

    // },

    // {
    //     text: `Yes, no worries`,
    //     date: '17 February 2021 21:35:20',
    //     isSender: true

    // },

    // {
    //     text: `Thanks for the information provided Msapu, we are done capturing your personal information, are you ready for the next step?`,
    //     date: '17 February 2021 21:35:20'
        

    // },

    
]

//export const hostr = {...hostProps}
export const questionas = {...activityProps} 
export const profileQuestions = {...profileQuestionsProps}
// export const profileFeatureKeys = {...profileFeatureKeys}
export const currentQuestionr = "start"
//export const currentActivity = ''
export const currentQuestion = null
export const currentQuestionIndex = 0
export const currentQuestions = []
export const isProcessing = false 
export const isProcessingText = `I'm currently processing your request`
export const activeActivityHolder = {activity: '',index: 0}
export const isReturnUser = false 
export const isOffline = false 
export const isActionSuccessful=false 
export const message = ''
export const currentProfileFeature = ''
export const currentProfileFeatureRecord= null 
export const fillers = null 
export const updateType = ''
export const humanoidActionType = ''
// export const hostCurrentStateSID=0 
// export const botCurrentStage='host'


export const isNotReadyInitialMessages = [

    'Hi, Welcome back, I hope you are doing well. let\`s start where we last left off',
    `...if I remember well, the last time you were not ready, but I'm convinced that you are now`,
     `I wanted to find out some information from you that time`,
    `...so I'll just start asking you some questions regarding that, and more.`
]

export const isPausedInitialMessages = [

    'Hi there, Welcome back, I hope you are doing well. let\`s start where we last left off',
    `...if I remember well, the last time you left our chat, but I'm convinced that you want to complete creating your cv`,
     `I wanted to find out more information about you that time`,
    `...so I'll just continue asking you questions to find out more.`
]

export const closingStatement = {
 
    closeText:  `Thanks for taking your time to chat with us and setting up your cv with our platform, we wish you well on landing your desired job, Goodbye`,
                
}

export const isSendingText = {
 
    sendText:  `Hang tight while we finalize setting up your cv...`,
                
}

export const answers = {}

export const stepID = 0
export const currentStep = ''
export const currentStepCQ = 0
export const isSetUpComplete = false 
export const isNotReady = false 
export const isNotReadyMessagesSet = false 
export const isPausedMessagesSet = false
export const isClosed = false 
export const isPaused = false
export const isProcessed = false 
export const isFetching = false 
export const isDataCaptureComplete = false 
export const canSend = false
