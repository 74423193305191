
import React, { Component } from 'react' 





 function Common (props){
    
   
     
     
      


   const searchJobs= (job)=>{

       console.log('The current Job')
       console.log(props) 
       const {actions,search} = props 
       const {saveJobSearchParams,push} = actions 
       const {jl='South Africa'} = search
       //console.log(url)
      // push(url)
       saveJobSearchParams({
        jq: job,
        jl: jl,
        // canSetHistory: true,
        fetchJobs: true
    })

   }

  

    return(

      
     
           <section className="job__search--common">

               <p className="job__search--common-title">Common Searches:</p>
               <ul>
                      <li onClick={searchJobs.bind(this,`IT`)}>IT</li>
                      <li onClick={searchJobs.bind(this,`Accounting`)}>Accounting</li>
                      <li onClick={searchJobs.bind(this,`General worker`)}>General Worker</li>
                      <li onClick={searchJobs.bind(this,`Nursing`)}>Nursing</li>
                      <li onClick={searchJobs.bind(this,`Cashier`)}>Cashier</li>
                      <li onClick={searchJobs.bind(this,`Admin`)}>Admin</li>
               </ul>
             
           </section>
      
    )

}


export default Common