
import React,{Component} from 'react'
import Send from './send/send' 



class Mail extends Component{
    
    
    render(){

        const {props} = this 
        const {actions,contact} = props

        return(

           

                
              
            <Send actions={actions} contact={contact} />
            
               
                

           
        )
    }

}


export default Mail