
import React from 'react' 



function What() {
    

    return(

           <section className="home__what">

               <div className="home__what--title bx-shadow-dark">
                    <p className="font-fd-tn fg-primary">
                        What are we?
                    </p>
               </div>

               <div className="home__what--message">
                   <p>
                   We are Jobbri, a job search platform whose purpose is to help improve jobseekers' job search experience. Our aim is to use technology to  connect jobseekers and employers better and quicker.
                   </p>
               </div>
             
           </section>
    )

}


export default What