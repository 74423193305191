
import React, {Component} from 'react'

class ClientErrorHandler extends Component{
    
    
    constructor(props) {
        super(props);
      
    }

    postError = ()=>{

        return false

    }


      
    
    render(){


        const {props,postError} = this 
        const {errorMeta={}} = props 
        const {
            postErrorAction = postError,
            actionColor='warn',
            header='OOPS!',
            headerText='Something went wrong!',
            errorText= 'We have unfortunately encountered an error processing a request',
            buttonText= 'Please Try Again'} = errorMeta 
        const errorClasses = actionColor === 'warn'  
                                            ?  'blog__error--card-rect blog__error--card-rect-warn'
                                            : 'blog__error--card-rect blog__error--card-rect-error'

        return(

           
            <article className="blog blog-error">
                
                <div  className="blog__error">

                   <section className="blog__error--container">

                    
                                <div className={`${errorClasses}  bx-raised`}>
                                </div>
                                <div className={`${errorClasses} blog__error--card-rect-2 bx-raised`}>
                                </div>
                                <div className="blog__error--card-rect blog__error--card-rect-3 bx-raised">

                                    <p className="blog__error--card-rect-3-header">{header}</p>
                                    <p className="blog__error--card-rect-3-sub-header">{headerText}</p> 
                                    <p className="blog__error--card-rect-3-text">{errorText}</p>

                                    

                                    <button class="blog__error--card-btn" onClick={()=> postErrorAction()}>
                                        <span class="blog__error--card-btn-rect"></span>
                                        <span class="blog__error--card-btn-rect-2">
                                            <small>{buttonText}</small>
                                        </span>
                                    </button>

                                </div>

                          

                   </section> 

                  
                </div>

                </article> 
               
                

           
        )
    }

}

export default ClientErrorHandler