import { yellow } from '@material-ui/core/colors'
import React, { Component } from 'react' 
import Cuport from './cuports'
import CuLanguages from './culanguages'
import CuSkills from './cuskills'





class Curated extends Component{
    
    constructor(){

        super()
   

        
    }





    
    render(){


       
       const {props} = this 
       
       const {userProfile} =  props 
       const {profile} = userProfile
       const {portfolio=[],language=[],skill=[]} = profile

        return(

           
    
            <div className="resume__curated">

                 
                 <h2 className="resume__curated--title">
                     <span className="resume__curated--title-circle"></span>
                     <span className="resume__curated--title-text">More Information About Me</span>
                 </h2>

                 <div className="resume__curated-rect">

                 <CuLanguages language={language}  />

                     
                     <section className="resume__curated--card resume__curated--card-2">
                    
                     <CuSkills skill={skill}  />

                     

                     </section>

                     <Cuport portfolio={portfolio}  />
                     
                     
                    
                    
               

                </div>

               
                



            </div>

          
        )
    }


}


export default Curated


