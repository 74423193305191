
import React, {Component} from 'react' 
import { toast } from 'react-toastify';
import CuratedLanguageInfo from './culanguageinfo'

// import ReCAPTCHA from "react-google-recaptcha"


class Bio extends  Component{


    constructor(props){

        super()

    }




    getPortfolio = (e,i)=>{


       

        return <CuratedLanguageInfo language={e} key={i} index={i} />
    
    }


 

               

   

    render(){

        
         const {props} = this 
        //  const {bioText} = state
         const { language} = props 
        
        

        return(

                    

                        
                       
                        // <>{ language.map((e,i)=> this.getlanguages(e,i))}</>

                        <section className="resume__curated--card">


                        <div className="resume__curated--card-pointer"></div>
   
                        <div className="resume__curated--card-pointee">
   
                           <h5 className="resume__curated--card-pointee-period">Languages</h5>
                           <p className="resume__curated--card-pointee-legend">

                                <span>Language</span>
                                <span>Speak</span>
                                <span>Write</span>
                                <span>Read</span>
                                <span>Actions</span>
                               
                            </p>
                            <ul className="resume__curated--card-pointee-list">

                            {language.map((p,i)=> this.getPortfolio(p,i))} 
                         

                            </ul>
                           
                    
   
                           </div>
                        </section>

           
             
             
           
        )
    }




}

export default Bio