
import React, { Component } from 'react' 





 function Service (){
    
   
  

    return(

      
                
                 

                    <div className="about__service--key">

                            <h2 className="about__service--key-title">

                                    <span className="about__service--key-title-ck about__service--key-title-ck-1 "></span>
                                    <span className="about__service--key-title-ck about__service--key-title-ck-2 "></span>
                                    <span className="about__service--key-title-ck about__service--key-title-ck-3 "></span>
                                    <span className="about__service--key-title-ck about__service--key-title-ck-4 ">
                                        
                                    </span>
                                    <small className="about__service--key-title-text">Our services</small>
                                    
                                
                            </h2>

                    </div>

              

              
     
      
    )

}


export default Service