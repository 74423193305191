
import {nextQuestionr,askQuestion} from './questionr'
import {showIO} from './view'

export const loadNext = (self = null,id)=>{

   const {state} = self 
   const {text} = state
   nextQuestionr(self,id)


}

export const loadWithIncomplete = (self = null,id)=>{

    const {state,props} = self 
    const {text} = state 
    const {humanoid,actions} = props 
    const {questionas,activeActivityHolder={}} = humanoid 
    const {setCurrentQuestions,setNextQuestionr} = actions
    const {activity='',index=0} = activeActivityHolder 
    const questionr = questionas[activity] 
    const {questions} = questionr 
    let currentQuestion = questions[index]

    console.log('THE VALUE OF LOADWITHINCOMPLETE')
    console.log(id)
    console.log(text)
   
    if(id.trim() === 'yes'){

        setNextQuestionr(activity)
        setCurrentQuestions(questions)
        askQuestion(self,{question: currentQuestion},index)
       
    }else{

        nextQuestionr(self,'userAction')

    }
    
 
 
 }


 export const loadWithProfileSegment = (self = null,id)=>{



    const {props} = self 
    const {setProcessor} = props 
    setProcessor(false,'')
   
    if(id === 'yes'){

        nextQuestionr(self,'userAction')
       
    }else{

        nextQuestionr(self,'close')

        setTimeout(()=>{

            closeChat(self)
        },10000)

       

    }
 
 
 }

 export const loadComplete = (self = null,id)=>{

    
    const {props} = self 
    const {setProcessor} = props 
    setProcessor(false,'')
   
    if(id === 'yes'){

        nextQuestionr(self,'userAction')
       
    }else{

        nextQuestionr(self,'close')

        setTimeout(()=>{

            closeChat(self)
        },10000)

       

    }
       
  
 
 
 }
export const registerUser = (self=null,text='')=>{

    console.log('THE REGISTER QUESTIONING IS COMPLETE')

    const {props} = self 
    const {setProcessor} = props 
    setProcessor(true,'Processing')
    setHumanoidActionType('register')
    nextQuestionr(self,'complete')

    

}

export const updateUserProfileSegment = (self=null,text='')=>{

    console.log('THE UPDATE QUESTIONING IS COMPLETE')

    const {props} = self 
    const {actions,humanoid,userProfile} = props 
    const {updateType,currentProfileFeature,answers} = humanoid
    const {profileitems} = answers
    const {setProcessor,addResumeItem,updateResumeItem,deleteResumeItem,setHumanoidActionType} = actions

    setProcessor(true,'Processing')
    setHumanoidActionType('updateProfile')
    showIO(self,{responseText: '',processor:true}) 

    console.log('THE ANSWERS')
    console.log(answers)
    console.log(profileitems)
    const refinedAnswers = refineAnswers(profileitems) 
    // console.log('THE REFINED ANSWERS')
    // console.log(refinedAnswers)
    // nextQuestionr(self,'complete')
    // return setTimeout(()=>{
    //     setProcessor(false,'')
    //     nextQuestionr(self,'editrecordcomplete')
    // },60000)
    

   

    if(updateType === 'edition'){

        
        // updateResumeItem({[currentProfileFeature]: {...submittData,id:inId}},id)
        setProcessor(false,'') 
        nextQuestionr(self,'editrecordcomplete')


    }else if(updateType === 'addition'){

        // addResumeItem({[currentProfileFeature]: [{...submittData,u_id: id}]},id)
        setProcessor(false,'')
        nextQuestionr(self,'addrecordcomplete')

    }else if(updateType === 'deletion'){

        // deleteResumeItem({education: {id:itemId}},id)
        setProcessor(false,'')
        nextQuestionr(self,'deleterecordcomplete')

    }

    // setTimeout(()=>{
    //     setProcessor(false,'')
    //     nextQuestionr(self,'editrecordcomplete')
    // },60000)
    

    

}

export const refineAnswers = (toRefine)=>{



   console.log('THE TOREFINE')
   console.log(toRefine)
  let refined = {}
  for (const item in toRefine) {

     if(typeof toRefine[item] === 'object'){
         
         let toRefineChild = toRefine[item]
         for(const ch in toRefineChild){

             refined[ch] = toRefineChild[ch]
         }
     }else{

        refined[item] = toRefine[item]

     }
      
  }

  return refined

}


export const closeChat = (self = null)=>{

    const {props} = self 
    const {actions} = props 
    const {closeHumanoidchat,updateActiveStatus} = actions
   
   
    

   //setNextQuestionr(activity)
   updateActiveStatus(false)
   closeHumanoidchat()
    
    
 
 
}
 






