
import React, {Component} from 'react'
import {Helmet} from 'react-helmet' 
import {Link} from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import * as actions from './actions'
import Header from './header/header'
import Faqi from './faqi/fagi'





class Terms extends Component{


    constructor(props){

        super(props)
        this.state ={

            width: 0
            
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    }


    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }

    componentDidMount(){

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
       
    }

    componentWillUnmount(){

        window.removeEventListener('resize', this.updateWindowDimensions);
      
     }


   


    render(){

        const {state} = this
        const {width} = state

        return(

            <article className="faqs">
                <Helmet>
                    <title>Jobbri - Terms of service</title>
                </Helmet>
                  
                  <section>
                      <Header />
                  </section>
                  <div className="faqs__highlight">
                  <h1>Terms and Conditions</h1>
                    <p>Last updated: May 10, 2021</p>
                    <p>Please read these terms and conditions carefully before using Our Service.</p>
                  </div>
                  <Faqi width={width} />
                  
                    
                

                

            </article>
        )
    }




}

const mapStateToProps = (state)=>{


    return {

        login: {
            ...state.auth
        }
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...actions},dispatch)
    }
}

export default connect(mapStateToProps,mapDispachToProps)(Terms)