import { yellow } from '@material-ui/core/colors'
import React, { Component } from 'react' 
import Bio from './bio'
import Contact from './contact'
import Share from './share'
import ShareButton from './sharebutton'
import Upload from './upload'
import Profession from './professionform'
import IdentityForm from './identityform'
import { isActionSuccessful, isUploadResume } from '../../UserProfile/props'







class About extends Component{
    
    constructor(){

        super()
        this.state ={

            bioEdit: false,
            contactEdit: false,
            avatarEdit: false,
            professionSubmit: null,
            identitySubmit: null,
            localImage: null,
            shouldInitiateSubmit: false,
            input: {},
            width: 0,
            height: 0,
            
            

        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        
    }

    allowEdit = (key,e)=>{

        e.preventDefault()

         let editing = `${key}Edit` 
    
         let editingValue = this.state[editing]
         this.setState({...this.state,[editing]: !editingValue})
    }

    updateCaptured = (img)=>{

        this.setState({...this.state,localImage: img})
    }

    
    saveIdentitySubmit = (value)=>{

         this.setState({...this.state,identitySubmit: value})
    }

    saveProfessionSubmit = (value)=>{

        this.setState({...this.state,professionSubmit: value})
    }

    saveFormInput = (input)=>{

        this.setState({...this.state,input: {...this.state.input,...input}})
    }

    beginSubmit = ()=>{

        const {state} = this 
        const {professionSubmit,identitySubmit} = state 
        let profRes = null 
        let idRes = null

        console.log(professionSubmit)
        console.log(identitySubmit)
      

        if(professionSubmit) profRes = professionSubmit.submitForm()
        if(identitySubmit) idRes = identitySubmit.submitForm()

        if(profRes && profRes.message) return {isInputValid: false,message:profRes.message} 
        if(idRes && idRes.message) return {isInputValid: false,message:idRes.message} 

        if(profRes && idRes) return {input:{...profRes.data,...idRes.data},isInputValid: true} 
        if(profRes) return {input:{...profRes.data},isInputValid: true} 
        if(idRes) return {input:{...idRes.data},isInputValid: true} 

        return {input:null,isInputValid: true}
    }


    // verifyItem = (items)=>{

    //     let validData = null
    //     let noneTouched = true
    //     let breakOuter = false 

    //     console.log('The items')
    //     console.log(items)

    //     for(let item in items){

    //         if(breakOuter) break 

    //         console.log('THE ITEM VALUE')
    //         console.log(item)
    //         if(items[item]){

    //             noneTouched = false
    //             for(let k in item){
            
          
    //                 if(item[k].isValid === false){
    
    //                     breakOuter = true
    //                     validData = null
    //                     break
    
    
    //                 }else{
     
    //                     !validData ? validData = {[k]: item[k].value} : validData[k] = item[k].value
    
    //                 }
    
                
        
            
    //              } 

    //         }
           
    //     }
       

    //     return {
    //         noneTouched,
    //         validData
    //     }
    // }

    // verifyInputs = ()=>{

    //         const {state} = this
    //         const {identity,profession} = state 
    //         console.log('THE VALUE OF THE STATE')
    //         console.log(state)
    //         let verified = this.verifyItem({identity: identity,profession: profession})

    //         console.log('THE VERIEIED INPUTS')
    //         console.log(verified)
    //          return verified
            




       
    // }

    initiateSubmit = ()=>{

        this.setState({...this.state,shouldInitiateSubmit: true})
    }



    profileCompletenessStatus = (completePercent,addToPercent,addText,addID='')=>{

        return (<div>
            <section style={{cursor:'pointer',marginBottom: '30px'}} onClick={(e)=>this.scrollToBottom(e,addID)}>

               <span className="triangle-bottomright bx-raised-1" style={{color: '#cc3333',fontSize: '15px',display: 'inline-block',marginRight: '8px'}}>
                   <i className="fa fa-user" style={{position: 'relative',display: 'block',top: '20px',left: '-17px'}} aria-hidden="true"></i>
               </span>
               <div style={{color: 'seagreen',fontSize: '15px',display: 'inline-block',verticalAlign: 'top'}}>
                    <span style={{color: 'seagreen',fontSize: '15px',display: 'inline-block'}}>
                       {completePercent}% profile completeness
                   </span>

                   <span style={{color: 'black',fontSize: '14px',display: 'block',opacity: '.7'}}>
                       {addText} to increase it to {addToPercent}%
                   </span>
               </div>
               {/* <span style={{color: 'black',fontSize: '15px',display: 'block',opacity: '.7'}}>
                   Add experience to increase it to 70%
               </span> */}
            </section>
           
          
        </div>
       )
    }

    getPortionIncompleteness = (portions)=>{


         console.log('THE PORTIONS')
         console.log(portions)

         let incompleteCount = 0 
         let values = []
         let incomplete = portions.filter((p,i)=>{

            if(!p.value){

                incompleteCount += 1 
                values.push(p.key)
                return true

            }else if(typeof p.value === 'number' && p.value === 0){

                 incompleteCount += 1
                 values.push(p.key)
                 return true
            }else if(typeof p.value === 'string' && (p.value.trim() === '' || p.value.trim() === 'null')){

                incompleteCount += 1
                values.push(p.key)
                 return true
            }
         })

         return {incompleteCount,incomplete:values,portionsLen: portions.length}
    }

    checkProfileCompleteness = ()=>{

        const {props} = this 
        const {profileCompletenessItems,userProfile} = props 
        const {profile} = userProfile 
        const {personal = {}} = profile 
        const {profile_completeness,profile_completeness_max,is_upload_resume,last_name,bio,profession,phone,state
        } = personal 
        const percentPortion = Math.round((100 / profile_completeness_max),2)
        
      
        // is_upload_resume,phone,last_name, bio, profession,state
    
         if(profile_completeness === profile_completeness_max) return null 

         if(profile_completeness < profile_completeness_max){

            //if() 

            switch(profile_completeness){


                case 2:{

                    console.log('THE PERCENT PORTION')
                    console.log(percentPortion)

                    let profileIncrease = `Add ${profileCompletenessItems[2]}`
                    let profileI = profileCompletenessItems[2]
                    let addTo =  (percentPortion * 2) + percentPortion 
                    let completedPercent = percentPortion * 2

                    return this.profileCompletenessStatus(completedPercent,addTo,profileIncrease,profileI)

                }
                case 3: {
                  
                    let profileIncrease = `Add ${profileCompletenessItems[3]}`
                    let profileI = profileCompletenessItems[3]
                    let addTo =  (percentPortion * 3) + percentPortion
                    let completedPercent = percentPortion * 3


                    return this.profileCompletenessStatus(completedPercent,addTo,profileIncrease,profileI)
                }
                case 4: {
                    let profileIncrease = `Add ${profileCompletenessItems[4]}`
                    let profileI = profileCompletenessItems[4]
                    let addTo =  (percentPortion * 4) + percentPortion
                    let completedPercent = percentPortion * 4


                    return this.profileCompletenessStatus(completedPercent,addTo,profileIncrease,profileI)
                }
                case 5: {

                    let profileIncrease = `Add ${profileCompletenessItems[5]}`
                    let profileI = profileCompletenessItems[5]
                    let addTo =  (percentPortion * 5) + percentPortion
                    let completedPercent = percentPortion * 5


                    return this.profileCompletenessStatus(completedPercent,addTo,profileIncrease,profileI)
                }
                case 1:{
                     
                    let iPercentPortions = percentPortion / 2 
                    let heavyIPortion = percentPortion - iPercentPortions 
                    let incompleteO = this.getPortionIncompleteness([{value:is_upload_resume,key:'is_upload_resume'},{value:phone,key: 'phone'},{value:last_name,key: 'last_name'}, {value:bio,key:'bio'}, {value: profession,key: 'profession'},{value: state,key: 'state'}])
                    let {portionsLen,incompleteCount,incomplete} = incompleteO 
                    let eachIPercentPortion = iPercentPortions / (portionsLen - 1)
                    // let toBecompleted = portionsLen - incompleteCount 
                    let incompletePercent = 0 
                    let completePercent = 0

                    console.log('THE INCOMPLETE DICTIONARY')
                    console.log(incompleteO)
                    console.log(incomplete)

                    if(incomplete.length <= 0){

                        let profileIncrease = `Add ${profileCompletenessItems[1]}`
                        let profileI = profileCompletenessItems[1]
                        let addTo =  percentPortion + percentPortion
                        let completedPercent = percentPortion 
    
    
                        return this.profileCompletenessStatus(completedPercent,addTo,profileIncrease,profileI)
                    }

                    if(incomplete.indexOf('is_upload_resume') >= 0 ){

                         let completedIPercentPortions = portionsLen - incompleteCount
                         completePercent = eachIPercentPortion * completedIPercentPortions 
                        //  (iPercentPortions / (portionsLen -1)) * (incompleteCount) 
                         let completeTo = completePercent + heavyIPortion  
                        //  let addTo = percentPortion - incompletePercent 

                         console.log('THE COMPLETE PERCENT')
                         console.log(completePercent)
                         console.log(completeTo)
                         console.log(completedIPercentPortions)

                        return this.profileCompletenessStatus(completePercent,completeTo,'Upload cv','is_upload_resume')

                        // let completePercent = (percentPortion * 1) - 
                    }else{


                        let completedIPercentPortions = portionsLen - (incompleteCount - 1)
                        completePercent = (eachIPercentPortion * completedIPercentPortions) + heavyIPortion
                        let addTo = completePercent + eachIPercentPortion
                      
                       

                    
                        if(incomplete.indexOf('phone') >= 0) return this.profileCompletenessStatus(completePercent,addTo,'Add phone','phone')
                        if(incomplete.indexOf('last_name') >= 0) return this.profileCompletenessStatus(completePercent,addTo,'Add surname','last_name')
                        if(incomplete.indexOf('bio') >= 0) return this.profileCompletenessStatus(completePercent,addTo,'Add bio','bio')
                        if(incomplete.indexOf('profession') >= 0) return this.profileCompletenessStatus(completePercent,addTo,'Add profession','profession')
                        if(incomplete.indexOf('state') >= 0) return this.profileCompletenessStatus(completePercent,addTo,'Add address','state')

                    }
                }
                default: return null
                   
               

                    

                    


                    
                
             

            }

         }

    }

    scrollToBottom = (e,id)=>{

        e.preventDefault()
        console.log('SCROLLTO WITH ID')
        console.log(id)
        let scrollEl = document.getElementById(id)

        if(!scrollEl) return
        if(scrollEl) scrollEl.scrollIntoView({behavior: 'smooth'})
    }

    downloadCV = ()=>{

        const {actions,userProfile} = this.props
        const {downloadUploadResume,removeNotification} = actions 
        const {profile} = userProfile
        const {personal} = profile 
        const {resume_url} = personal 
        let parts = resume_url.indexOf('//') >= 0 ? resume_url.split('//') : resume_url.split('\\') 
        const send = {fileName: parts[parts.length - 1]}
        downloadUploadResume(send)
       

    }

    componentDidUpdate(){

        const {actions,userProfile} = this.props
        const {isActionSuccessful=false, isUploadResume=false} = userProfile
        const {removeNotification} = actions 

        if(isActionSuccessful && isUploadResume) removeNotification()

    }


    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }

    componentDidMount(){

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
       
    }

    componentWillUnmount(){

        window.removeEventListener('resize', this.updateWindowDimensions);
      
     }

     getDesk = (userProfile,actions,bioEdit,contactEdit,avatarEdit,localImage,personal,UploadCV,profile_url,id,version,bio)=>{

        const {width} = this.state 
        const iconStyles = {
            display: `inline-block`,
            marginRight: `10px`,
            verticalAlign: `top`}
        const iconTextStyles = {
            display: `inline-block`,
            verticalAlign: `top`}

        return (<>

            <section className="resume__about--bio">

            {/* {this.checkProfileCompleteness()}  */}
            {bioEdit 
            ? <IdentityForm saveIdentitySubmit={this.saveIdentitySubmit.bind(this)} saveFormInput={this.saveFormInput.bind(this)} /> 
            : <><h2 className="resume__about--bio-title">
                <span>
                {
                      personal.first_name
                      ? personal.first_name 
                      : <span style={{cursor: 'pointer'}} onClick={this.allowEdit.bind(this,'bio')} id={`first_name`}>add your first name</span> 
                  }
                    </span>
                <span>
                {
                      personal.last_name 
                      ? personal.last_name 
                      : <span style={{cursor: 'pointer'}} onClick={this.allowEdit.bind(this,'bio')} id={`last_name`}>add your last name</span> 
                  }</span>
                </h2>
              <h3 className="resume__about--bio-sub">
                  {
                      personal.nick_name 
                      ? personal.nick_name 
                      : <span style={{cursor: 'pointer'}} onClick={this.allowEdit.bind(this,'bio')}>add your nick name</span> 
                  }
                  </h3></>
            }
             
             <Bio isEdit={bioEdit} allowEdit={this.allowEdit.bind(this,'bio')} scrollID={'bio'} beginSubmit={this.beginSubmit.bind(this)} actions= {actions} userProfile={userProfile} bio={bio} />

                
             <div className="resume__about--bio-text">

                <h4>Connect</h4>
                <Contact isEdit={contactEdit} allowEdit={this.allowEdit.bind(this,'contact')} actions={actions} contact={personal} userProfile={userProfile} />


             </div>

             
             
         </section>


         <section className="resume__about--profile">

        <div className="resume__about--profile-rect">

           {/* <h2>General Worker</h2> */}
           {bioEdit 
           ? <Profession saveProfessionSubmit={this.saveProfessionSubmit.bind(this)} saveFormInput={this.saveFormInput.bind(this)} /> 
           : <h2>
               {
                 personal.profession && personal.profession.trim() !== 'null'
                 ? personal.profession
                 : <span style={{cursor: 'pointer'}} onClick={this.allowEdit.bind(this,'bio')} id={'profession'}>
                      <small style={iconStyles}>
                     <i className="fa fa-lx fa-plus" style={{color: 'seagreen'}} aria-hidden="true">
                            </i>
                    </small>
                    <small style={iconTextStyles}>profession</small> 
                 </span> 

             }</h2> }

        </div>
        

        <div className="resume__about--profile-rect-2">

           {!avatarEdit 
           ? <p style={{position: 'relative'}} onClick={this.allowEdit.bind(this,'avatar')}>
              <small className="resume--edit-pen resume__about--bio-edit-pencil" style={{fontSize: '16px'}}>
               <i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true">
               </i>
               </small>
            </p>
            : null }

           <span className="resume__about--profile-circle-lg"></span>
           <span className="resume__about--profile-circle-sm"></span>
           <span className="resume__about--profile-avatar">
                   <img src="/img/250_2.jpg" alt="user cv profile photo" />
                   {/* <img src={localImage || profile_url} alt="user cv profile photo" /> */}
                   {avatarEdit 
                   ?
                       <section className="resume__about--profile-avatar-edit">
                           <Upload allowEdit={this.allowEdit.bind(this,'avatar')} sendUserAvatarUpdate={actions.sendUserAvatarUpdate} profile_url={profile_url} id={id} userProfile={userProfile} version={version} updateCaptured={this.updateCaptured.bind(this)} />
                       </section>
                   : null}
           </span>

            
        </div>

        <div>
        <p className="resume__about--profile-text">
           <button className="resume__about--profile-text-btn">HIRE ME</button>
           <button className="resume__about--profile-text-btn">
           <UploadCV text={'DOWNLOAD CV'} popUp={"downloadMulti"} />
           </button>
        </p>

        <p>
      

           
              <Share actionButton={ShareButton} width={width} />
               <a href={`http://localhost:3001/cv?r=${personal.resume_url_hash}`} target="_blank" className="resume--action-btns">

                           <span className="resume--action-btns-rect resume--action-btns-rect-2"></span>
                           <span className=" resume--action-btns-rect resume--action-btns-rect-3"></span>
                           <span className="resume--action-btns-rect resume--action-btns-rect-4">
                               <small className="resume--action-btns-rect-4-icon">
                               <i class="fa fa-eye" aria-hidden="true"></i>

                               </small>
                               <small>Preview CV</small>
                           </span>
               </a>
        </p>

        <div style={{marginTop: '100px'}}>
            {/* <button style={{backgroundColor: '#f9eb2591',display: 'inline-block',padding: '5px'}}>
               <UploadCV text={'Replace CV'} />
            </button>
            <button style={{backgroundColor: 'red',display: 'inline-block',padding: '5px'}}>
               <UploadCV text={'Delete CV'} />
            </button> */}
            <p className="resume__about--profile-text">
               <button className="resume__about--profile-text-btn" style={{color: '#ffcc66'}}>
                   <span className="triangle-bottomright bx-raised-1" style={{color: '#cc3333',fontSize: '15px',display: 'inline-block',marginRight: '8px'}}>
                       <i className="fa fa-user" style={{position: 'relative',display: 'block',top: '20px',left: '-17px'}} aria-hidden="true"></i>
                       
                   </span>
                   <UploadCV text={'REPLACE CV'} popUp={'replace'} />
               </button>
               <button className="resume__about--profile-text-btn" style={{color: 'red'}}>
                   <span className="triangle-bottomright triangle-bottomright-red bx-raised-1" style={{color: 'red',fontSize: '15px',display: 'inline-block',marginRight: '8px'}}>
                       <i className="fa fa-user" style={{position: 'relative',display: 'block',top: '20px',left: '-17px',color: '#e6a4ce'}} aria-hidden="true"></i>
                       
                   </span>
                   <UploadCV text={'DELETE CV'} popUp={'delete'}/>
               </button>
        </p>

       {personal.is_upload_resume === 1 
       ? <p className="resume__about--profile-text">
           <button className="resume__about--profile-text-btn" style={{padding: `2rem`,
backgroundColor: `#ffcc66`,fontSize: `1.4rem`,color: `black`}} onClick={()=>this.downloadCV()}>Download Uploaded CV</button>
      
         </p>
       : <p className="resume__about--profile-text">

           <UploadCV text={'UPLOAD CV'} />
  
         </p> } 
           
        </div>


        </div>
        
   </section>

         </>

        )


     }
     getMobile =(userProfile,actions,bioEdit,contactEdit,avatarEdit,localImage,personal,UploadCV,profile_url,id,version,bio) =>{

         const {width} = this.state 
         const iconStyles = {
            display: `inline-block`,
            marginRight: `10px`,
            verticalAlign: `top`}
        const iconTextStyles = {
            display: `inline-block`,
            verticalAlign: `top`}


        return (<>
        <section className="resume__about--profile">

        <div className="resume__about--profile-rect">

           {/* <h2>General Worker</h2> */}
           {bioEdit 
           ? <Profession saveProfessionSubmit={this.saveProfessionSubmit.bind(this)} saveFormInput={this.saveFormInput.bind(this)} /> 
           : <h2>
               {
                 personal.profession && personal.profession.trim() !== 'null'
                 ? personal.profession
                 : <span style={{cursor: 'pointer'}} onClick={this.allowEdit.bind(this,'bio')} id={'profession'}>
                      <small style={iconStyles}>
                     <i className="fa fa-lx fa-plus" style={{color: 'seagreen'}} aria-hidden="true">
                            </i>
                    </small>
                    <small style={iconTextStyles}>profession</small> </span> 

             }</h2> }

        </div>
        

        <div className="resume__about--profile-rect-2">

           {!avatarEdit 
           ? <p style={{position: 'relative'}} onClick={this.allowEdit.bind(this,'avatar')}>
              <small className="resume--edit-pen resume__about--bio-edit-pencil" style={{fontSize: '16px'}}>
               <i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true">
               </i>
               </small>
            </p>
            : null }

           <span className="resume__about--profile-circle-lg"></span>
           <span className="resume__about--profile-circle-sm"></span>
           <span className="resume__about--profile-avatar">
                   <img src="/img/250_2.jpg" alt="user cv profile photo" />
                   {/* <img src={localImage || profile_url} alt="user cv profile photo" /> */}
                   {avatarEdit 
                   ?
                       <section className="resume__about--profile-avatar-edit">
                           <Upload allowEdit={this.allowEdit.bind(this,'avatar')} sendUserAvatarUpdate={actions.sendUserAvatarUpdate} profile_url={profile_url} id={id} userProfile={userProfile} version={version} updateCaptured={this.updateCaptured.bind(this)} />
                       </section>
                   : null}
           </span>

            
        </div>

        <div>
        <p className="resume__about--profile-text">
           <button className="resume__about--profile-text-btn">HIRE ME</button>
           <button className="resume__about--profile-text-btn">
           <UploadCV text={'DOWNLOAD CV'} popUp={"downloadMulti"} />
           </button>
        </p>

        <p>
      

           
              <Share actionButton={ShareButton} width={width} />
               <a href={`http://localhost:3001/cv?r=${personal.resume_url_hash}`} target="_blank" className="resume--action-btns">

                           <span className="resume--action-btns-rect resume--action-btns-rect-2"></span>
                           <span className=" resume--action-btns-rect resume--action-btns-rect-3"></span>
                           <span className="resume--action-btns-rect resume--action-btns-rect-4">
                               <small className="resume--action-btns-rect-4-icon">
                               <i class="fa fa-eye" aria-hidden="true"></i>

                               </small>
                               <small>Preview CV</small>
                           </span>
               </a>
        </p>

        <div style={{marginTop: '100px'}}>
            {/* <button style={{backgroundColor: '#f9eb2591',display: 'inline-block',padding: '5px'}}>
               <UploadCV text={'Replace CV'} />
            </button>
            <button style={{backgroundColor: 'red',display: 'inline-block',padding: '5px'}}>
               <UploadCV text={'Delete CV'} />
            </button> */}
            <p className="resume__about--profile-text">
               <button className="resume__about--profile-text-btn" style={{color: '#ffcc66'}}>
                   <span className="triangle-bottomright bx-raised-1" style={{color: '#cc3333',fontSize: '15px',display: 'inline-block',marginRight: '8px'}}>
                       <i className="fa fa-user" style={{position: 'relative',display: 'block',top: '20px',left: '-17px'}} aria-hidden="true"></i>
                       
                   </span>
                   <UploadCV text={'REPLACE CV'} popUp={'replace'} />
               </button>
               <button className="resume__about--profile-text-btn" style={{color: 'red'}}>
                   <span className="triangle-bottomright triangle-bottomright-red bx-raised-1" style={{color: 'red',fontSize: '15px',display: 'inline-block',marginRight: '8px'}}>
                       <i className="fa fa-user" style={{position: 'relative',display: 'block',top: '20px',left: '-17px',color: '#e6a4ce'}} aria-hidden="true"></i>
                       
                   </span>
                   <UploadCV text={'DELETE CV'} popUp={'delete'}/>
               </button>
        </p>

       {personal.is_upload_resume === 1 
       ? <p className="resume__about--profile-text">
           <button className="resume__about--profile-text-btn" onClick={()=>this.downloadCV()}>Download Uploaded CV</button>
      
         </p>
       : <p className="resume__about--profile-text">

           <UploadCV text={'UPLOAD CV'} />
  
         </p> } 
           
        </div>


        </div>
        
   </section>

   <section className="resume__about--bio">

            {/* {this.checkProfileCompleteness()}  */}
            {bioEdit 
            ? <IdentityForm saveIdentitySubmit={this.saveIdentitySubmit.bind(this)} saveFormInput={this.saveFormInput.bind(this)} /> 
            : <><h2 className="resume__about--bio-title">
                <span>
                {
                      personal.first_name
                      ? personal.first_name 
                      : <span style={{cursor: 'pointer'}} onClick={this.allowEdit.bind(this,'bio')} id={`first_name`}>add your first name</span> 
                  }
                    </span>
                <span>
                {
                      personal.last_name 
                      ? personal.last_name 
                      : <span style={{cursor: 'pointer'}} onClick={this.allowEdit.bind(this,'bio')} id={`last_name`}>add your last name</span> 
                  }</span>
                </h2>
              <h3 className="resume__about--bio-sub">
                  {
                      personal.nick_name 
                      ? personal.nick_name 
                      : <span style={{cursor: 'pointer'}} onClick={this.allowEdit.bind(this,'bio')}>add your nick name</span> 
                  }
                  </h3></>
            }
             
             <Bio isEdit={bioEdit} allowEdit={this.allowEdit.bind(this,'bio')} scrollID={'bio'} beginSubmit={this.beginSubmit.bind(this)} actions= {actions} userProfile={userProfile} bio={bio} />

                
             <div className="resume__about--bio-text">

                <h4>Connect</h4>
                <Contact isEdit={contactEdit} allowEdit={this.allowEdit.bind(this,'contact')} actions={actions} contact={personal} userProfile={userProfile} />


             </div>

             
             
         </section>



     </>)

     }

    
    render(){


      const {state,props} = this 
      const {bioEdit,contactEdit,avatarEdit,localImage,width} = state 
      const {actions,userProfile} = props 
      const {profile} = userProfile
      const  {personal={}} = profile 
      const UploadCV = props.upload
    //   const {profileUrl} = profile
      const {profile_url='',id,version} = personal
      const bio = personal.bio ? personal.bio : '' 
    //   const version =  profile.version || personal.version
      
     
       

        return(

           
    
            <div className="resume__about">

                 {
                 
                 width <= 1200 
                 ? this.getMobile(userProfile,actions,bioEdit,contactEdit,avatarEdit,localImage,personal,UploadCV,profile_url,id,version,bio)
                 : this.getDesk(userProfile,actions,bioEdit,contactEdit,avatarEdit,localImage,personal,UploadCV,profile_url,id,version,bio)
                 
                 
                 }
               

                <strong className="clearfix"></strong>



            </div>

          
        )
    }


}


export default About







