
import React, { Component } from 'react' 
import {Link} from 'react-router-dom'
import primaryLinks from './links'
import Navigation from "../../../Common/navigation/navigation"
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import DashboardIcon from '@material-ui/icons/Dashboard';
import PersonIcon from '@material-ui/icons/Person';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Alert from './alert'


const styles = {
    root: {
     
    //   color:"#ff6633",
      fontSize: 18,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };



class Primary extends Component{
    
    constructor(props){
        super()
        this.state = {
            showMenu: false
        }
    }

    toggleMenue(e){

        e.preventDefault()
        this.getAndStyleElement(this.state.showMenu)
        this.setState({...this.state,showMenu: !this.state.showMenu})

    }

    getAndStyleElement(tog){

        const wrappedElement = document.getElementById('search');

        console.log('THE SEARCH ELEMENT')
        console.log(wrappedElement)
        if(tog){

          if(!wrappedElement) return 
          wrappedElement.style.display = 'block'
        }else{
          if(!wrappedElement) return 
          wrappedElement.style.display = 'none'
        }
       
      }

    render(){

        const {state,props,toggleMenue} = this 
        const {path,width} = props
        const Upload = props.upload
        const {showMenu} = state 
        let isJos =  path === '/jobs' ? true : false 
        let isJosMob = isJos && (width <= 1200) ? true : false 
        let showMenuClasses = showMenu 
         ? isJosMob ? 'header__nav--cont header__nav--cont-jo header__nav--cont-adjust' :'header__nav--cont header__nav--cont-adjust'
         : isJosMob ? 'header__nav--cont header__nav--cont-jo' :'header__nav--cont' 
        let navItemClasses = isJosMob ? 'header__nav--item header__nav--item-jo' : 'header__nav--item' 
        let closeClases = isJosMob ? 'header__nav--item-close header__nav--item-close-jo' : 'header__nav--item-close'
        console.log('the upload prop')
        console.log(Upload)
       

        //let jos = path === '/jobs' ?  width > 1200 ? true : false : true 


    return(

         <section>
            <div className={showMenuClasses}>

                <span className={closeClases} onClick={toggleMenue.bind(this)}>
                    <Icon>
                        <CloseIcon style={styles.root} className="fg-primary" />
                    </Icon>
                </span>

                { props.width > 768 && !isJosMob ? <li className="header__nav--item">

                    <Link to='/access' className="header__nav--item-link">
                    <span style={{top: .3+"rem"}} className="d-inline-block mg-right-fd-xxx-tn pos-rel">
                        <Icon>
                            <PersonIcon style={styles.root} className="fg-primary" />
                        </Icon>
                        </span>
                        <span className="header__nav--item-account">Admin</span>
                    </Link>
                

                </li>
                : null}
                

            </div>

            { props.width <= 768 || isJosMob ? <li className="header__nav--item header__nav--item-icon">

                    <Link to='/access' className="header__nav--item-link">
                    <span>
                        <Icon>
                            <PersonIcon style={styles.root} className="fg-primary" />
                        </Icon>
                        </span>
                        
                    </Link>
                

                </li>
                : null}

                {props.width <= 768 || isJosMob ? 
                
                    <span className="header__nav--menu" onClick={toggleMenue.bind(this)}>
                        <Icon>
                            <MenuIcon style={styles.root} className="fg-primary" />
                        </Icon>
                    </span>
              
                : null}


         </section>
    )
    }

}


export default withStyles(styles)(Primary)