import React, { Component } from 'react' 
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import Notifications from '@material-ui/icons/Notifications';
import SortIcon from '@material-ui/icons/Sort';
import Dropdown from './Dropdown/dropdown'
import Alert from './alert'

import Ads from './ads/ads'
import Tags from './tags/tags'
import Join from './join/join'
import { isFetching } from '../../../../Humanoid/props';
// import SimpleBar from 'simplebar'
// import 'simplebar/dist/simplebar.css';
// import { Scrollbars } from 'react-custom-scrollbars';

const styles = {
    root: {
     
      color:"#f4f2f4",
      fontSize: 20,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };



  function GetSortFilter(props){


    const {displayDropDown,currentList,hideDropDown,showDropDown,dropDownID,closePopup=()=>null} = props

    return(


          <>
                  <section className="job__jobs--lt-sorts-i recent" onClick={()=>displayDropDown('recent')}>
                                        
                    <p className="hearx__products--filter-i-dropdown-i recent" >SortBy</p>
                    {
                    showDropDown && dropDownID === 'recent' ? <Dropdown list={currentList} hideDropDown={hideDropDown} displayDropDown={displayDropDown} hidePopup={closePopup} /> : null

                  }
                  </section>
                  
                
                  <section className="job__jobs--lt-sorts-i pages" onClick={(e)=>displayDropDown('pages')}>
                    <p className="hearx__products--filter-i-dropdown-i pages" >Page count</p>
                    {
                    showDropDown && dropDownID === 'pages' ? <Dropdown list={currentList} hideDropDown={hideDropDown} displayDropDown={displayDropDown} hidePopup={closePopup} /> : null

                  }
                  </section>
          </>
    

    )

  }
  function CustomButton(props){

    const {classes={},openPopup =()=>null} = props 
    const {root=''} = classes

    return(

      <button className="job__jobs--lt-mobile-card-rect-4-sub-btn" onClick={()=>openPopup()}>
          <span className="job__jobs--lt-mobile-card-rect-4-sub-btn-rect"></span>
          <span className="job__jobs--lt-mobile-card-rect-4-sub-btn-rect-2">
              <small><Icon><SortIcon className={root} /> </Icon></small>
              </span> 

      </button>

    )
  }



class LeftFilter extends Component{
    
    constructor(props){


        super(props)

        this.state = {
          recent: [
            {
                id: 0,
                title: 'Relevance',
                selected: false,
                key: 'location',
                to: '/access',
                value: 'relevance'
            },
            {
              id: 1,
              title: 'Date',
              selected: false,
              key: 'location',
              to: '/jobs',
              value: 'date'
            },
            {
              id: 2,
              title: 'Salary',
              selected: false,
              key: 'location',
              to: '/postjob',
              value: 'salary'

            },
            // {
            //   id: 3,
            //   title: 'Past two weeks',
            //   selected: false,
            //   key: 'location',
            //   to: '/alert',
            //   value: 336
            // }
           
          ],
          pages: [
            {
                id: 0,
                title: '15 per Page',
                selected: false,
                key: 'location',
                to: '/access',
                value: 15
            },
            {
              id: 1,
              title: '20 per Page',
              selected: false,
              key: 'location',
              to: '/jobs',
              value: 20
            },
            {
              id: 2,
              title: '25 per Page',
              selected: false,
              key: 'location',
              to: '/postjob',
              value: 25

            }
           
          ],
          showDropDown: false,
          dropDownID: ''
        }

      }



      toggleSelectedRecent(id, key){

        let temp = this.state[key]
        temp[id].selected = !temp[id].selected
        this.setState({
          [key]: temp
        })

      }

      toggleSelectedPages(id, key){

        let temp = this.state[key]
        temp[id].selected = !temp[id].selected
        this.setState({
          [key]: temp
        })

      }

      displayDropDown = (filter)=>{

        console.log('THE FILTER GETS A CLICK AND IT RUNS')
        console.log(filter)

        this.setState({...this.state,showDropDown:true,dropDownID: filter})
        // filter === 'price' ? this.priceFilter(filterType) : this.nameFilter(filterType)

    }

    hideDropDown = (e,i='',doAfter=()=>null,afterHideAction=()=>null)=>{

      console.log('THE VALUE I HIDEDROPDOWN')
      console.log(i)
      e.stopPropagation() 
      e.preventDefault()
      if(i){
      	  
      	  const {props,state} = this 
      	  const {actions} = props 
      	  const {crudJobFilter,saveJobSearchParams} = actions 
      	  let id = state.dropDownID
          let opt = state[id] 
          let value = opt[i].value
          console.log('THE OPT VALUE')
          console.log(opt)
      	  
      	  
      	   
      	   this.setState({...this.state,showDropDown:false,dropDownID: ''},()=>{
      	   	
      	   	  if(id === 'recent'){
      	   	  	
      	   	  
      	   	  saveJobSearchParams({
      	   	  	
      	   	  	 sort: value,
      	   	  	 fetchJobs: true
      	   	  	
      	   	  }) 
      	   	  }else{
                 
                console.log('the opt value in eslse setstate')
                console.log(value)
                saveJobSearchParams({
                  pageLimit: value,
      	   	  	 	fetchJobs: true
      	   	  	 })
      	   	  	
      	   	  }
               doAfter()
      	   	  
      	   	
      	   })
      	  
      	 
      	  
      	
      }else{
      	
        // if(!afterHideAction) return this.setState({...this.state,showDropDown:false,dropDownID: ''})

        this.setState({...this.state,showDropDown:false,dropDownID: ''},
        ()=>{

           afterHideAction()
        })
      	 
      	
      }
      

    }


    render(){

        const {displayDropDown,state,hideDropDown,props} = this 
        const {dropDownID,showDropDown} = state 
        const currentList =  state[dropDownID] 
        const {search,auth,actions,isPosts,isScrolled,width,isBottom,upload,userProfile={},classes,listing} = props 
        const {isFetching=false} = listing
        const Modal = props.modal 
        const self = this

        // let ltClasses = !isPosts || (width <= 1200 || !isScrolled) ? 'job__jobs--lt' : 'job__jobs--lt job__jobs--lt-sticky'
        // let ltClasses = !isPosts || isBottom ? 'job__jobs--lt' : 'job__jobs--lt job__jobs--lt-sticky'
        let ltClasses = (!isPosts || isBottom) && width > 800 ? 'job__jobs--lt' : 'job__jobs--lt job__jobs--lt-sticky'
        const contClass = width <= 800 ? 'job__jobs--lt-sorts job__jobs--lt-mobile-containers' : 'job__jobs--lt-sorts'
        const componentProps = {
                                currentList:currentList, 
                                displayDropDown:displayDropDown.bind(self),
                                hideDropDown:hideDropDown.bind(self),
                                dropDownID:dropDownID,
                                showDropDown:showDropDown,
                              }
        // const mobileStyles = width <= 800 ? {

        //   display: 'inline-block',
        //   backgroundColor: 'transpanrent',
        //   width: '90px',
        //   margin: 0,
        //   padding: 0
        // } : null

        if((!isPosts || isFetching) && width <= 800)  return null


        console.log('THE COMPOENENT PROPS')
        console.log(componentProps)
        return(


           

          
           <section className={ltClasses} id='left-filter'>


                
                <div className={contClass}>
                    {width > 800 ? <h2>Filter By</h2> : null}
                    <section className="job__jobs--lt-sorts-icont">
                      
                    {
                      width > 800 
                      ? <GetSortFilter 
                         {...componentProps} 
                        /> 
                      : <Modal popUp={GetSortFilter} button={CustomButton} buttonProps={{classes: classes}}  componentProps={componentProps} />

                    }
                        

                       

                    </section>
                </div>

                {isPosts && <Alert search={search} actions={actions} width={width} auth={auth} userProfile={userProfile} />} 
                
                <Tags modal={props.modal} search={search} actions={actions} width={width} />
                <Join upload={upload} width={width} />
                {/* <Ads /> */}
                
             
           </section>
        )
    }

}


export default withStyles(styles)(LeftFilter)