
import React, {Component} from 'react' 
import FormControl from '../formcontrol'
// import ProgressBarLinear from '../progressbarlinear'
import { toast } from 'react-toastify';
import Accordion from './accordion'
// import ReCAPTCHA from "react-google-recaptcha"


class Bio extends  Component{


    constructor(props){

        super()

    }




    getInstitutions = (e,i)=>{

     
        let card = i === 0 ? '' : `resume__education--card-${i+1}` 
        let toPresent = !e.to ? `${e.from}-` : `${e.from}-${e.to}`
        let pos = i+1
        let institution = (

    

            <section className={`resume__education--card ${card}`} key={i}>

                <div className="resume__education--card-pointer">
                    <small>{pos}</small>

                </div>

                <div className="resume__education--card-pointee">

                    <h5 className="resume__education--card-pointee-period">{toPresent}</h5>
                    <h6 className="resume__education--card-pointee-employer">{e.institution}</h6>
                    <span className="resume__education--card-pointee-role">{e.qualification}</span>
                    <p className="resume__education--card-pointee-description">
                       {e.majors}
                    </p>

                </div>

                

            </section>

            
        )
        

        return institution



    
    }
 

               

   

    render(){

        
         const {props,state} = this 
        //  const {bioText} = state
         const {language,setIsEditing,index,isEditing,isPortAdd=false,removeItem,width} = props 
         const editIconStyles  = isEditing > 0 || isPortAdd ? {opacity: '.4',pointerEvents: 'none'}  : {opacity: 1,pointerEvents: 'auto'} 
        //  const editContStyles = isEdit ? {boxShadow: '-15px 10px 25px 5px rgba(0, 0, 0, 0.04);'} : {boxShadow: 'none'}
        // const {push} = actions
        // const {message = ''} = auth 
        // console.log('tHE MESSAGE')
        // console.log(message)
        // console.log(message.trim() !== '')


        return(

                    

                        
                       
                  
                        
                        
   
                          
   
                                //    <li className="resume__curated--card-pointee-list-i">
                                //        <span>{language.name}</span>
                                //        <span>{language.name}</span>
                                //        <span><a href={language.link} target="_blank">View</a></span>
                                    //    <span>
                                    //        <small style={editIconStyles} onClick={()=>{setIsEditing(index+1)}}><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                               
                                    //        <small style={editIconStyles}><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                                    //    </span>
                                       
                                //    </li>

                                    // <li className="resume__curated--card-pointee-list-i">
                                    //     <span>{language.language}</span>
                                    //     <span>{language.l_speak}</span>
                                    //     <span>{language.l_write}</span>
                                    //     <span>{language.l_read}</span>
                                    //     <span>
                                    //        <small style={editIconStyles} onClick={()=>{setIsEditing(index+1)}}><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                               
                                    //        <small style={editIconStyles} onClick={(e)=>{removeItem(e,language.id)}}><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                                    //    </span>
                                    // </li>
                                   
                                    <>

                                      {width <= 1200
                                     
                                    ? <li><Accordion name={language.language}>

                                        
                                            <ul className="resume--list">
                                                <li className="resume--list-li">
                                                    <span className="resume--list-li-title">Speak:</span>
                                                    <span className="resume--list-li-value">{language.l_speak}</span>
                                                </li>
                                                <li className="resume--list-li">
                                                    <span className="resume--list-li-title">Read:</span>
                                                    <span className="resume--list-li-value">{language.l_read}</span>
                                                </li>
                                                <li className="resume--list-li">
                                                    <span className="resume--list-li-title">Write:</span>
                                                    <span className="resume--list-li-value">{language.l_write}</span>
                                                </li>
                                            </ul>
                                        


                                    </Accordion></li>
                                
                                   : <li className="resume__curated--card-pointee-list-i">
                                        <span>{language.language}</span>
                                        <span>{language.l_speak}</span>
                                        <span>{language.l_write}</span>
                                        <span>{language.l_read}</span>
                                        <span>
                                           <small style={editIconStyles} onClick={()=>{setIsEditing(index+1)}}><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                               
                                           <small style={editIconStyles} onClick={(e)=>{removeItem(e,language.id)}}><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                                       </span>
                                    </li>
                                    
                                }

                                </>
                                
                                
                                   
   
   
                            
                      


             
           
        )
    }




}

export default Bio