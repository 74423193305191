
import React from 'react' 
import Root from './components/Root/component'




export default ()=>{

    return (

       <Root />
    )

}



