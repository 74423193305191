
import app from './app'
import init from './init'


export default function(){

     console.log('THE APP IS BOOTSTRAPPING')
     init(app)

}



