
import React,{Component} from 'react'
import Benefits from './benefits/benefits' 



class Employer extends Component{
    
    
    render(){

        return(

            <div className="grid-row">

                
                <div  className="home__jobseeker--container">
                    <h2 className="home__jobseeker--title">Jobseeker benefits</h2>
                     <Benefits />
                  
                 
                </div>
               
                

            </div>
        )
    }

}


export default Employer