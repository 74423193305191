
import React,{Component} from 'react'
import Us from './us/us' 



class Bio extends Component{
    
    
    render(){

        return(

           

                
                <div  className="about__us">
                    
                     <Us />
                </div>
               
                

           
        )
    }

}


export default Bio