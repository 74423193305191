
import React,{Component} from 'react'
import Launch from './launch/launch' 



class AppLaunch extends Component{
    
    
    render(){

        const {launch,actions} = this.props

        return(

            <div className="grid-row">

                
                <div  className="mg-bottom-fd-md">
                    
                     <Launch launch={launch} actions={actions}  />
                </div>
                
               
                

            </div>
        )
    }

}


export default AppLaunch