
import React, {Component} from 'react' 
import CuratedPortfolioInfo from './cuportinfo'

// import ReCAPTCHA from "react-google-recaptcha"


class Bio extends  Component{


    constructor(props){

        super()

    }




    getPortfolio = (e,i)=>{


            return <CuratedPortfolioInfo portfolio={e} key={i}  index={i} />
        


    
    }



               

   

    render(){

        
         const {props} = this 
        //  const {bioText} = state
         const { portfolio} = props 
        
        //  const editContStyles = isEdit ? {boxShadow: '-15px 10px 25px 5px rgba(0, 0, 0, 0.04);'} : {boxShadow: 'none'}
        // const {push} = actions
        // const {message = ''} = auth 
        // console.log('tHE MESSAGE')
        // console.log(message)
        // console.log(message.trim() !== '')

        //if(isPortfolioAdd) return <CuratedPortfolioForm isAdding={true} /> 

        

        return(

                    

                        
                       
                        // <>{ portfolio.map((e,i)=> this.getPortfolios(e,i))}</>

                        <section className="resume__curated--card">

                        <div className="resume__curated--card-pointer"></div>
   
                        <div className="resume__curated--card-pointee">
   
                           <h5 className="resume__curated--card-pointee-period">Portfolio</h5>
                           <p className="resume__curated--card-pointee-legend">
                                   <span>Name</span>
                                   <span>Description</span>
                                   <span>Role</span>
                                   <span>Link</span>
                                   <span>Actions</span>
                               
                            </p>
                            <ul className="resume__curated--card-pointee-list">

                            {portfolio.map((p,i)=> this.getPortfolio(p,i))} 
                           

                            </ul>
                           
                          
   
                           </div>
                        </section>

           
             
             
           
        )
    }




}

export default Bio