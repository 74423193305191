
import React from 'react' 
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import {Link} from 'react-router-dom'
import { push } from '../../../ReduxFirstNavigator/actions'


const styles = {
    root: {
     
      color:"#999",
      fontSize: 30,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };




function Jobseeker(props) {
    
    const {width} = props
    // const openPage = (e)=>{

    //     e.preventDefault()
    //     props.actions.push('jobs?jq=all')
    // }
    return(

           <section className="home__who">

               <div className="home__who--leg">

                   <section className="home__who--leg-title">
                       <span>Who are our players</span> <small className="fg-secondary font-fd-tn">?</small>
                   </section>

                   {width <= 1200 
                     ? 
                     <section className="home__who--pointer">
                         <small className="home__who--pointer-span fg-red font-fd-x-tn d-inline-block pos-rel left-offset-fl-xxx-sm top-offset-fl-x-sm">
                            <Icon> <ArrowForwardIcon className={props.classes.fontSize} /></Icon>
                        </small>
                     </section>
                     : null

                     }

               </div>
               
               <div className="home__who--players">
                    
                    <section className="home__who--players-cand">

                        <h2>Job seekers</h2>
                        <p>
                        Searching for jobs is a demanding task, and sometimes a painful experience. Our aim is to implement technology that makes the job search experience convenient, fun, enjoyable, and timely for jobseekers. 

                        </p>
                        <Link to="/jobs?jq=all" className="link" style={{cursor: 'pointer'}}>

                            <button>
                                Find a job
                            </button>

                        </Link>


                    </section>
                    <section className="home__who--players-hire">

                        <h2>Employers</h2>

                        <p>
                        Finding desired talent is a resource-intensive task for businesses. Our goal is to help establish better and robust relationships between employers and jobseekers to help employers find skilled workforce quicker and with less resources.

                        </p>

                        <Link to="/post-job" className="link" style={{cursor: 'pointer'}}>

                            <button >
                                Post a job
                            </button>

                        </Link>


                    </section>
                    {width > 1200 
                            ?<section className="home__who--pointer">

                        <small className="home__who--pointer-span fg-red font-fd-x-tn d-inline-block pos-rel left-offset-fl-xxx-sm top-offset-fl-x-sm">
                                <Icon> <ArrowForwardIcon className={props.classes.fontSize} />
                                    {/* {width > 1200 
                                        ?  <ArrowForwardIcon className={props.classes.fontSize} />
                                        :  <ArrowForwardIcon className={props.classes.fontSize} />
                                    }
                                    */}
                                </Icon>
                            </small>
                            </section>
                            : null
                        }
                     
                    <strong className="clearfix" />
               </div>
               <strong className="clearfix" />
           </section>
    )

}


export default withStyles(styles)(Jobseeker)