
import React, { Component } from 'react' 
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { toast } from 'react-toastify';
import FormControl from '../../formcontrol'



const styles = {
    root: {
     
   
      fontSize: 40,
     
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };


 class Form extends Component{
    
    constructor(){
        super()

        this.state = {
            form : {

                email:{
                    
                    control: 'input',
                    value:'',
                    validation: {
                        isRequiredFormat:{
                            isRequired: true,
                            isEmail: true
                            
                        },
                
                    },
                    isValid: false,
                    isTouched: false,
                    validationMessage: '',
                    validationStyles: {
                        position: 'bottom'
                    },
                    config: {
                        
                        name: "email",
                        type: 'text',
                        placeholder: 'alertme@mail.com',
                        // disabled: false
                        
                    }
                    
                },
               
            }
        };
    }


    updator = (upControl)=>{
        
        console.log('THE UPDATE CONTROL')
        console.log(upControl)
        let newForm ={...this.state.form} 
        let updateControl = {...newForm[upControl.id]}
        updateControl.value = upControl.e.target.value 

    
        
        if(!(updateControl.validation.isPlaceholder && updateControl.value.trim() === '')){

            if(upControl.blur){
            
                let validData = this.controlValidate(updateControl) 
                
                updateControl.isValid = validData[0] 
                updateControl.validationMessage = validData[1]

                if(!updateControl.isValid){

                    for(let k in newForm){ 


                        if(newForm[k].config.disabled === false && k.trim() !== upControl.id.trim()){
                            newForm[k].config.disabled = true
                        }
                    }
                }else{

                    for(let k in newForm){ 

                        if(newForm[k].config.disabled === true){
                            newForm[k].config.disabled = false
                        }
                    }

                }
                
            }

        }
    
        updateControl.isTouched = upControl.blur
        newForm[upControl.id] = updateControl
        
        this.setState({
            form: newForm
            
        })


    } 

    controlValidate = (validateControl)=>{
        
        let validData = [true,''] 

        if(validateControl.validation.isRequired){ 
        
            let valid = validateControl.value.trim() !== ''
            let message = ''
            if(!valid){
                message = `${validateControl.config.name} is a required field `
            }

            validData = !valid ? [valid,message] : validData 
            return validData
            
        }
        if(validateControl.validation.isRequiredFormat){
            
            let value =  validateControl.value.trim() 
            let specialCharactersRegex = '[`!@#$%^&*()_+\-=\[\]]{};:"\\|,.<>\/?~]'
            let charsArray = Array.from(specialCharactersRegex)
                charsArray.push("'")
            let containsSpecialCars = charsArray.some((v)=>value.indexOf(v) >= 0)
            let pieces = value.split(/\W+/) 
            let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            console.log('SPECIAL CHARACTER TEST')
            console.log(charsArray)
            console.log(containsSpecialCars)
            console.log(value)
            console.log(specialCharactersRegex)
            let isRequiredFormat =  validateControl.validation.isRequiredFormat 
            if(isRequiredFormat.specialCharacters && containsSpecialCars === true ) return [false,`special characters are not allowed as username`]
            if(isRequiredFormat.minLetters && (value.length < isRequiredFormat.minLetters) ) return [false,`${validateControl.config.name} should be atleast ${isRequiredFormat.minLetters} letters long`]
            if(isRequiredFormat.minPieces && (pieces.length < isRequiredFormat.minPieces) ) return [false,`${validateControl.config.name} should be atleast ${isRequiredFormat.minPieces} words`] 
            if(isRequiredFormat.maxPieces && (pieces.length > isRequiredFormat.maxPieces) ) return [false,`${validateControl.config.name} should be atmost ${isRequiredFormat.maxPieces} words`] 
            if(isRequiredFormat.isNumber && isNaN(value) ) return [false,`${validateControl.config.name} should be a number with no spaces`] 
            if(isRequiredFormat.isLen && (value.length !== isRequiredFormat.isLen) ) return [false,`${validateControl.config.name} should be a total of ${isRequiredFormat.isLen} numbers`] 
            if(isRequiredFormat.isEmail && emailRegex.test(value) !== true) return [false,`${validateControl.config.name} Should be a valid email`]
        
                
        
        }
        
        return validData

    }
   

    submitForm = (e)=>{
            
        e.preventDefault() 

        
        const {actions} = this.props
        const {sendLaunchNotification} = actions 
        let submittData = {} 
        let formIsValid = true 
        let {form} = this.state 
        let {notify} = this
        
        
        
        for(let k in form){
            
            console.log('THE SUBMIT CONTROL')
            console.log(form[k])
            if(form[k].validation && form[k].validation.isRequiredFormat){

                console.log('THE CONTROL REQUIRES VALIDATION')

                if(form[k].isValid === false){

                    console.log('CONTROL:',k, ' is not valid')
                    formIsValid = false 
                    
                    console.log(notify)
                    notify({message:`${k} must provided before you can continue.`,type: 'warning',className: 'notify-warn'})
                    break


                }else{
 
                    
                        submittData[k] = form[k].value

                   
                
                }

            }
        
        } 

        if(!formIsValid) return


        console.log('THE SUBMITDATA')
        console.log(submittData)
        submittData.name = this.extractNameFromEmail(submittData.email)
        submittData['launchType'] = 'jobpost'
        sendLaunchNotification(submittData) 
        
         
        

    }

    extractNameFromEmail(email){

        return  email.split("@")[0]
    }

    notify = (config) => {

        
        console.log('THE TOAST CONFIG')
        console.log(config)
        const {message} = config || ''
        const type = config.type || 'info'
        toast[type](message,
            {position: toast.POSITION.BOTTOM_LEFT,...config});
    
    }


   
  
    render(){

        const {props} = this 
        const {launch} = props 
        const {isFetching=false} = launch 

        return(

        
            <section className="po__send">


                   
                        <form className="form pd-bottom-fd-lg ">


                          


                           
                                <section className="po__form-i">

                                    <span className="d-block mg-bottom-fd-tn font-fd-xx-tn">Email</span>
                                    <FormControl 

                                        styles={{child:'bx-raised po__form-text',error: ''}}
                                        id = 'email'
                                        controlData={this.state.form.email}
                                        change={(control)=>this.updator(control)}
                                        placeHolder={'notifyme@mail.com'}
                                    
                                        

                                    />
                                </section>
                            
                        

                            {isFetching
                                ?  <section className="po__form-submit">
                                    <div className="home__search--circle top-offset-fl-sm text-align-center" style={{backgroundColor: 'white'}}>
                                    <section className="huloader huloader-co">
                                    <div className="huloader__loading huloader__loading-co">
                                        <div></div>
                                        <div></div>
                                    </div>  
                                </section></div></section>
                            : <div className="po__form-submit">

                                <input type="submit" onClick={(e)=>this.submitForm(e)} className="cursor-pointer font-fd-xx-tn bg-primary fg-light mg-top-fl-x-sm  d-inline-block pd-fd-bt bd-none" value="Send"/>
        
                              </div>
                        }





                </form>
    
            </section>
        
        )
    }

}


export default withStyles(styles)(Form)





//  function Form (){
    
   
  

//     return(

      
//            <section className="po__send">

//                 <form className="form pd-bottom-fd-lg ">

//                 <p className="po__form-i">

//                     <span className="d-block mg-bottom-fd-tn font-fd-xx-tn">Email</span>
//                     <input type="email" className="bx-raised po__form-text" placeholder="launchnotify@yourmail.com"/>

//                 </p>

                
//                 <strong className="clearfix" />
                



//                 <p className="po__form-submit">

//                     <input type="submit" className="cursor-pointer font-fd-xx-tn bg-primary fg-light mg-top-fl-x-sm  d-inline-block pd-fd-bt bd-none" value="Send"/>

//                 </p>


//                 </form>
              
//            </section>
      
//     )

// }


// export default Form