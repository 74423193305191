
export const SAVE_CHAT_MESSAGE = "SAVE_CHAT_MESSAGE"
export const SAVE_INITIAL_CHAT_MESSAGE = "SAVE_INITIAL_CHAT_MESSAGE"
export const SAVE_CHAT_MESSAGE_WITH = "SAVE_CHAT_MESSAGE_WITH"
export const SAVE_WITH_NO_MESSAGE = "SAVE_WITH_NO_MESSAGE"
export const SAVE_HOST_MESSAGE = "SAVE_HOST_MESSAGE"
export const SAVE_CHAT_TEXT = "SAVE_CHAT_TEXT"
export const CLEAR_CHAT_MESSAGES = "CLEAR_CHAT_MESSAGES"
export const SET_IS_COMPLETE = "SET_IS_COMPLETE"
export const SET_DATA_CAPTURE_COMPLETE = "SET_DATA_CAPTURE_COMPLETE"
export const SET_NEXT_QUESTIONR = "SET_NEXT_QUESTIONR"
export const SET_CURRENT_QUESTIONS = "SET_CURRENT_QUESTIONS"
export const SET_HUMANOID_ANSWER = "SET_HUMANOID_ANSWER"
export const UNSET_HUMANOID_ANSWER = "UNSET_HUMANOID_ANSWER"
export const CHANGE_ACTIVE_STATUS = "CHANGE_ACTIVE_STATUS"
export const SET_ACTIVE_ACTIVITY = "SET_ACTIVE_ACTIVITY"
export const SET_IS_RETURN_USER = "SET_IS_RETURN_USER"
export const SET_PROCESSOR = "SET_PROCESSOR"
export const SET_PROFILE_DYNAMIC_QUESTION = "SET_PROFILE_DYNAMIC_QUESTION"
export const CLOSE_HUMANOID_CHAT = "CLOSE_HUMANOID_CHAT"
export const SET_CURRENT_PROFILE_FEATURE = "SET_CURRENT_PROFILE_FEATURE"
export const SET_CURRENT_PROFILE_FEATURE_RECORD = "SET_CURRENT_PROFILE_FEATURE_RECORD" 
export const UPDATE_PROFILE_ITEMS = "UPDATE_PROFILE_ITEMS"
export const SET_FILLER_ITEM = "SET_FILLER_ITEM" 
export const SET_UPDATE_TYPE = "SET_UPDATE_TYPE" 
export const SET_HUMANOID_ACTION_TYPE = "SET_HUMANOID_ACTION_TYPE" 


export const SEND_USER_BUILT_RESUME_SUCCESS = "SEND_USER_BUILT_RESUME_SUCCESS"
export const SEND_USER_BUILT_RESUME_FAILURE = "SEND_USER_BUILT_RESUME_FAILURE"
export const SEND_USER_BUILT_RESUME_REQUEST = "SEND_USER_BUILT_RESUME_REQUEST"

export const SEND_USER_BUILT_RESUME_FACET_SUCCESS = "SEND_USER_BUILT_RESUME_FACET_SUCCESS"
export const SEND_USER_BUILT_RESUME_FACET_FAILURE = "SEND_USER_BUILT_RESUME_FACET_FAILURE"
export const SEND_USER_BUILT_RESUME_FACET_REQUEST = "SEND_USER_BUILT_RESUME_FACET_REQUEST"
// export const FETCH_DASH_STATS_SUCCESS = "FETCH_DASH_STATS_SUCCESS"
// export const FETCH_DASH_STATS_FAILURE = "FETCH_DASH_STATS_FAILURE"



