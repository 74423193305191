
import React,{Component} from 'react'
import Dash from './dash/dash' 



class Jobdash extends Component{
    
    
    render(){

        return(

            <div className="grid-row">

                
                <div  className="home__jobdash--container">
                    <h2 className="home__jobdash--conv">More Convenience for you</h2>
                     <Dash actions={this.props.actions} />
                  
                 
                </div>
               
                

            </div>
        )
    }

}


export default Jobdash