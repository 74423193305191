
import React, {Component} from 'react' 
import FormControl from '../formcontrol'
// import ProgressBarLinear from '../progressbarlinear'
import { toast } from 'react-toastify';
// import ReCAPTCHA from "react-google-recaptcha"


class Bio extends  Component{


    constructor(props){

        super()

    }




    getInstitutions = (e,i)=>{


        // console.log('THE CERTIFICATE')
        // console.log(certificate)
        // let institutionCertificate = certificate.filter((c,ci)=>{

        //      console.log(c)
        //      console.log(e)
        //      console.log(c.id === e.certificate_id)
        //      if(c.id === e.certificate_id) return c
        //     }) 
        // console.log('THE INSTITUTE MATCH ID')
        // console.log(institutionCertificate)
        // let instiDocument = {...institutionCertificate[0]}
        // console.log(instiDocument)
        let card = i === 0 ? '' : `resume__education--card-${i+1}` 
        let toPresent = !e.to_date ? `${new Date(e.from_date).getFullYear()}-Present` : `${new Date(e.from_date).getFullYear()}-${new Date(e.to_date).getFullYear()}`
        let pos = i+1
        let institution = (

    

            <section className={`resume__education--card ${card}`} key={i}>

                <div className="resume__education--card-pointer">
                    <small>{pos}</small>

                </div>

                <div className="resume__education--card-pointee">

                    <h5 className="resume__education--card-pointee-period">{toPresent}</h5>
                    <h6 className="resume__education--card-pointee-employer">{e.institution}</h6>
                    <span className="resume__education--card-pointee-role">{e.qualification}</span>
                    <p className="resume__education--card-pointee-description">
                       {e.majors}
                    </p>

                </div>

            </section>

            
        )
        

        return institution



    
    }
 

               

   

    render(){

        
         const {props,state} = this 
        //  const {bioText} = state
         const {education} = props 
        //  const editContStyles = isEdit ? {boxShadow: '-15px 10px 25px 5px rgba(0, 0, 0, 0.04);'} : {boxShadow: 'none'}
        // const {push} = actions
        // const {message = ''} = auth 
        // console.log('tHE MESSAGE')
        // console.log(message)
        // console.log(message.trim() !== '')


        return(

                    

                        
                       
                        <>{education.map((e,i)=> this.getInstitutions(e,i))}</>

           
             
             
           
        )
    }




}

export default Bio