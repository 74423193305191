
import {showIO} from './view'
import * as endActions from './actions' 
import * as initiators from './initiator'
import {storeAnswers} from './answers'


export const askQuestion = (self=null,qs,index=0)=>{

          

  
        console.log('ASKING QUESTIONS')
        console.log(qs)
        const {props} = self 
        const {actions} = props
        //const {updateTypingStatus} = actions

        const {question={},multiple=false} = qs 

        if(multiple){

            showIO(self,{multiText:true,question: question})
            //updateTypingStatus(false)

        }else{


            showIO(self,{question: question},index)
            //updateTypingStatus(false)
 

        }
   
}


export const readResponse = (self=null,response='')=>{

    const {props} = self 
    const {humanoid,actions} = props 
    const {currentQuestion={},currentQuestions=[],currentQuestionIndex=0,currentQuestionr,questionas} = humanoid 
    const questionr = questionas[currentQuestionr] 
    // const {setCurrentQuestions} = actions
    // const currentQuestionrQuestions = questionr.questions
    // const {endAction} = questionr 
    const {expectedFormat=null,expected=null} = currentQuestion 

    if(expected && expectedFormat){

        const isVal = isValid(response,expectedFormat)
        
        if(isVal) return showIO(self,{responseText: response,enforce: true})
        if(!isExpected(response,expected)) return showIO(self,{enforce: true,responseText:'Your response is not in the required format'})
        storeAnswers(self,response)
        nextQuestion(self,response)

    }else{

        storeAnswers(self,response)
        nextQuestion(self,response)
    }
  

 
 }


 export const isValid = (validatee,expectedFormat)=>{


     const validate = validator(expectedFormat,validatee)

     if(!validate[0]){

         return {text: validate[1]}
     }else{

        return false

     }
   
  
  }


export const validator = function(isRequiredFormat={}, validateText=''){


   
            
        let value = validateText.trim() 
        let specialCharactersRegex = '[`!@#$%^&*()_+\-=\[\]]{};:"\\|,.<>\/?~]'
        let charsArray = Array.from(specialCharactersRegex)
            charsArray.push("'")
        let containsSpecialCars = charsArray.some((v)=>value.indexOf(v) >= 0)
        let pieces = value.split(/\W+/) 
        let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        // console.log('SPECIAL CHARACTER TEST')
        // console.log(charsArray)
        // console.log(containsSpecialCars)
        // console.log(value)
        // console.log(specialCharactersRegex)
        // let isRequiredFormat =  validateControl.validation.isRequiredFormat 
        if(isRequiredFormat.specialCharacters && containsSpecialCars === true ) return [false,`special characters are not allowed as username`]
        if(isRequiredFormat.minLetters && (value.length < isRequiredFormat.minLetters) ) return [false,`Your response should be atleast ${isRequiredFormat.minLetters} letters long`]
        if(isRequiredFormat.minPieces && (pieces.length < isRequiredFormat.minPieces) ) return [false,`Your response should be atleast ${isRequiredFormat.minPieces} words`] 
        if(isRequiredFormat.maxPieces && (pieces.length > isRequiredFormat.maxPieces) ) return [false,`Your response should be atmost ${isRequiredFormat.maxPieces} words`] 
        if(isRequiredFormat.isNumber && isNaN(value) ) return [false,`Your response should be a number with no spaces`] 
        if(isRequiredFormat.isLen && (value.length !== isRequiredFormat.isLen) ) return [false,`Your response should be a total of ${isRequiredFormat.isLen} numbers`] 
        if(isRequiredFormat.isEmail && emailRegex.test(value) !== true) return [false,`Your response Should be a valid email`]
        if(isRequiredFormat.isString && typeof value !== 'string') return [false,`Your response Should be a plain text`]
        if(isRequiredFormat.isBoolean && typeof value !== 'boolean') return [false,`Your response Should be a plain text`]
        return [true]
    
            
    
    
    


}



export const isExpected = (validatee,expected)=>{

    // console.log('THE VALIDATEE')
    // console.log(validatee)
    // console.log(validatee.trim().toLowerCase())

    // console.log(validatee)
    // console.log(expected)
    // console.log(expected.indexOf(validatee.trim()))
    // console.log(expected.indexOf(validatee.trim()) < 0)

    // let lowered = validatee.trim().toLowerCase()
    // console.log('THE LOWERED')
    // console.log(lowered)

    for(let i=0; i < expected.length; i++){

        console.log('TESTING')
        console.log(expected[i])
        console.log(validatee)
        console.log(expected[i].trim().toLowerCase())
        console.log(validatee.trim().toLowerCase())

        if(expected[i].trim().toLowerCase() === validatee.trim().toLowerCase()){

            return true

        }else if(i === expected.length - 1){

            return false
        }
        

    }

   

    // if(expected.indexOf(lowered) < 0){

    //     console.log('LESS THAN ZERRO')
    //     return false
    // }
    // return true
  
}

 export const nextQuestion = (self=null,id='')=>{


    const {props} = self 
    const {humanoid,actions} = props 
    const {currentQuestion={},currentQuestions=[],currentQuestionIndex=0,currentQuestionr,questionas} = humanoid 
    const questionr = questionas[currentQuestionr] 
    const {setCurrentQuestions,updateTypingStatus} = actions
    // const currentQuestionrQuestions = questionr.questions
    const {endAction=''} = questionr 

    console.log('THE NEXT QUESTION')
    console.log(id)
    console.log(currentQuestion)

     
    console.log('The current question has no chain')
    console.log(currentQuestions.length)
    console.log(currentQuestions)
    console.log(currentQuestionIndex)
    // updateTypingStatus(true)
    

    if(currentQuestion.text && currentQuestion.questions){

        console.log('THE CURRENT QUESTION IS CHOICE,ABOUT TO GET ITS CHILDREN')
        if(currentQuestion.prefferedAnswer){
            if(currentQuestion.prefferedAnswer.trim().toLowerCase() === id.trim().toLowerCase()){

                return choiceQuestions(self,setCurrentQuestions,currentQuestion,currentQuestionIndex)
            }
        }
       
    }

    if(currentQuestionIndex === currentQuestions.length - 1){
        //endActions[endAction](self,id)

        if(currentQuestion.chain){

            let chain = currentQuestion.chain
            const {questions} = questionr
            let chainFrags = chain.split('.')
             chainFrags.pop()

            for(let i=0; i < chainFrags.length; i++){


                if(i === 0 && chainFrags.length === 1){

                    console.log('THE CHAIN IS CLOSEST TO THE TOP LEVEL')

                    const lastAsk = getLaskAsk(questions)

                    if(questions.length - 1 > lastAsk.lastAskIndex){


                        continueFromLastAsk(self,setCurrentQuestions,lastAsk,questions,lastAsk.lastAskIndex)
                        break;

                        // console.log('THE LAST ASK INDEX')
                        // console.log(lastAsk.lastAskIndex)
                        // let newIndex = lastAsk.lastAskIndex +1 
                        // console.log(newIndex)
                        // setCurrentQuestions(questions)
                        // console.log(questions[newIndex])
                        // askQuestion(self,{question: questions[newIndex]},newIndex)
                        // break; 
            

                     }else{

                       if(endActions[endAction]) endActions[endAction](self,id)
                        break;

                     }
                   

                     
                }

                console.log('THE FRAGMENT PIECE')
                console.log(chainFrags[i])

                const identifier = getIdentifieFromChain(questions,chain)
                // console.log('THE GOT IDENTIFIER')
                // console.log(identifier)
                const lastAsk = getLaskAsk(identifier)
                // console.log('THE RETURN LASTASK')
                // console.log(lastAsk)

                

                if(!lastAsk) {

                    // if(chainFrags.length > 0){

                    //     chainFrags.pop()
                    //     chain = chain.substr(0,chain.lastIndexOf('.'))
                    //     continue

                    // }else{

                    //     console.log('WE HAVE REACHED THE TOP LEVEL OBJECT, NO NEE FOR FURTHER WORK')
                    //     endActions[endAction](self,id)
                    // }
                    chain = chain.substr(0,chain.lastIndexOf('.'))
                    if(actOnLastAsk(self,id,chainFrags,chain,endAction) === 'break') {break};
                    continue;
                    
                  
                   

                }else{

                     if(identifier.length - 1 > lastAsk.lastAskIndex){

                        // let newIndex = lastAsk.lastAskIndex +1
                        // setCurrentQuestions(identifier)
                        // askQuestion(self,{question: identifier[newIndex]},newIndex)
                        // break; 
                        //self,setCurrentQuestions,lastAsk,identifier
                        continueFromLastAsk(self,setCurrentQuestions,lastAsk,identifier,lastAsk.lastAskIndex)
                        break;
            

                     }else{

                        // if(chainFrags.length > 0){

                        //     chainFrags.pop()
                        //     chain = chain.substr(0,chain.lastIndexOf('.'))
                        //     continue
    
                        // }else{
    
                        //     console.log('WE HAVE REACHED THE TOP LEVEL OBJECT, NO NEE FOR FURTHER WORK')
                        //     endActions[endAction](self,id)
                        //     break; 
                        // }
                        chain = chain.substr(0,chain.lastIndexOf('.'))
                        if(actOnLastAsk(self,id,chainFrags,chain,endAction) === 'break') {break};
                        continue;

                     }
                   
                }

            }

        }else{

            endActions[endAction](self,id)

        }
        

    }else{

            const nextIdex = currentQuestionIndex + 1

            if(currentQuestions[nextIdex].text && currentQuestions[nextIdex].questions){


                // console.log('THE CURRENT QUESTION WHEN NEXT IS CHOICE')
                // console.log(JSON.stringify(currentQuestion))
                // console.log(JSON.stringify(currentQuestions[currentQuestionIndex]))
                // console.log(JSON.stringify(currentQuestionIndex))
                currentQuestions[currentQuestionIndex]['isLevelLastAsk'] = true 
                currentQuestions[currentQuestionIndex]['lastAskIndex'] = currentQuestionIndex + 1
                askQuestion(self,{question: currentQuestions[nextIdex]},nextIdex)

            }else{

                if(currentQuestions[nextIdex].questions){

                    const nestedQuestions = currentQuestions[nextIdex].questions 
                    currentQuestions[currentQuestionIndex]['isLevelLastAsk'] = true 
                    currentQuestions[currentQuestionIndex]['lastAskIndex'] = currentQuestionIndex + 1

                    setCurrentQuestions(nestedQuestions)
                    askQuestion(self,{question: nestedQuestions[0]},0)

                }else{

                    askQuestion(self,{question: currentQuestions[nextIdex]},nextIdex)

                }
            }

        
        
        
    }

     

  
  }



export const nextQuestionr = (self=null,questionrID='')=>{


    const {props} = self 
    const {actions} = props 
    const {setNextQuestionr} = actions
    //const {currentQuestion={},currentQuestionIndex=0,questionas} = humanoid 
    // const questionr = questionas[questionrID] 
    // const currentQuestionrQuestions = questionr.questions 
    console.log('THE NEXTQUESTIONR ID')
    console.log(questionrID)

    setNextQuestionr(questionrID)
    //initiators[questionrID](self,questionrID)
    initiators['initiate'](self,questionrID)



  
  
  }


export const initiateConversations = (self=null)=>{


    // const {props} = self 
    // const {humanoid} = props 
    // const {currentQuestionr='start'} = humanoid 
    // initiators[currentQuestionr](self)

    const {props} = self 
    const {humanoid,actions} = props 
    const {setActiveActivity,setIsReturnUser}= actions
    const {isReturnUser=false,activeActivityHolder={},currentQuestionr='',currentQuestionIndex} = humanoid 
    const {activity='',index=0} = activeActivityHolder 
    const exclude = ['complete','incomplete','start','close','returner','userAction','editrecordcomplete']
    //initiators[currentQuestionr](self)
    if(isReturnUser){

      

        if(currentQuestionr.trim() && exclude.indexOf(currentQuestionr) < 0){



            setActiveActivity(currentQuestionr,currentQuestionIndex)
            endActions.loadNext(self,'returner')
            setTimeout(()=>{
    
                endActions.loadNext(self,'incomplete')

            },5000)
            //endActions.loadNext(self,'returner')
        }else{

            endActions.loadNext(self,'returner')
            setTimeout(()=>{

            endActions.loadNext(self,'userAction')

        },5000)

        }

        

    }else{

        setIsReturnUser(true)
        endActions.loadNext(self,'start')
        setTimeout(()=>{

            endActions.loadNext(self,'userAction')

        },5000)
    }
    


}

const getIdentifieFromChain = function(object, string){

    console.log('GET ID FROM CHAIN')
    console.log(object)
    console.log(string)



    var explodedString = string.split('.');
    let lastID = explodedString[explodedString.length - 1]
    console.log(explodedString)



    for (let i = 0; i < object.length; i++){

        console.log('qUESTION ON DISPLAY')
        //console.log(object[i])

        let qs = object[i]
        console.log(qs)
        console.log(lastID)

         if(qs.key && qs.key === lastID){

            return object
         }else{

            if(qs.questions){

                return getIdentifieFromChain(qs.questions, string)
            }
         }
         
         if(i === object.length - 1){

            return object
         }
    }

   
 }


const getLaskAsk = function(lastObject){

    console.log('THE LAST ASK')
    console.log(lastObject)


    for (let i = 0; i < lastObject.length; i++){

       if(lastObject[i].isLevelLastAsk){
          return lastObject[i]
       }else if(i === lastObject.length - 1){

           return null
       }

    }

    
 }


 const actOnLastAsk = function(self,id,chainFrags=[],chain='',endAction={}){

    
    if(chainFrags.length > 0){

        chainFrags.pop()
        
        return 'continue'

    }else{

        console.log('WE HAVE REACHED THE TOP LEVEL OBJECT, NO NEEd FOR FURTHER WORK')
        endActions[endAction](self,id)
        return 'break'; 
    }

    
 }


 const continueFromLastAsk = function(self,setCurrentQuestions,lastAsk,identifier,lastAskIndex){


        // console.log('THE LAST ASK')
        // console.log(lastAsk)
        
        let newIndex = lastAskIndex +1
        setCurrentQuestions(identifier)
        askQuestion(self,{question: identifier[newIndex]},newIndex)

   
    
    
 }


export const choiceQuestions = function(self,setCurrentQuestions,questionrr,index){


    const nestedQuestions = questionrr.questions 
    questionrr['isLevelLastAsk'] = true 
    questionrr['lastAskIndex'] = index

    setCurrentQuestions(nestedQuestions)
    askQuestion(self,{question: nestedQuestions[0]},0)

    // const nestedQuestions = currentQuestions[currentQuestion].questions 
    // currentQuestions[currentQuestionIndex]['isLevelLastAsk'] = true 
    // currentQuestions[currentQuestionIndex]['lastAskIndex'] = currentQuestionIndex + 1

    // setCurrentQuestions(nestedQuestions)
    // askQuestion(self,{question: nestedQuestions[0]},0)


}

// const getIdentifieFromChain = function(object, string){


//     var explodedString = string.split('.');
//     for (i = 0, l = explodedString.length; i<l; i++){
//        object = object[explodedString[i]];
//     }

//     return object;
//  }
//  var r = { a:1, b: {b1:11, b2: 99}};
 
//  var s = "b.b2";
//  var o = accessProperties(r, s);
//  alert(o);//99




