
import * as types from './types'
import { CALL_API } from '../../middlwares/api'


export const updateTypingStatus = (status = null)=>{


    return {
        type: types.CHANGE_TYPING_STATUS,
        payload: status
    }

}



// export const fetchUserProfileSettings = (data)=> {

//   console.log('FETCHUSERPROFILESETTINGS')
//   console.log(data)

//   return {

//       [CALL_API]: {
//         endpoint: `/profile`,
//         request: 'URL',
//         method: 'POST',
//         payload: {action: 'getProfile',payload: {...data}},
//         authencitcated: false,
//         types: [types.FETCH_USER_PROFILE_SETTINGS_REQUEST, types.FETCH_USER_PROFILE_SETTINGS_SUCCESS, types.FETCH_USER_PROFILE_SETTINGS_FAILURE]
//       }
//     }

// }

