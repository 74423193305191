import React, { Component } from 'react' 
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import Notifications from '@material-ui/icons/Notifications';
import FilterListIcon from '@material-ui/icons/FilterList';

import Filter from './filter'


const styles = {
    root: {
     
      color:"seagreen",
      fontSize: 30,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };




class RightFilter extends Component{
    
    constructor(props){

        super()
        this.state = {
          isOpenFilts: false
        }
        

    }

   resetFilters(){
     
     const {props} = this 
   	 const {actions} = props 
     const {resetFilters} = actions 

    resetFilters({
     	
      resetFilter: true
     
   })
   
    //  const {props,state} = this 
   	//  const {actions,search} = props 
    //  const {resetFilters} = actions 
    //  const {searchy,filters} = search 

    //  this.setState({...state,reset: true})
     
    //  fetchJobsByFilter({
     	
    //  	 ...searchy,
    //  	 ...filters
     	
    //  })
   	
   }

   openFilts(e){
     
     e.preventDefault()
     const {state} = this 
     const {isOpenFilts} = state 

    // this.getAndStyleElement(this.state.openFilts)
     this.setState({...this.state,isOpenFilts: !isOpenFilts })

   }

   getAndStyleElement(tog){

    const wrappedElement = document.getElementById('search');

    console.log('THE SEARCH ELEMENT')
    console.log(wrappedElement)
    if(tog){

      if(!wrappedElement) return 
      
      wrappedElement.style.display = 'block'
    }else{
      if(!wrappedElement) return 
      wrappedElement.style.display = 'none'
    }
   
  }

    render(){

        // console.log('THE FILTERS DATA')
        // console.log(this.props) 
        const {props,resetFilters,state,openFilts} = this
        const {filtersData,actions,search,isScrolled,width,isPosts,isBottom} = props 
        const {isOpenFilts} = state
        const {resetFilter} = search 
        // console.log('THE RIGHT FILTER')
        // console.log(search) 
        // console.log(resetFilter)
        let pointEvent = resetFilter ? 'none' : 'all' 
        let isMobile = width <= 1200 ? true : false 
        let filtsStyle = isMobile ? isOpenFilts ? {visibility: 'visible',opacity: 1,transition: 'opacity 5s cubic-bezier(0.175, 0.885, 0.32, 1.275)'} : {visibility: 'hidden',opacity: 0,transition: 'opacity 5s cubic-bezier(0.175, 0.885, 0.32, 1.275);'} : {visibility: 'visible',opacity: 1,transition: 'opacity 5s cubic-bezier(0.175, 0.885, 0.32, 1.275)'}

        // let rtClasses = !isPosts || (width <= 1200 || !isScrolled) ? 'job__jobs--rt' : 'job__jobs--rt job__jobs--rt-sticky'
        let rtClasses = (!isPosts || isBottom) && width > 800 ? 'job__jobs--rt' : 'job__jobs--rt job__jobs--rt-sticky' 
        let stickyHid =  width <= 1200 ? isOpenFilts  ? {width: '100%',zIndex: 12,position:'fixed'} :  {width: '50px'} : null


        console.log('THE WIDTH THAT IS THE WIDTH')
        console.log(width)
        console.log(isOpenFilts)
        console.log(isOpenFilts ? 'should open only when true' : 'should open only when false')
        console.log(isMobile)
       

        return(

           

                <section className={rtClasses} style={stickyHid}>

                  
                  <div className="job__jobs--rt-filts" style={filtsStyle}>

                    <section className="job__jobs--rt-btns-cont">
                      <button disabled={resetFilter} pointerEvents={pointEvent} onClick={resetFilters.bind(this)} className="job__jobs--rt-btn">{isMobile ? 'Reset' : 'Reset Filtered'}</button>
                      { isMobile ? <button disabled={resetFilter} pointerEvents={pointEvent} onClick={openFilts.bind(this)} className="job__jobs--rt-cancel">Cancel</button> : null }
                   </section>
                    <Filter filtersData={filtersData} search={search} actions={actions}/> 
                    { isMobile ? <button disabled={resetFilter} pointerEvents={pointEvent} onClick={resetFilters.bind(this)} className="job__jobs--rt-update">Update</button> : null }
                  </div>
                  
                  {isMobile 
                    ?
                    <div className="job__jobs--rt-filter" onClick={openFilts.bind(this)}>
                      <span className="job__jobs--rt-filter-icon"><Icon><FilterListIcon style={styles.root} /></Icon></span>
                      <span className="job__jobs--rt-filter-text">Filter</span>
                    </div>
                    : null
                  }

               
             
                </section>
        )
    }

}


export default withStyles(styles)(RightFilter)