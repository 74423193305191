
import React, { Component } from 'react' 
import { isActionSuccessful } from '../Humanoid/props'







class ActionConfirm extends Component{
    
    constructor(){

        super()
        this.state ={
            showActionMessage: false,
            option: false
        }

    

        
    }
    // componentDidMount(){

    //     const {props} = this 
    //     const {setTitle,confirmType} = props
        
    //     confirmType === 'delete' ?  setTitle('Confirm delete') : setTitle('Choose replace option')
    // }

   

    // componentWillUnmount(){

    //     const {actions,userProfile} = this.props
    //     const {removeMessage} = actions 
    //     const {message=''} = userProfile 

    //     if(message){

           
    //         removeMessage()
    //     }
    // }

    showUpload = (option)=>{

        const {props} = this 
        const {openUpload} = props

        this.setState({...this.state,showActionMessage: true,option: option})
        setTimeout(()=>{

            console.log('THE TIMEOUT IS RUNNING')
            console.log(openUpload)
            openUpload(option)

        },500)

       
    }

    deletecv = (delID,e)=>{

        if(e) e.preventDefault()

        const {props}= this 
        const {userProfile,actions} = props 
        const {profile} = userProfile 

        const {deleteUserAlert} = actions
        const {personal} = profile
        const {id} = personal
        this.setState({...this.state,showActionMessage: true,option: true})

        setTimeout(()=>{

            deleteUserAlert({ID:id,id:delID})

        },500)
        
      

       
    }



    
    // componentDidUpdate(){

    //     const {props}= this 
    //     const {userProfile,actions} = props 
    //     const {isActionSuccessful=false,message=''} = userProfile 
    //     const {removeNotification,removeMessage} = actions

    //     console.log('THE Component did update')
    

    //     if(isActionSuccessful) {

    //         this.notify({message:'Job alert successfully deleted',type: 'success',className: 'notify-success'}) 
    //         removeNotification() 
            
    //     }else if(message.trim()){

    //         this.notify({message: 'Job alert could not be updated',type: 'error',className: 'notify-error'}) 
    //         removeMessage() 
            
    //     }

     
        
    // }


 
    



 
    // notify = (config) => {

       
    //     const {message} = config || ''
    //     const type = config.type || 'info'
    //     toast[type](message,
    //         {position: toast.POSITION.BOTTOM_LEFT,...config});
    // }



    componentWillUnmount(){

        const {props}= this 
        const {userProfile,actions,handleClickOpen} = props 
        const {isActionSuccessful=false,message=''} = userProfile 
        const {removeNotification,removeMessage} = actions

      
        
        if(message.trim()){
            removeMessage() 
            
        }

     
        
    }


  

   
    render(){


     const {props,state} = this 
     const {userProfile,componentProps} = props 
     const {message='',isFetching=false} = userProfile
     const alertID = componentProps.id 
     const {option,showActionMessage} = state
     let checkStyles = {borderRadius: '5px',marginRight: '5px',textAlign: 'center',display: 'inline-block',border: 'solid maroon 1px',height: '15px',width: '15px'}
     let confirmTextStyles = {display: 'inline-block'}
     let repBg = option === 'only' ? {backgroundColor: '#eed2d2'} : {backgroundColor: 'white'}
     let allBg = option === 'all' ? {backgroundColor: '#eed2d2'} : {backgroundColor: 'white'}
     let listStyles = {padding: '5px',width: '300px',marginBottom: '10px',cursor: 'pointer'}
     let iconStyles = {color: 'seagreen',position:'relative',bottom:'2px',display: 'inline-block'}
     let textStyles = {marginBottom: 0}
     let deleteErrorStyles = {color: 'white',display: 'inline-block',backgroundColor: 'seagreen',padding: '7px',cursor: 'pointer',textAlign: 'center',fontSize: '13px',borderRadius: '2px'}
     //#eed2d2

       
       

        return(

           
    
           
                 
                <ul className="upload-cv-success-list">

                        {isActionSuccessful 

                            ?  <span style={{display: 'inline-block',fontSize: '15px',marginTop: '5px',color: 'seagreen'}}>
                                    Alert has successfully been deleted
                                </span>                
                            
                                
                            : <li className="upload-cv-success-list-i bx-raised-1" style={{...listStyles,...repBg}} onClick={(e)=>this.deletecv(alertID,e)}>

                                        <p style={{marginTop: '5px',marginBottom: '30px'}}>You are about to delete your set Job alert, are you sure you want to continue?</p>
                                        <span style={checkStyles} aria-hidden="true">
                                        {option 
                                        ? <i className="fa fa-lx fa-check" style={iconStyles}></i> 
                                        : null
                                        }
                                        </span>
                                
                                    <span style={confirmTextStyles}  >Yes, delete this job alert</span>
                                    
                                </li>
                            
                            
                            
                        }

 

    

                        
                   
                    {message.trim() 
                    
                    ?  <span style={{display: 'inline-block',fontSize: '15px',marginTop: '5px',color: 'seagreen'}}>
                        <small style={{display: 'block',color: 'red',marginBottom: '15px',fontSize: '14px'}}>File has failed to delete</small>
                        <span style={deleteErrorStyles} onClick={(e)=>{this.deletecv(alertID,e)}}>Let me try again</span>
                     </span> 
                    
                    :showActionMessage 
                        ?  <span style={{display: 'inline-block',fontSize: '15px',marginTop: '5px',color: 'seagreen'}}>
                            The system is deleting your alert...
                            </span> 
                        : null}
                        
                </ul>
               




          

          
        )
    }


}


export default ActionConfirm


