
import React, {Component} from 'react' 
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import * as actions from './actions'
import * as navigatorActions from '../ReduxFirstNavigator/actions'
import { Helmet } from "react-helmet";

import Search from './search/search'
import Identity from './identity/identity'
import Jobseeker from './jobseeker/jobseeker'
import Jobdash from './jobdash/jobdash' 
import Young from './young/young'
import Top from './top/top'
import Employer from './employer/employer'
import Candidate from './candidate/candidate'




class Home extends Component{


    constructor(props){

        super(props)
        this.state = {
            width: 0
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    }


    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }

    componentDidMount(){

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
       
    }

    componentWillUnmount(){

        window.removeEventListener('resize', this.updateWindowDimensions);
      
     }

    

  


    render(){
        // console.log('THE LOCATION STATE SHAPE')
        // console.log(this.props.state.pathname)

        const {width} = this.state

        return(

             <article className="grid-row">

                <Helmet>
                    <title>Jobbri - Welcome</title>
                </Helmet>

                <div className="home">

                    <div className="home--dez-cont">

                        
                        <Search actions={this.props.actions} />
               
                     
                        <strong className="clearfix" />
                       
                       
                    </div>
                    <Young actions={this.props.actions} />
                    <div>
                        <Identity actions={this.props.actions} width={width} />
                    </div>

                    <div>
                        <Jobseeker />
                    </div>
                    <div>
                        <Jobdash actions={this.props.actions} />
                    </div>
                    

                </div>
                
             </article>
        )
    }




}

const mapStateToProps = (state)=>{

    return{
        state:{
            ...state.router
        }
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...actions,...navigatorActions},dispatch)
    }
}

export default connect(mapStateToProps,mapDispachToProps)(Home)