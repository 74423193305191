
import React, { Component } from 'react' 





 function Player (){
    
   
  

    return(

      
           <section className="about__players">

               <div className="about__players--header">

                    <h2 className="about__players--header-title">
                          

                        <span className="about__players--header-title-text">
                          To our players
                        </span>
                        <span className="about__players--header-title-dot"></span>
                        <span className="about__players--header-title-sub">Jobseekers & employers</span>
                    </h2>

                  
                        
               </div>

               <div className="about__players--to">

                      

                          <p className="about__players--to-text">

                          Dear valuable jobseeker and employer, we would like to acknowledge and emphasize that you are our most valued asset, and as such, we are dedicated to giving you quality service and experience. Without you, we cease to exist.



                          </p>
                            
                       
                   

               </div>

               <div className="about__players--appreciate">

                        
                          <p className="about__players--appreciate-text">

                          

                            <span className="about__players--appreciate-text-ck about__players--appreciate-text-ck-1 "></span>
                            <span className="about__players--appreciate-text-ck about__players--appreciate-text-ck-2 "></span>
                            <span className="about__players--appreciate-text-ck about__players--appreciate-text-ck-3 "></span>
                            <span className="about__players--appreciate-text-ck about__players--appreciate-text-ck-4 ">
                            A big handshake of appreciation to you
                                       
                            </span>



                          </p>
                            
                        
                   

               </div>

              

            
             
           </section>
      
    )

}


export default Player