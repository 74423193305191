
import React, {Component} from 'react' 





class ShareButton extends  Component{


    constructor(props){

        super()

      
    }

    
    
    
   

    render(){

        
         const {props,state} = this 
        //  const {bioText} = state
        //  const {actions,isEdit,allowEdit} = props 
        //  const editContStyles = isEdit ? {boxShadow: '-15px 10px 25px 5px rgba(0, 0, 0, 0.04);'} : {boxShadow: 'none'}
        //  let contact = true
        // const {push} = actions
        // const {message = ''} = auth 
        // console.log('tHE MESSAGE')
        // console.log(message)
        // console.log(message.trim() !== '')


        return(

                    
                <span className="resume--action-btns resume--action-btns-offset ">

                        <span className="resume--action-btns-rect resume--action-btns-rect-2"></span>
                        <span className=" resume--action-btns-rect resume--action-btns-rect-3"></span>
                        <span className="resume--action-btns-rect resume--action-btns-rect-4">
                            <small className="resume--action-btns-rect-4-icon">
                            <i class="fa fa-share-alt" aria-hidden="true"></i>
                            </small>
                            <small>Share My CV</small>
                        </span>
                </span> 
             
           
        )
    }




}

export default ShareButton