
import React, { Component } from 'react' 
import Form from './form'





 function Send (props){
    
   
  

    return(

      
           <section className="contact__mail">

               <div className="contact__mail--jo">

                    <h2 className="contact__mail--jo-title">
                          

                          <span className="contact__mail--jo-title-text">
                           Contact us
                        </span>
                        <span className="contact__mail--jo-title-bar"></span>
                    </h2>

                    <section className="contact__mail--jo-formy">
                        <Form  props={{...props}} />
                    </section>
                        
               </div>

               <div className="contact__mail--bio">


                  <div className="contact__mail--bio-cont">
                   
                  
                   <section className="contact__mail--bio-value bx-raised-1">

                        <h2 className="contact__mail--bio-value-title">
                            <span className="contact__mail--bio-value-title-text-1">Our</span>
                            <span className="contact__mail--bio-value-title-text-1">social presence</span>
                        </h2>

                        <div className="contact__mail--bio-value-box">

                            <p className="contact__mail--bio-value-box-i">

                                <a href="https://www.facebook.com/Jobbri-105946767631202/" className="link" target="_blank">
                                    <span className="contact__mail--bio-value-box-point"></span>
                                    <span className="contact__mail--bio-value-box-text">Facebook</span>
                                </a>

                            </p>

                            <p className="contact__mail--bio-value-box-i">

                                <a href="https://twitter.com/jobbrijobs" className="link" target="_blank">
                                    <span className="contact__mail--bio-value-box-point"></span>
                                    <span className="contact__mail--bio-value-box-text">Twitter</span>
                                </a>

                            </p>

                            <p className="contact__mail--bio-value-box-i">

                                <a href="https://www.instagram.com/jobbri/" className="link" target="_blank">
                                    <span className="contact__mail--bio-value-box-point"></span>
                                    <span className="contact__mail--bio-value-box-text">Instagram</span>
                                </a>

                            </p>

                          
                            {/* <p className="contact__mail--bio-value-box-i">

                            <span className="contact__mail--bio-value-box-point"></span>
                            <span className="contact__mail--bio-value-box-text">Twitter</span>

                            </p>

                            <p className="contact__mail--bio-value-box-i">

                            <span className="contact__mail--bio-value-box-point"></span>
                            <span className="contact__mail--bio-value-box-text">Instagram</span>

                            </p>

                            <p className="contact__mail--bio-value-box-i">

                            <span className="contact__mail--bio-value-box-point"></span>
                            <span className="contact__mail--bio-value-box-text">Googleplus</span>

                            </p> */}
                            

                        </div>


                   </section>

                   </div>
                    

                   

               </div>

              

               <strong className="clearfix" />
             
           </section>
      
    )

}


export default Send