
import React,{Component} from 'react'
import Shapes from './shapes/shapes' 
import Categories from './categories'
import SearchForm from './searchform/launchform' 


class Search extends Component{
    
    
    render(){

        return(

            <div  className="home__deziner">

                <section className="home__deziner--cta">

                    <div className="home__deziner--cta-rect home__deziner--cta-rect1 bx-raised"></div>
                    <div className="home__deziner--cta-rect home__deziner--cta-rect2 bx-raised">

                        <h2>Find your dream job in one go.</h2>

                        <SearchForm actions={this.props.actions} />
                        
                    </div>
                    <div className="home__deziner--cta-circle"></div> 
                    <strong className="pos-rel"></strong>


                </section>

                <section className="home__deziner-sidecircle"></section>

               <Categories actions={this.props.actions} />

                <Shapes />


           

            

                
            </div>
        )
    }

}


export default Search