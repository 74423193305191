
import React, { Component } from 'react' 
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import AttachmentIcon from '@material-ui/icons/Attachment';
import SendIcon from '@material-ui/icons/Send';
import CheckIcon from '@material-ui/icons/Check';
import {clearMessages,updateInput,inputClickListener,scrollToBottom} from '../humanoidFunks/util'
// import {doHost} from '../humanoidFunks/host'
// import {showChats} from '../humanoidFunks/showman'
import {getIO} from '../humanoidFunks/view'
// import {startConversation}  from '../humanoidFunks/hostr'
import {showConversation} from '../humanoidFunks/view'
import {initiateConversations} from '../humanoidFunks/questionr'
import {registerUser,updateUserProfileSegment} from '../humanoidFunks/postprocessors'


// import { messages } from '../props';
// import { isTyping } from '../../ChatHeader/props';
// import { getIOText } from '../actions';

const Filter = require('bad-words')
const filter = new Filter()
// filter.addWords('Nyamawu', 'tsek', 'nyopfi');


const styles = {
    root: {
     
    //   color:"#ff6633",
      fontSize: 18,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };





class About extends Component{
    
    constructor(){

        super()
        this.state = {

            text: '',
            aliasText: '',
            shouldUpdate: true,
            // currentStep: '',
            // stepID: 0
        }
       

        
    }

    openLogin = ()=>{

        const {props} = this 
        const {actions} = props 
        const {push} = actions 
        push('/access')
    }

  inputClickListener = (e=null)=>{

        //e.preventDefault()
    
        if(e.code === "Enter" || e.code === "NumpadEnter") return getIO(this,e)
    }
    

   

    componentDidMount(){

        // console.log('THE TEMPLATES')
        // console.log(templates)
        initiateConversations(this)
        



        // const {currentStep,stepID} = state
        // const {

        //     currentStep = '',stepID = 0,currentStepCQ = 0,
        //     isPaused = false, isClosed = false,isProcessed = false,isNotReady=false, 
        //     messages =[]
        // } = humanoid 

        
        document.addEventListener("keydown",this.inputClickListener)

        // console.log('THE COMPONENT DIDMOUTN CURRENTSTEP')
        // console.log(currentStep)
        // console.log(currentStep.trim())
        // console.log(currentStep.trim().length)

        // if(isNotReady) return this.chatIfIsNotReady() 
        // if(currentStep.trim()) return this.chatIfIsPaused() 
        // if(currentStep.trim() === '' && messages.length === 0) return this.chatIfStart()

        // if(currentStep.trim() === '' && messages.length === 0){

        //     // const {setInitialCurrentStep} = actions 
        //     // setInitialCurrentStep('qualification')
        //     const step = questions[stepID]
        //     const {queries} = step 
        //     const query = queries[currentStepCQ]
        //     const {question,isNoAccept=false} = query

        //     if(isNoAccept) this.delayInitialMessageWithNoAnswer()

        //     // {

        //     //     currentStep,
        //     //     currentStepCQ,
        //     //     step,
        //     //     queries,
        //     //     query
        //     // }

        //     this.sendInitialQuestion({
        //         text: question,
        //         currentStep: 'qualification',
        //         currentStepCQ: currentStepCQ
        //     })
           

        // }else{
            
        //       this.scrollToBottom()
        // }
        
      
        
    }

    componentWillUnmount(){

        document.removeEventListener('keydown',this.inputClickListener)
    }

    componentDidUpdate(){
        


        const {humanoid,userProfile,actions} = this.props
        const {isActionSuccessful,message=''} = userProfile 
        const {humanoidActionType} = humanoid
        const {unsetNotificationDisplay} = actions
        
        if(isActionSuccessful) {

            if(humanoidActionType === 'updateProfile'){

                updateUserProfileSegment(this,true)
            }else if(humanoidActionType === 'register'){

                registerUser(this,true)
            }
        // unsetNotificationDisplay()
           
        }else if(message.trim()){

            //this.notify({message: message,type: 'error',className: 'notify-error'})
            if(humanoidActionType === 'updateProfile'){

                updateUserProfileSegment(this,false)
            }else if(humanoidActionType === 'register'){

                registerUser(this,false)
            } 
            //unsetNotificationDisplay()
            //removeMessage() 
            
        }


       
        
    }

    
    
    render(){


        const {props,state} = this 
        const {humanoid} = props
        const {messages} = humanoid
        const {text} = state 
        let lastMessage = messages.length > 0 ? messages[messages.length - 1] : {template: false} 
        const {template=false} = lastMessage
       

        return(

           
    
            <div className="humanoid__chat">

                 

                 <section className="humanoid__chat--chats"> 

                  {messages ? messages.map((m,i)=> showConversation(m,i,this,messages)) : null} 
                  <span style={{display: 'block'}} id="scroll-to"></span>    

                 </section>

               
                 <section className="humanoid__chat--box">

                     {/* <Skill /> */}

                 {/* <button className="humanoid__chat--box-attachment" onClick={this.clearMessages.bind(this)}>
                         <span>
                            <Icon>
                                <AttachmentIcon style={styles.root} className="fg-primary" />
                            </Icon>
                         </span>
                    </button> */}
                     
                        <div className="humanoid__chat--box-message">
                            <form>
                                <textarea className="humanoid__chat--box-message-input" disabled={template} type="text" value={text} name="response" placeholder="Type your message..." onChange={(e)=>updateInput(this,e)} />
                                <div className="humanoid__chat--box-action-btns">

                                <button className="humanoid__chat--box-attachment" disabled={template} onClick={(e)=>clearMessages(this,e)}>
                                    <span>
                                        <Icon>
                                            <AttachmentIcon style={styles.root} className="fg-primary" />
                                        </Icon>
                                    </span>
                                </button>

                                <button className="humanoid__chat--box-send" disabled={template} onClick={(e)=>getIO(this,e)}>
                                    <span>
                                        <Icon>
                                            <SendIcon style={styles.root} className="fg-primary" />
                                        </Icon>
                                    </span>
                                </button> 
                            </div>
                            </form>
                            
                        </div>

                       
                     
                     
                     
                 </section>

                 

            </div>

          
        )
    }


}


export default About


