import React, { Component } from 'react' 
import {Link} from 'react-router-dom'
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import Notifications from '@material-ui/icons/Notifications';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';


const styles = {
    root: {
     
      color:"#ee4c7c",
      fontSize: 20,

      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    },
    white: {color:'white',fontSize: 20},
  };

  function TrendingTags(props){

    const {searchJobs,closePopup=()=>null,width,classes}= props 
    const contStyles = width <=800 ? {marginTop: 0} : null

    return(

        <section className="job__jobs--lt-tags-content" style={contStyles}>


                {width <= 800 
                  ?  <h4 className="job__jobs--lt-tags-title" style={{marginBottom: '2rem'}}>
                        <span className="job__jobs--lt-tags-title-icon"><Icon><TrendingUpIcon className={classes.root} /> </Icon></span>
                        <small className="job__jobs--lt-tags-title-text">Trending Tags</small>
                    </h4>
                : null
                }
                       
            {/* <button className="job__jobs--lt-tags-content-btn"  onClick={searchJobs.bind(this,`frontend`)}>#Frontend</button>
            <button className="job__jobs--lt-tags-content-btn" onClick={searchJobs.bind(this,`nursing`)}>#nursing</button>
            <button className="job__jobs--lt-tags-content-btn" onClick={searchJobs.bind(this,`General Worker`)}>#generalworker</button>
            <button className="job__jobs--lt-tags-content-btn" onClick={searchJobs.bind(this,`warehouse`)}>#warehouse</button>
            <button className="job__jobs--lt-tags-content-btn" onClick={searchJobs.bind(this,`retail`)}>#retail</button> */}
            <button className="job__jobs--lt-tags-content-btn"  onClick={()=>{searchJobs(`frontend`); closePopup()}}>#Frontend</button>
            <button className="job__jobs--lt-tags-content-btn" onClick={()=> {searchJobs(`nursing`); closePopup()} }>#nursing</button>
            <button className="job__jobs--lt-tags-content-btn" onClick={()=>{searchJobs(`General Worker`); closePopup()}}>#generalworker</button>
            <button className="job__jobs--lt-tags-content-btn" onClick={()=>{searchJobs(`warehouse`); closePopup()}}>#warehouse</button>
            <button className="job__jobs--lt-tags-content-btn" onClick={()=>{searchJobs(`retail`); closePopup()}}>#retail</button>
        </section>

    )
  }


  
  function CustomButton(props){

    const {classes={},openPopup =()=>null} = props 
    const {white=''} = classes

    return(

        <button className="job__jobs--lt-mobile-card-rect-4-sub-btn" onClick={()=>openPopup()}>
            <span className="job__jobs--lt-mobile-card-rect-4-sub-btn-rect"></span>
            <span className="job__jobs--lt-mobile-card-rect-4-sub-btn-rect-2">
                <small><Icon><TrendingUpIcon className={white} /> </Icon></small>
                </span> 

        </button>

    )
  }



class Tags extends Component{
    
    constructor(props){

        super(props)

    }




    searchJobs= (job)=>{

        const {props} = this
        const {actions,search} = props 
        const {saveJobSearchParams} = actions 
        const {jl='South Africa'} = search
        //console.log(url)
       // push(url)
        saveJobSearchParams({
         jq: job,
         jl: jl,
         // canSetHistory: true,
         fetchJobs: true
     })
 
    }


    render(){

        const {props,searchJobs} = this 
        const {classes,width} = props 
        const Modal = props.modal
        const self = this 
        const componentProps = {searchJobs:searchJobs.bind(self),width: width,classes:classes}
        // const containerMobileStyles = width <= 800 ? {display: 'inline-block',width: '90px',padding: 0,margin:0} : null
        const contClass = width <= 800 ? 'job__jobs--lt-tags job__jobs--lt-mobile-containers' : 'job__jobs--lt-tags'

        return(





           <section className={contClass}>


                
               

                  {width > 800 
                  ?  <h4 className="job__jobs--lt-tags-title" >
                        <span className="job__jobs--lt-tags-title-icon"><Icon><TrendingUpIcon className={classes.root} /> </Icon></span>
                        <small className="job__jobs--lt-tags-title-text">Trending Tags</small>
                    </h4>
                    :  <Modal popUp={TrendingTags} button={CustomButton} buttonProps={{classes: classes}}  componentProps={componentProps} />
                    }

                    {width > 800 
                    ? <TrendingTags searchJobs={searchJobs.bind(this)} {...componentProps} /> : null}

                   

             
           </section>
        )
    }

}


export default withStyles(styles)(Tags)