
import React,{Component} from 'react'
import Shapes from './shapes/shapes' 
import {BrowserRouter as Router,Link} from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'

import FavoriteIcon from '@material-ui/icons/Favorite';



const styles = {
    root: {
     
      color:"#999",
      fontSize: 30,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };

// import SearchForm from './searchform/launchform' 




class Categories extends Component{

    constructor(){

        super()
    }

    openJobs = (jobKeyword,e)=>{

        e.preventDefault()
        const {props} = this 
        const {actions} = props 
        const {push} = actions 
    
        push(`jobs?jq=${jobKeyword}`)
    }
    
    
    render(){

        return(

                <section className="home__cate">


                    <div className="home__cate--i">
                        <section className="home__cate--i-backwall"></section>
                        <section className="home__cate--i-box bx-raised" onClick={this.openJobs.bind(this,'Office administrative')}>

                            <small className="home__cate--i-box-icon"><Icon><FavoriteIcon /></Icon></small>
                            <section className="home__cate--i-box-title">Office Administrative</section> 
                           
                        </section>
                    </div>

                    <div className="home__cate--i">
                        <section className="home__cate--i-backwall"></section>
                        <section className="home__cate--i-box bx-raised"  onClick={this.openJobs.bind(this,'Design and Development')}>

                            <small className="home__cate--i-box-icon"><Icon><FavoriteIcon /></Icon></small>
                            <section className="home__cate--i-box-title">Design &amp; Development</section> 
                           
                        </section>
                    </div>

                    <div className="home__cate--i">
                        <section className="home__cate--i-backwall"></section>
                        <section className="home__cate--i-box bx-raised"  onClick={this.openJobs.bind(this,'Science and Technologies')}>

                            <small className="home__cate--i-box-icon"><Icon><FavoriteIcon /></Icon></small>
                            <section className="home__cate--i-box-title">Science &amp; Technologies</section> 
                           
                        </section>

                    
                    </div>

                    <div className="home__cate--i">
                        <section className="home__cate--i-backwall"></section>
                        <section className="home__cate--i-box bx-raised"  onClick={this.openJobs.bind(this,'Business Development')}>

                            <small className="home__cate--i-box-icon"><Icon><FavoriteIcon /></Icon></small>
                            <section className="home__cate--i-box-title">Business Development</section> 
                           
                        </section>

                    
                    </div>

                    <div className="home__cate--i">
                        <section className="home__cate--i-backwall"></section>
                        <section className="home__cate--i-box bx-raised"  onClick={this.openJobs.bind(this,'Human resources')}>

                            <small className="home__cate--i-box-icon"><Icon><FavoriteIcon /></Icon></small>
                            <section className="home__cate--i-box-title">Human Resources</section> 
                           
                        </section>

                    
                    </div>

                    <div className="home__cate--i">
                        <section className="home__cate--i-backwall"></section>
                        <section className="home__cate--i-box bx-raised"  onClick={this.openJobs.bind(this,'Retail sales food')}>

                            <small className="home__cate--i-box-icon"><Icon><FavoriteIcon /></Icon></small>
                            <section className="home__cate--i-box-title">Retail, Sales &amp; Food</section> 
                           
                        </section>

                    
                    </div>





                   

                    

                </section> 

               


           

            

                
            
        )
    }

}


export default  withStyles(styles)(Categories)