
import React,{Component} from 'react'
import More from './more/more' 



class Company extends Component{

    constructor(){
        super()
    }
    
    
    render(){

        const {footer,actions,width} = this.props

        return(

            <div className="grid-row">

                
                <div  className="mg-bottom-fd-md">
                    
                     <More footer={footer} actions={actions} width={width} />
                </div>
               
                

            </div>
        )
    }

}


export default Company