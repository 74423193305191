import { askQuestion } from "./questionr"




// export const start = (self = null)=>{


//     const {props} = self 
//     const {humanoid,actions} = props 
//     const {currentQuestionr='start',questionas} = humanoid 
//     const {setCurrentQuestions} = actions
//     const questionr = questionas[currentQuestionr] 
//     const {questions} = questionr 
//     setCurrentQuestions(questions)
//     askQuestion(self,{multiple:true, question: questions})

// }

export const incomplete = (self = null)=>{


  const {props} = self 
  const {humanoid,actions} = props 
  const {questionas} = humanoid 
  const {setCurrentQuestions,setNextQuestionr} = actions
  const questionr = questionas['incomplete'] 
  const {questions} = questionr 
  setNextQuestionr('incomplete')
  setCurrentQuestions(questions)
  askQuestion(self,{multiple:true, question: questions})

}



// export const register = (self = null,ID)=>{


//   const {props} = self 
//   const {humanoid,actions} = props 
//   const {questionas} = humanoid 
//   const questionr = questionas[ID] 
//   const {setCurrentQuestions} = actions
//   const {questions} = questionr 
//   console.log('THE REGISTER INITIATOR') 
//   console.log(JSON.stringify(ID))
//   setCurrentQuestions(questions)
//   askQuestion(self,{question: questions[0]},0)

// }


export const initiate = (self = null,ID)=>{


  console.log('THE INITIATE QUESTIONR ID')
  console.log(ID)
  const {props} = self 
  const {humanoid,actions} = props 
  const {questionas} = humanoid 
  const questionr = questionas[ID] 
  console.log('THE QUESTIONR')
  console.log(JSON.stringify(questionr))
  const {setCurrentQuestions} = actions 
  
  const {questions} = questionr 
  const exclude = ['complete','incomplete','personalask',
  'profileask','editrecordcomplete','addrecordcomplete','deleterecordcomplete'] 
  console.log('INITIATE QUESTIONS')
  console.log(JSON.stringify(questions))
  setCurrentQuestions(questions) 
  if(exclude.indexOf(ID) < 0){

    console.log('THE FIRST QUESTONS')
    console.log(questions[0])
    askQuestion(self,{question: questions[0]},0)

  }else{

    askQuestion(self,{multiple:true, question: questions})

  }
  

}


    
 






