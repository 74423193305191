
import React, {Component} from 'react' 
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as actions from './actions' 
import * as navigatorActions from '../ReduxFirstNavigator/actions'
import * as launchActions from '../Launch/actions'

import Brand from './brandi/brandi'
import Company from './company/company'
import AppLaunch from './applaunch/applaunch' 

import Humanoid from '../Humanoid/component'








class Footer extends Component{


    constructor(props){

        super(props)
        this.state ={
            width: 0
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    }


    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }

    componentDidMount(){

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
       
    }

    componentWillUnmount(){

        window.removeEventListener('resize', this.updateWindowDimensions);
      
     }

    componentDidUpdate(){
        
        // console.log()
        // const {isActionSuccessful,launcher,isFetcing,actions} = this.props 
        // const  {removeNotification} = actions 
        // console.log('THE LAUNCHER INFOUPDATE::')
        // console.log(launcher)

        // if(isActionSuccessful && launcher === 'infoUpdate') {
        //     this.notify({message:'Profile info successfully updated',type: 'success',className: 'notify-success'}) 
        //     removeNotification()
        // }

        const {footer,launch,actions} = this.props
        const {isActionSuccessful,message='',formType,text,launcher=''} = footer 
        const isLaunchActionSuccessful = launch.isActionSuccessful || false
        const launchMessage = launch.message || ''
        const launchText = launch.text || ''
        const {unsetNotificationDisplay} = actions 
        const removeLaunchMessage = actions.removeMessage 
        const removeLaunchNotification = actions.removeNotification
        // const {removeNotification,removeMessage} = actions 

        console.log('SUCCESS IDENTIFIERS')
        console.log(isLaunchActionSuccessful)
        console.log(isActionSuccessful)

        if(isActionSuccessful || isLaunchActionSuccessful) {

            let msg = ''
            let launchMsg = launchText || 'You have been successfully added to the notify list.'
            let alertMsg = text || 'Job alert created, please confirm the email we\'ve sent to you to finish.'
           

            if(isActionSuccessful) msg = alertMsg 
            if(isLaunchActionSuccessful) msg = launchMsg
            this.notify({message:msg,type: 'success',className: 'notify-success'}) 


            if(isLaunchActionSuccessful) return removeLaunchNotification()
            unsetNotificationDisplay()
            // removeNotification() 
            
          
           
        }else if(message.trim() && launcher.trim() === 'footer' || launchMessage.trim()){

            let errMsg = '' 
            let launchErrMsg = launchMessage || ''
            let alertErrMsg = message || ''


            if(launchMessage.trim()) errMsg = launchErrMsg 
            if(alertErrMsg.trim()) errMsg = alertErrMsg 
            this.notify({message: errMsg,type: 'error',className: 'notify-error'}) 

            if(launchMessage.trim()) return removeLaunchMessage()
            unsetNotificationDisplay()
            //removeMessage() 
            
        }


       
        
    }

    notify = (config) => {

            
        console.log('THE TOAST CONFIG')
        console.log(config)
        const {message} = config || ''
        const type = config.type || 'info'
        toast[type](message,
            {position: toast.POSITION.BOTTOM_LEFT,...config});

    }


    render(){

        const {footer,launch,actions} = this.props 
        const {width} = this.state
        const pathName = this.props.location.pathname 
        console.log('THE STATE FOOTER')
        console.log(this.state)

        if(pathName === '/humanoid') return null

        

        return(

            <footer className="grid-row">

             
                <section>
                    <Brand />
                </section>
             

                
                   
               

            </footer>
        )
    }





}

const mapStateToProps = (state)=>{

    return{
        footer:{
            ...state.footer
        },
        launch: {...state.launch}
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...actions,...navigatorActions,...launchActions},dispatch)
    }
}

export default withRouter(connect(mapStateToProps,mapDispachToProps)(Footer)) 