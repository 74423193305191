
import React, {Component} from 'react' 
import {BrowserRouter as Router,Link} from 'react-router-dom'
import DeactivateButton from './deactivatebutton'
import DeactivatePopup from './deletepopup'


// import Jobs from './jobs/jobs'
// import Brand from './brand/brand'
// import Primary from './primary/primary'
// import Secondary from './secondary/secondary'



class Navigation extends  Component{


    constructor(props){

        super(props)
    }

    notifyActive(active,e){

        e.preventDefault()
        e.stopPropagation()

        this.props.handleActive(active)

    }

   

    render(){

        const {userProfile,actions} = this.props
        const Modal = this.props.modal
        const componentProps = {compClass:"link dashboard__candsettings--settingpane-link"}

        return(


               <nav>

                 <Link onClick={this.notifyActive.bind(this,'profile')} to="" className="link dashboard__candsettings--settingpane-link"><span>Profile</span></Link>
                 <Link onClick={this.notifyActive.bind(this,'alerts')} to="" className="link dashboard__candsettings--settingpane-link"><span>Job alerts</span></Link>
                 <Modal popUp={DeactivatePopup} componentProps={componentProps} userProfile={userProfile} actions={actions} button={DeactivateButton} />
                
               

                </nav>

             
             
           
        )
    }




}

export default Navigation