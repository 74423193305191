
import React, {Component} from 'react' 
import {Helmet} from 'react-helmet'
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import * as userProfileActions from '../UserProfile/actions'
import * as navigatorActions from '../ReduxFirstNavigator/actions'
import ProgressBarCircular from '../ProgressBarCircular/component'
import SearchHistory from './components/dashboard/searchhistory'
import Empty from './components/empty'
import jobs from './jobs'
import { toast } from 'react-toastify';





class CandHistory extends Component{


    // constructor(props){

    //     super(props)
    // }

    componentDidMount(){

        const {props} = this 
        const {userProfile,actions} = props 
        const {push} = actions
        const {profile} = userProfile 
        const {personal} = profile 
        const {id,is_pending} = personal 
        
        if(is_pending === 1) return push('/account-verification')
        //actions.fetchSearchHistory({ID:id})
        
    }


    



    componentDidUpdate(){

        const {props}= this 
        const {userProfile,actions} = props 
        const {isActionSuccessful=false,message='',isAlertDelete=false} = userProfile 
        const {removeNotification,removeMessage} = actions

      
        if(isActionSuccessful) {

            this.notify({message:'Seach history successfully deleted',type: 'success',className: 'notify-success'}) 
            removeNotification() 
            
        }else if(message.trim()){
            this.notify({message:message,type: 'error',className: 'notify-error'}) 
            removeMessage() 
            
        }
     
        
    }


    notify = (config) => {

       
        const {message} = config || ''
        const type = config.type || 'info'
        toast[type](message,
            {position: toast.POSITION.BOTTOM_LEFT,...config});
    }


    
    componentWillUnmount(){

        const {props}= this 
        const {userProfile,actions} = props 
        const {isActionSuccessful=false,message=''} = userProfile 
        const {removeNotification,removeMessage} = actions


        if(isActionSuccessful) {
            removeNotification() 
            
        }
        
        if(message.trim()){
            removeMessage() 
            
        }

     
        
    }

   

    render(){

        const {userProfile,actions} = this.props
        const {profile} = userProfile
        const {searchHistory} = profile 
        // const searchHistory = []


        // if(isFetching) return  <article className="dashboard__savedjobs">
        //     <Helmet>
        //             <title>Jobbri - Search History</title>
        //     </Helmet><ProgressBarCircular /></article>;

        return(

            <article className="dashboard__savedjobs">
                 <Helmet>
                    <title>Jobbri - Search History</title>
                </Helmet>
                  
                  <div>

                      {
                          searchHistory.length > 0
                          ? <SearchHistory searchHistory={searchHistory} userProfile={userProfile} actions={actions} />
                          : <section> 
                              <div style={{marginBottom: '5rem',width: '250px'}} className="dashboard__searchhistory--i-box bx-raised-1 ">

                                <span className="dashboard__searchhistory--i-box-title">Search history</span>
               
                              </div>
                              <Empty  userProfile={userProfile} actions={actions} />
                            </section>
                      }

                     
                  </div>
                  
                 
            </article>
        )
    }




}

const mapStateToProps = (state)=>{

    const {userProfile} = state

    return{
        
        userProfile
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...userProfileActions,...navigatorActions},dispatch)
    }
}

export default connect(mapStateToProps,mapDispachToProps)(CandHistory)


