import { yellow } from '@material-ui/core/colors'
import React, { Component } from 'react' 






class Register extends Component{
    
    constructor(){

        super()

        
    }

    openLogin = ()=>{

        const {props} = this 
        const {actions} = props 
        const {push} = actions 
        push('/access')
    }

    
    componentDidMount(){

        const {props} = this 
        const {setTitle} = props 
        setTitle('Login or Register ')

    }

    
    render(){


       
       

        return(

           
    
            <div className="upload-cv-register">

                 <h2 className="upload-cv-register-title">
                 <span className="upload-cv-register-text">
                    Hi there
                 </span> 
                 <span style={{marginTop: '8px'}}>
                    <i className="fa fa-lx fa-smile-o" style={{color: 'seagreen'}} aria-hidden="true"></i>
                    </span>
                </h2>
                <p className="upload-cv-register-message">To upload a cv on Jobbri, please login or alternatively register if you do not have an account </p>
                <button className="upload-cv-register-submit" onClick={(e)=>this.openLogin(e)}>
                    <span className="upload-cv-register-submit-rect"></span>
                    <span className="upload-cv-register-submit-rect-2">
                        <small>Login/Register</small></span>
                </button>




            </div>

          
        )
    }


}


export default Register


