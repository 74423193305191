
import React, {Component} from 'react' 

// import ReCAPTCHA from "react-google-recaptcha"


class Bio extends  Component{


    constructor(props){

        super()

    }




    getInstitutions = (e,i)=>{

     
        let card = i === 0 ? '' : `resume__education--card-${i+1}` 
        let toPresent = !e.to ? `${e.from}-` : `${e.from}-${e.to}`
        let pos = i+1
        let institution = (

    

            <section className={`resume__education--card ${card}`} key={i}>

                <div className="resume__education--card-pointer">
                    <small>{pos}</small>

                </div>

                <div className="resume__education--card-pointee">

                    <h5 className="resume__education--card-pointee-period">{toPresent}</h5>
                    <h6 className="resume__education--card-pointee-employer">{e.institution}</h6>
                    <span className="resume__education--card-pointee-role">{e.qualification}</span>
                    <p className="resume__education--card-pointee-description">
                       {e.majors}
                    </p>

                </div>

                

            </section>

            
        )
        

        return institution



    
    }
 

               

   

    render(){

        
         const {props} = this 
         const {language} = props 



        return(

                    

                        
                       
                  
                        
                        
   
                          
   
                                //    <li className="resume__curated--card-pointee-list-i">
                                //        <span>{language.name}</span>
                                //        <span>{language.name}</span>
                                //        <span><a href={language.link} target="_blank">View</a></span>
                                    //    <span>
                                    //        <small style={editIconStyles} onClick={()=>{setIsEditing(index+1)}}><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                               
                                    //        <small style={editIconStyles}><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                                    //    </span>
                                       
                                //    </li>

                                    <li className="resume__curated--card-pointee-list-i">
                                        <span>{language.language}</span>
                                        <span>{language.l_speak}</span>
                                        <span>{language.l_write}</span>
                                        <span>{language.l_read}</span>
                                       
                                    </li>
                                   
   
   
                            
                      


             
           
        )
    }




}

export default Bio