
import React from 'react';
import {BrowserRouter,Link} from 'react-router-dom';


const Brand = ()=>{


	

		return(


			<div className="app__brand" >

                <Link to='/' className="app__brand--name">

                    <span className="fg-dark d-inline-block ">

                    <img src="/img/jobbrilogo.png" alt="jobbri logo" width="90" />
                    </span>
                    {/* <span className="fg-dk-green mg-left-fd-bt d-inline-block font-fd-tn">
                        o
                    </span> */}



                </Link>

                    
			</div>
		)


	
	

}




export default Brand;





